import * as React from 'react';
import './footer.scss';
import Button from '@material-ui/core/Button';
import { Container } from '../Container';
import { AdapterLink } from '../AdapterLink';
import Modal from 'react-bootstrap/Modal';
import * as Config from '../../config';
import { AuthContext } from '../../contexts/AuthContext';
import * as LangConfig from '../../languages/languages';

export const Footer: React.FC = () => {
  const auth = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [translations, setTranslations] = React.useState(auth['user']['attributes'].locale ? LangConfig.languages[auth['user']['attributes'].locale]['translations'] : LangConfig.languages['en']['translations']);
  const [help, setHelp] = React.useState(false);
  const helpClose = () => {
    setHelp(false);
  }
  const helpShow = () => setHelp(true);

  return (
    <footer className="footer">
      <Container>
        <div className="footer-inn">
          <Button
            variant="contained"
            color="primary"
            component={AdapterLink}
            to="/terms"
          >
            {translations.Terms_Conditions}
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={AdapterLink}
            to="/privacy"
          >
            {translations.privacy_policy}
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={AdapterLink}
            to="#"
            onClick={helpShow}
          >
            {translations.help}
          </Button>

          <Modal show={help}>
            <Modal.Header closeButton>
              <Modal.Title>{translations.Instructions}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: 'inherit' }} dangerouslySetInnerHTML={{ __html: auth['user']['attributes'].locale && auth['user']['attributes'].locale == 'es' ? Config.instructionsEs : Config.instructions }}></Modal.Body>
            <Modal.Footer>
              <Button className="btn btn-primary text-white" onClick={helpClose}>
                Okay
            </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Container>
    </footer>
  );
};
