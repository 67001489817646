import * as React from 'react';
import './button.scss';

interface IButton {
  onClick: () => void;
  label?: string;
  style?: 'normal';
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const Button: React.FC<IButton> = ({
  style,
  label,
  children,
  className,
  onClick,
  disabled
}) => {
  const buttonStyle = style ? `button--${style}` : '';

  return (
    <button className={`button ${buttonStyle} ${className}`} disabled={disabled} onClick={onClick}>
      {children || label}
    </button>
  );
};
