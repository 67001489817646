import * as React from 'react';
import '../Login/login.scss';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { RouteComponentProps, Redirect } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';
import { AdapterLink } from '../AdapterLink';

import SimpleSnackbar from '../SimpleSnackbar';
var CryptoJS = require("crypto-js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
    img_logo: {
      width: 350,
      marginBottom: 10,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 20,
    },
    menu: {
      width: 200,
    },
    button: {
      margin: theme.spacing(1),
      marginTop: 20,
    },
    input: {
      display: 'none',
    },
  }),
);

interface IValueState {
  username: string;
  code: string;
  errorMessages: string[];
  successMessages: string[];
  usernameHasError: boolean;
  codeHasError: boolean;
}

export const VerifyAccount: React.FC<RouteComponentProps> = props => {
  const auth = React.useContext(AuthContext);
  const classes = useStyles();

  const [values, setValues] = React.useState<IValueState>({
    username: auth.usernameForVerification || '',
    code: '',
    usernameHasError: false,
    codeHasError: false,
    errorMessages: [],
    successMessages: ['Verification code has been emailed to you. Please check your inbox or spam folder. Enter verification code.']
  });

  React.useEffect(() => {
    setValues({
      ...values,
      username: values.username || auth.usernameForVerification || ''
    });
  }, [auth]);

  const { from } = props.location.state || { from: { pathname: '/' } };

  if (auth.hasValidSession && !auth.requireVerification) {
    return <Redirect to={from} />;
  }

  /*if (auth.requireVerification && !auth.hasValidSession) {
    return <Redirect to={'/verify'} />;
  }*/

  const validateForm = (username: string, code: string) => {
    let err: string = '';
    if (!username && !code) {
      err = 'Please enter email and code.';
    }
    if (!username && code) {
      err = 'Please enter email.';
    }
    if (username && !code) {
      err = 'Please enter a code.';
    }

    setValues({
      ...values,
      errorMessages: [...values.errorMessages, err],
      successMessages: [],
      usernameHasError: !username,
      codeHasError: !code,
    });

    return !!username && !!code;
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.keyCode === 13) {  // if "Enter"
      e.preventDefault();
      handleVerification();
    }
  };

  const handleResendConfirmationCode = async () => {
    const { username } = values;
    setValues({
      ...values,
      errorMessages: [],
      successMessages: [],
      usernameHasError: false,
      codeHasError: false,
    });

    auth.resendSignUp && await auth.resendSignUp({ username });
    setValues({
      ...values,
      errorMessages: [],
      successMessages: ['Email resent with confirmation code. Login with your account.'],
    });
    /*
    props.history.push({
      pathname: '/login',
      state: {
        verfiedCode: true
      }
    })*/
  }

  const handleVerification = async () => {
    const { username, code } = values;
    const inputIsValid = validateForm(username, code);

    if (inputIsValid) {
      setValues({
        ...values,
        errorMessages: [],
        successMessages: [],
        usernameHasError: false,
        codeHasError: false,
      });

      auth.verifyAccount && await auth.verifyAccount({ username, code });
      setValues({
        ...values,
        errorMessages: [],
        successMessages: ['Email Confirmed. Login with your account.'],
      });

      var bytes = CryptoJS.AES.decrypt(localStorage.getItem('authKey'), 'my-secret-key@123');
      var password = bytes.toString(CryptoJS.enc.Utf8);

      auth.login && (await auth.login({ username,  password}));
      localStorage.setItem('instpopup', 'false');
      localStorage.setItem('authKey', '');
      window.location.href = '/';

      /*props.history.push({
        pathname: '/login',
        state: {
          verfiedCode: true
        }
      })*/
    }
  };
  return (
    <div className="login-page">
      <div  className="login verify">
        {/* <img src={require('../../assets/images/lawdroid-voice-logo.png')} alt="Lawdroid Logo" className={classes.img_logo}/> */}
        {values.errorMessages &&
        values.errorMessages.map(
          (err, i) => !!err && <div className = "alert alert-danger w-100"> {err} </div>,
        )}
        {values.successMessages &&
        values.successMessages.map(
          (success, i) => !!success && <div className = "alert alert-success w-100"> {success} </div>,
        )}

        <form className={classes.container} noValidate autoComplete="off" onKeyDown={inputOnKeyDown}>
          <TextField
            error={values.usernameHasError}
            id="standard-name"
            placeholder="Email"
            className={classes.textField}
            value={values.username}
            onChange={e => {
              setValues({ ...values, username: e.target.value });
            }}
          /><br/>
          <TextField
            error={values.codeHasError}
            id="standard-code"
            placeholder="Code"
            type="text"
            className={classes.textField}
            value={values.code}
            onChange={e => {
              setValues({ ...values, code: e.target.value });
            }}
          />
          <Button
            onClick={handleVerification}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Verify
          </Button>
          {/*<div className="resend">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              component={AdapterLink}
              to="/forgot"
            >
              Resend a Code
            </Button>
          </div>*/}
          <div className="login-secondaryActions">
            <a href="javascript:void(0)" 
            onClick={() => handleResendConfirmationCode()} >
              Resend Confirmation Code
            </a>
            {/*<Button
              onClick={handleResendConfirmationCode}
              className={classes.button}
              // component={AdapterLink}
              variant="contained"
              color="primary"
            >
             Resend Confirmation Code
            </Button>*/}
          </div>
          <div className="login-secondaryActions">
            <Button
              className={classes.button}
              component={AdapterLink}
              to="/login"
            >
              Back to Sign In
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
