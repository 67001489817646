import * as React from 'react';
import Amplify, { CognitoUser } from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';
import {
  CognitoUserSession,
  CognitoAccessToken,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import { Nullable } from '..';
import { Redirect } from 'react-router';
import { async } from 'q';

import { AWS_userPoolId, AWS_userPoolWebClientId } from '../config'

Storage.configure({
  AWSS3: {
    bucket: 'atj-bot',
    region: 'us-east-1'
  }
})

Amplify.configure({
  region: 'us-east-1',
  userPoolId: AWS_userPoolId,
  userPoolWebClientId: AWS_userPoolWebClientId,
  mandatorySignIn: false,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
});

interface ISignUpParams {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  language: string;
  // phone: string;
}

interface IUpdateProfileParams {
  username: string;
  photo: string;
  firstName: string;
  lastName: string;
  language: string;
  phone: string;
  address: string;
  oldPassword: string;
  newPassword: string;
}

interface IForgotPasswordParams {
  username: string;
  password: string;
  code: string;
}

interface ILoginParams {
  username: string;
  password: string;
}

interface IVerifyAccountParams {
  username?: string;
  code: string;
}

interface IResendSignUpParams {
  username: string;
}

interface IAuthContext {
  user: Nullable<CognitoUser>;
  session: Nullable<CognitoUserSession>;
  accessToken: Nullable<CognitoAccessToken>;
  refreshToken: Nullable<CognitoRefreshToken>;
  hasValidSession: boolean;
  requireVerification: boolean;
  usernameForVerification: string;
  resetNewPassword: boolean;

  login: (params: ILoginParams) => void;
  signup: (params: ISignUpParams) => void;
  updateProfile: (params: IUpdateProfileParams) => void;
  forgotPassword: (step: number, params: IForgotPasswordParams) => void;
  verifyAccount: (params: IVerifyAccountParams) => void;
  resendSignUp: (params: IResendSignUpParams) => void;
  logout: () => void;
  isAuthenticated: () => void;
}

interface IAuthState {
  user: Nullable<CognitoUser>;
  session: Nullable<CognitoUserSession>;
  accessToken: Nullable<CognitoAccessToken>;
  refreshToken: Nullable<CognitoRefreshToken>;
  hasValidSession: boolean;
  requireVerification: boolean;
  usernameForVerification: string;
  resetNewPassword: boolean;
}

const initialState: IAuthState = {
  user: null,
  session: null,
  accessToken: null,
  refreshToken: null,
  hasValidSession: false,
  requireVerification: false,
  usernameForVerification: '',
  resetNewPassword: false
};

export const AuthContext = React.createContext<Partial<IAuthContext>>({});
export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends React.Component<any, any> {
  public state: IAuthState = initialState;

  public async componentDidMount() {
    try {
      /**
       * If AuthContextProvider mounts and it's not currently authenticated,
       * we will try to hydrate user data from localStorage.
       */

      const storage = await localStorage.getItem(
        'LawDroid.ATJ.Web.authContext',
      );

      // console.log(storage);

      if (storage) {
        /**
         * localStorage has what we need. Parsing the JSON and setting the state.
         */
        const hydratedState: Partial<IAuthState> = await JSON.parse(storage);
        // console.log(hydratedState);
        await this.setState({ ...this.state, ...hydratedState });
        // console.log(this.state);
      }

      if (!this.state.hasValidSession) {
        /**
         * Try to fetch the latest from localStorage.
         */
        let user: CognitoUser = await Amplify.currentAuthenticatedUser({ bypassCache: true });
        const session: Nullable<
          CognitoUserSession
        > = await Amplify.currentSession();
        const requiresVerification = !(user as any).attributes.email_verified;

        const accessToken = session && session.getAccessToken();
        const refreshToken = session && session.getRefreshToken();


        this.setState({
          user,
          session,
          accessToken,
          refreshToken,
          hasValidSession: !!accessToken,
          requiresVerification,
          resetNewPassword: false
        });
      }
    } catch (error) {
      // console.log('AuthContext.componentDidMount', error);
      if (error.code === 'UserNotConfirmedException') {
        this.setState({ ...this.state, requireVerification: true, resetNewPassword: false });
      }

      // console.log(this.state);
      // throw new Error(error.message);
    }
  }

  public login = async ({
    username: usrName,
    password: usrPass,
  }: ILoginParams) => {
    return new Promise(async (resolve, reject) => {
      try {
        /**
         * Check user authentication with server
         * stringify data from response and store in localStorage
         */
        this.setState({ usernameForVerification: usrName });

        const user: CognitoUser = await Amplify.signIn(usrName, usrPass);

        const session: Nullable<CognitoUserSession> = user.getSignInUserSession();
        const requiresVerification = !(user as any).attributes.email_verified;
        const usernameForVerification = requiresVerification ? usrName : '';
        const resetNewPassword = false;

        const accessToken = session && session.getAccessToken();
        const refreshToken = session && session.getRefreshToken();

        this.setState({
          user,
          session,
          accessToken,
          refreshToken,
          hasValidSession: !!accessToken,
          requiresVerification,
          usernameForVerification,
          resetNewPassword: false
        });

        // console.log('STATE', this.state);

        const stateToStorage = JSON.stringify({
          requiresVerification,
          usernameForVerification,
        });
        await localStorage.setItem(
          'LawDroid.ATJ.Web.authContext',
          stateToStorage,
        );
        return resolve(true);
      } catch (error) {
        if (error.code === 'UserNotConfirmedException') {
          this.setState({ requireVerification: true, resetNewPassword: false });
          return reject({ message: "Please verify your email address." });
        }
        // console.log('AuthContext.login', error);
        return reject(error);
      }
    });
  };

  public resendSignUp = async ({ username }: IResendSignUpParams) => {
    try {
      await Amplify.resendSignUp(username);

      this.setState({
        ...this.state,
        requiresVerification: true,
        usernameForVerification: username,
        resetNewPassword: false
      });

      const stateToStorage = JSON.stringify({
        requiresVerification: true,
        usernameForVerification: username,
        resetNewPassword: false
      });
      await localStorage.setItem(
        'LawDroid.ATJ.Web.authContext',
        stateToStorage,
      );

    } catch (error) {
      console.log(error);
      throw new Error(error.message);
    }
  };

  public verifyAccount = async ({ username, code }: IVerifyAccountParams) => {
    try {
      await Amplify.confirmSignUp(username, code);

      this.setState({
        ...this.state,
        requiresVerification: false,
        usernameForVerification: '',
        resetNewPassword: false
      });
    } catch (error) {
      // console.log(error);
      throw new Error(error.message);
    }
  };
  /*public verifyAccount = async ({ username, code }: IVerifyAccountParams) => {
    try {
      // console.log('verifyAccount', username, code);
      await Amplify.verifyCurrentUserAttributeSubmit('email', code);

      const user: CognitoUser = await Amplify.currentAuthenticatedUser();
      const session: Nullable<
        CognitoUserSession
      > = await Amplify.currentSession();

      const accessToken = session && session.getAccessToken();
      const refreshToken = session && session.getRefreshToken();

      // console.log(user);

      this.setState({
        user,
        session,
        accessToken,
        refreshToken,
        hasValidSession: !!accessToken,
        requiresVerification: false,
        usernameForVerification: '',
      });
    } catch (error) {
      // console.log('AuthContext.verifyAccount', error);
    }
  };*/

  public updateProfile = async ({ username, firstName, lastName, language, phone, address, photo, oldPassword = '', newPassword = '' }: IUpdateProfileParams) => {
    try {
      let user: CognitoUser = await Amplify.currentAuthenticatedUser();
      const session: Nullable<CognitoUserSession> = await Amplify.currentSession();

      const result  = await Amplify.updateUserAttributes(user, {
        'email': username,
        'given_name': firstName,
        'family_name': lastName,
        'locale': language,
        'name': firstName+' '+lastName,
        'phone_number': phone,
        'address': address,
        'picture': photo
      });

      if(newPassword != '') {
        await Amplify.currentAuthenticatedUser()
        .then(user => {
            return Amplify.changePassword(user, oldPassword, newPassword);
        })
        .then(data => console.log(data))
        .catch(err => {throw new Error(err.message)});
      }

      user = await Amplify.currentAuthenticatedUser({ bypassCache: true });
      const requiresVerification = !(user as any).attributes.email_verified;
      const usernameForVerification = requiresVerification ? username : '';

      this.setState({
        ...this.state,
        user,
        requiresVerification,
        usernameForVerification,
      });

      const stateToStorage = JSON.stringify({
        requiresVerification: requiresVerification,
        usernameForVerification: usernameForVerification,
        resetNewPassword: false
      });
      await localStorage.setItem(
        'LawDroid.ATJ.Web.authContext',
        stateToStorage,
      );
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public signup = async ({ username, password, firstName, lastName, language }: ISignUpParams) => {
    try {
      const user = await Amplify.signUp({
        username,
        password,
        attributes: {
          'email': username,
          'given_name': firstName,
          'family_name': lastName,
          'locale': language
        }
      });

      this.setState({
        ...this.state,
        user: user.user,
        requireVerification: true,
        usernameForVerification: username,
      });

      const stateToStorage = JSON.stringify({
        requiresVerification: true,
        usernameForVerification: username,
        resetNewPassword: false
      });
      await localStorage.setItem(
        'LawDroid.ATJ.Web.authContext',
        stateToStorage,
      );

      // console.log(user);
    } catch (error) {
      if (error.code === 'UserNotConfirmedException') {
        this.setState({ requireVerification: true, resetNewPassword: false });
        return;
      }

      // console.log('AuthContext.signUp', error);
      if (error.code == "InvalidPasswordException" || password.length < 6) {
        error.message = "<strong>PASSWORD MUST CONFIRM WITH THE POLICY:</strong><br/>Password must have length greater than or equal to 6.";
      }
      throw new Error(error.message);
    }
  };

  public logout = () => {
    return new Promise(async (resolve, reject) => {
      this.state.user && (await this.state.user.signOut());
      await localStorage.removeItem('LawDroid.ATJ.Web.authContext');
      this.setState(initialState, () => {
        return resolve(true);
      });
    });
  };

  /*public forgotPassword = async (step: number, params: any) => {
    try {
      // console.log('AuthContext.forgotPassword', step, params);

      if (step === 1) {
        const { username } = params;
        await Amplify.forgotPassword(username);

        return 2;
      }

      if (step === 2) {
        const { code, password, username } = params;
        await Amplify.forgotPasswordSubmit(username, code, password);

        return true;
      }
    } catch (error) {
      // console.log('AuthContext.forgotPassword', error);
    }
  };*/

  public forgotPassword = async (step: number, params: IForgotPasswordParams) => {
    try {
      // // console.log('AuthContext.forgotPassword', step, params);

      if (step === 1) {
        const { username } = params;
        await Amplify.forgotPassword(username);

        const stateToStorage = JSON.stringify({
          requiresVerification: false,
          usernameForVerification: username,
          resetNewPassword: true
        });
        await localStorage.setItem(
          'LawDroid.ATJ.Web.authContext',
          stateToStorage,
        );

        return true;
      }

      if (step === 2) {
        const { username, password, code } = params;
        await Amplify.forgotPasswordSubmit(username, code, password);

        const stateToStorage = JSON.stringify({
          requiresVerification: false,
          usernameForVerification: username,
          resetNewPassword: false
        });
        await localStorage.setItem(
          'LawDroid.ATJ.Web.authContext',
          stateToStorage,
        );

        return true;
      }
    } catch (error) {
      // console.log('AuthContext.forgotPassword', error);
      this.setState({ requireVerification: false, resetNewPassword: false });
      throw new Error(error.message);
    }
  };

  public isAuthenticated = () => {
    return new Promise(async (resolve, reject) => {
      try{
        /*let us: CognitoUser =*/ await Amplify.currentAuthenticatedUser({ bypassCache: true })
                              .then(data => this.setState({ user: data }, () => {
                                              return resolve(true);
                                            })
                                    )
                              .catch(err => {return resolve(false)});
        
      } catch (error) {
        // throw new Error(error);
        return resolve(error);
      }
    });
  };

  public render() {
    const {
      user,
      accessToken,
      refreshToken,
      session,
      hasValidSession,
      requireVerification,
      usernameForVerification,
      resetNewPassword
    } = this.state;

    return (
      <AuthContext.Provider
        value={{
          user,
          accessToken,
          refreshToken,
          session,
          hasValidSession,
          requireVerification,
          usernameForVerification,
          resetNewPassword,

          login: this.login,
          signup: this.signup,
          logout: this.logout,
          updateProfile: this.updateProfile,
          forgotPassword: this.forgotPassword,
          verifyAccount: this.verifyAccount,
          resendSignUp: this.resendSignUp,
          isAuthenticated: this.isAuthenticated,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
