import React from 'react';
import './message-list.scss';
import { DAClient } from '../../lib/DAClient';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { AuthContext } from '../../contexts/AuthContext';
import * as LangConfig from '../../languages/languages';

const da = new DAClient();

interface IMessageList {
  setPBar: any;
  myref: any;
  ynquestion: any;
  mStart: any;
  messages: IMessage[];
  link: string | null;
  interview_title: string | null;
  onResults: (userResponse: string) => void;
}

export interface IMessage {
  sentBy: 'PERSON' | 'BOT';
  createdAt: Date;
  message: string;
  variable: string;
  questionName: string;
  raw?: any;
  questionType: string;
  questionChoices: any;
  questionRange: any;
  bubbles: any[];
  bubbleIndex: any;
}

export const MessageList: React.FC<IMessageList> = ({ setPBar, myref, ynquestion, mStart, messages, link, interview_title, onResults }) => {
  let scrollContainer = React.createRef<HTMLDivElement>();
  let query_params = new URLSearchParams(window.location.search);
  let classNoBg = ' ';
  const [progressBar, setProgressBar] = React.useState(0);
  const [sections, setSections] = React.useState({
    'docassemble.playground1:Permanent-Parenting.yml': [
      { sectionName: 'Introduction', startpoint: 0, endpoint: 12, noteStart: 0, noteEnd: 2 },
      { sectionName: 'Basic Information', startpoint: 12, endpoint: 19, noteStart: 2, noteEnd: 5 },
      { sectionName: 'Children', startpoint: 19, endpoint: 32, noteStart: 5, noteEnd: 15 },
      { sectionName: 'Schedule', startpoint: 32, endpoint: 112, noteStart: 15, noteEnd: 59 },
      { sectionName: 'Making Decisions', startpoint: 112, endpoint: 119, noteStart: 59, noteEnd: 62 },
      { sectionName: 'Child Support', startpoint: 119, endpoint: 143, noteStart: 62, noteEnd: 85 },
      { sectionName: 'Taxes', startpoint: 143, endpoint: 146, noteStart: 85, noteEnd: 89 },
      { sectionName: 'Insurance', startpoint: 146, endpoint: 1000, noteStart: 89, noteEnd: 100 },
    ]
  });
  const auth = React.useContext(AuthContext);
  const [inputVal, setInputVal] = React.useState(false);
  const [lastM, setLastM] = React.useState(0);
  const [translations, setTranslations] = React.useState(auth['user']['attributes'].locale ? LangConfig.languages[auth['user']['attributes'].locale]['translations'] : LangConfig.languages['en']['translations']);
  const opera = navigator.userAgent.match(/Opera|OPR\//) ? true : false;

  React.useEffect(() => {
    if (messages.length > 0) {
      let lastMessage = messages[messages.length - 1];
      if (lastMessage.raw.questionType == 'deadend') {
        setProgressBar(100)
        return setPBar(100);
      }
      if (lastMessage.raw && lastMessage.raw.fields && lastMessage.raw.fields[1] && lastMessage.raw.fields[1].datatype == "note") {
        setProgressBar(parseInt(lastMessage.raw.fields[1].note))
        setPBar(parseInt(lastMessage.raw.fields[1].note));
      }
      if (messages[messages.length - 1] && messages[messages.length - 1].questionName && messages[messages.length - 1].questionName !== '') {
        setLastM(parseInt(messages[messages.length - 1].questionName.replace('Question_', '')));
      }
    }
    scrollContainer.current &&
      scrollContainer.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const downloadFile = async (fileNumber, title) => {
    let file = await da.retrieveFile(fileNumber, title);
  }

  const renderMessageBubble = (val, index) => {
    if (val.questionType == "deadend") {
      classNoBg = " classNoBg";
      console.log(classNoBg);
    }

    let bubbles: any = '';
    if (typeof val.message == 'boolean' && val.message == true) {
      val.message = translations.big_yes
    }
    if (typeof val.message == 'boolean' && val.message == false) {
      val.message = translations.big_no
    }
    if (val.sentBy == "BOT") {
      // bubbles = val.bubbles;
      bubbles = val.message.toString().split('<delay>');
    } else {
      bubbles = val.message.toString().split('<delay>');
    }

    if (bubbles.length <= 1) {
      let test = [<li className={`message is-${val.sentBy} ${classNoBg}`} key={index} dangerouslySetInnerHTML={{ __html: bubbles[0] }}></li>];
      if (ynquestion == 'notget' && index == messages.length - 1) {
        test.push(<li style={{ listStyle: 'none', color: 'red', fontSize: '14px' }}>{translations.warning_click_yes_no}</li>);
      } else if (ynquestion == 'onlyyes' && index == messages.length - 1) {
        test.push(<li style={{ listStyle: 'none', color: 'red', fontSize: '14px' }}>{translations.warning_click_yes}</li>);
      }
      return (test);
    } else {
      let html: any = [];
      let i: any = 0;

     for (let bubble of bubbles) {
        if (index == messages.length - 1 && i <= val.bubbleIndex) {
          // console.log("new message current bubble");
          html.push(<li className={`message is-${val.sentBy} ${classNoBg}`} dangerouslySetInnerHTML={{ __html: bubble }}></li>);
          i = i + 1;
        } else if (index != messages.length - 1) {
          // console.log("old message with all bubbles");
          html.push(<li className={`message is-${val.sentBy}  ${classNoBg}`} dangerouslySetInnerHTML={{ __html: bubble }}></li>);
        }
        // else if((/iPhone|iPad|iPod/i.test(navigator.userAgent))){
        //   html.push(<li className={`message is-${val.sentBy}`} dangerouslySetInnerHTML={{__html: bubble}}></li>);
        // }
      }
      if (ynquestion == 'notget' && index == messages.length - 1) {
        html.push(<li style={{ listStyle: 'none', color: 'red', fontSize: '14px' }}>{translations.warning_click_yes_no}</li>);
      } else if (ynquestion == 'onlyyes' && index == messages.length - 1) {
        html.push(<li style={{ listStyle: 'none', color: 'red', fontSize: '14px' }}>{translations.warning_click_yes}</li>);
      }
      return (html);
    }
  }

  const getChoiceQuicReplies = () => {
    return (<li className="message is-PERSON" style={{ background: 'transparent', marginRight: 0, padding: '1em 0 0 0', alignSelf: 'start', textAlign: 'left' }}>
      {messages[messages.length - 1].questionChoices.map((el) => {
        return (
          <button disabled={!(mStart == 'messageInput' || (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) || opera)} style={{ fontWeight: 'bold' }} className="btn btn-info mr-2 mt-2" onClick={() => onResults(el.label)}>
            {el.label}
          </button>)
      })}
    </li>)
  }

  return (
    <div className="app-main chat-shadow" style={{}}>
      {query_params.get('i') && query_params.get('i') == 'docassemble.playground1:Permanent-Parenting.yml' ?
        <div className="row state">
          {sections['docassemble.playground1:Permanent-Parenting.yml'].map(el => {
            // Done state
            if (lastM >= el.endpoint || progressBar == 100) {
              return (
                <div className="col text-center done"  >
                   {el.sectionName} 
                </div> 
              )
            }
            // Active
            else if (lastM >= el.startpoint && lastM < el.endpoint) {
              return (
                <div className="col text-center active" >
                  {el.sectionName} 
                </div>
              )
            }
            // Pending
            else {
              return (
                <div className="col text-center pending" >
                   {el.sectionName} 
                </div>
              )
            }
          })}
        </div> : null}
      <div className="app-main-chat-inner">
        <ul className="messageList">
          {messages.map((val, index) => {
            if (index == 0) {
              return renderMessageBubble(val, index);
            } else if (index == 1) {
              return null;
            } else if (val.message !== 'text_long' && messages[index - 1].message !== 'text_long') {
              return renderMessageBubble(val, index)
            }
          })}
          {/* {link ? (
          <li className="message is-BOT">
            <a href="#" onClick={() => downloadFile(link.match(/(\d+)/)[0], interview_title)}>
              Download PDF
            </a>
          </li>
        ) : null} */}


          {
            ((messages.length > 0 && messages[messages.length - 1].questionType == 'boolean' && messages[messages.length - 1].sentBy == "BOT" && messages[messages.length - 1].bubbles != undefined && messages[messages.length - 1].bubbleIndex == messages[messages.length - 1].bubbles.length - 1 &&
              localStorage.getItem('quesCompleted') == "true") ?
              (
                <li className="message is-BOT" style={{ background: 'transparent', marginRight: 0, padding: '.5em 0 0 0', alignSelf: 'left' }}>
                  <button disabled={!(mStart == 'messageInput' || (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) || opera)} style={{ fontWeight: 'bold' }} className="btn btn-info mr-2" onClick={() => onResults(translations.small_yes)}>
                    {translations.big_yes}
                  </button>
                  {messages[messages.length - 1].raw && messages[messages.length - 1].raw.fields && messages[messages.length - 1].raw.fields[0] && messages[messages.length - 1].raw.fields[0].hint && messages[messages.length - 1].raw.fields[0].hint == 'onlyyes' ? null : <button disabled={!(mStart == 'messageInput' || (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) || opera)} style={{ fontWeight: 'bold' }} className="btn btn-info mr-2" onClick={() => onResults('no')}>
                    {translations.big_no}
                  </button>}
                  {/* <button style={{ fontWeight:'bold' }}  className="btn btn-info mr-0" onClick={() => onResults("I don't know")}>
                I DON'T KNOW
              </button> */}
                </li>)
              :
              '')
          }

          {
            ((messages.length > 0 && messages[messages.length - 1].questionChoices && messages[messages.length - 1].questionChoices.length > 0 && messages[messages.length - 1].sentBy == "BOT" && messages[messages.length - 1].bubbles != undefined && messages[messages.length - 1].bubbleIndex == messages[messages.length - 1].bubbles.length - 1 &&
              localStorage.getItem('quesCompleted') == "true") ?
              getChoiceQuicReplies()
              :
              '')
          }

        </ul>


        <div className="messageList-spacer" ref={scrollContainer} />
        <div ref={myref} />
      </div>
    </div>
  );
};
