import * as React from 'react';
import './app.scss';

import { MessageList } from '../MessageList';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import { MessageContainer } from '../../Containers/MessageContainer';
import { ChatFooter } from '../ChatFooter';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { AuthContext } from '../../contexts/AuthContext';
import { DAClient } from '../../lib/DAClient';
import * as Config from '../../config';

const da = new DAClient();

const progressBarInterviews = [
  'docassemble.playground1:Expedited-Hearing.yml',
  'docassemble.playground1:Expedited-Hearing-Spanish.yml',
  'docassemble.playground1:TN-workers-progress.yml',
  'docassemble.playground1:TN-workers-spanish.yml'
]

export const Chat: React.FC = (props) => {
  const auth = React.useContext(AuthContext);
  let query_params = new URLSearchParams(window.location.search);
  let interviewType = query_params.get('interview');
  const [progressBar, setProgressBar] = React.useState(0);
  const [consec3, setConsec3] = React.useState(0);
  let myref = React.useRef<any>();
  const [mStart, setMStart] = React.useState<string>('');
  const opera = navigator.userAgent.match(/Opera|OPR\//) ? true : false;
  const listAvailInterviews = async () => {
    if (interviewType) {
      if (interviewType == "divorce") {
        interviewType = 'docassemble.playground1:Divorce-form-final.yml';
      } else if (interviewType == "feewaiver") {
        interviewType = 'docassemble.playground1:Fee-Waiver.yml';
      }

      let AvailableInterviews: any = await da.listInterviews();
      let result: any = AvailableInterviews.filter((e) => {
        return e.metadata['required privileges'].indexOf(Config.appHost) != -1 && e.filename == interviewType;
      })

      if (interviewType && AvailableInterviews.length != 0) {
        window.location.href = "/?i=" + result[0].filename + "&title=" + result[0].metadata.title + "&total_steps=" + result[0].metadata.total_steps;
      }
    }
  }

  const navigateBot = () => {
    if (myref.current) {
      myref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const setMic = (text) => {
    setMStart(text);
  }

  const setPBar = (value) => {
    setProgressBar(value);
  }

  const volumeMute = (vol) => {
    localStorage.setItem('muteBot', vol);
    if (vol == "true") {
      document.getElementById('volume_on').classList.add("hidden");
      document.getElementById('volume_off').classList.remove("hidden");
    } else {
      document.getElementById('volume_off').classList.add("hidden");
      document.getElementById('volume_on').classList.remove("hidden");
    }
  }

  const setCons = (status) => {
    if (status) {
      return setConsec3(consec3 + 1);
    }
    setConsec3(0);
  }


  React.useEffect(() => {
    if ((window.location.search && !window.location.search.includes("view=")) || (localStorage.getItem('inetrviewtype') && localStorage.getItem('inetrviewtype') !== 'false')) {
    } else {
      let userCognitoGroups = auth.accessToken.payload['cognito:groups'];
      let pro: any = props;
      if (userCognitoGroups != undefined && (userCognitoGroups.indexOf('Superadmin') != -1 || userCognitoGroups.indexOf('admin') != -1)) {
        pro.history.push('/admindashboard');
      } else {
        pro.history.push('/availableInterviews');
      }
    }
    localStorage.setItem('micStarted', 'false');
    localStorage.setItem('lastloader', 'false');
    listAvailInterviews();
  }, [auth]);

  return (
    <MessageContainer
      render={({ messages, setMessages, resetSession, useOldSession, msgUpdated, setMessageUpdated, setLongMessages }) => (
        <>
          <Header resetSession={resetSession} />
          <div className={(/iPhone/i.test(navigator.userAgent)) ? "app-main iphoneMob" : "app-main"}>
            <Container style={{ paddingTop: 0, paddingBottom: 0, flex: 1, }} >
              <div className="row justify-content-center">
                <div className="col-12 col-sm-10" style={{ maxWidth: '1200px', }}>
                  <div className="app-main_">
                    <div className="app-main-top">
                      <div className="prog_head_main">
                        <div className="prog_head">
                          {query_params.get('i') && progressBarInterviews.includes(query_params.get('i')) ? <div>
                            <h3> {query_params.get('title')} (<b>{progressBar}%</b>)</h3>
                          </div> : <h3> {query_params.get('title')}</h3>}
                          {/* <div >
                            <h3><b>{progressBar} %</b></h3>
                          </div> */}
                        </div>
                        {query_params.get('i') && progressBarInterviews.includes(query_params.get('i')) ? <ProgressBar style={{ marginTop: '10px' }} now={progressBar} max={100} min={0} /> : null}
                      </div>
                    </div>
                    <div className="app-main-inner chat-box">
                      <MessageList setPBar={setPBar} myref={myref} ynquestion={messages.ynquestion} mStart={mStart} messages={messages.data} link={messages.link} interview_title={messages.title}
                        onResults={(rawUserResponse) => {
                          if (messages.data && messages.data.length == 3) {
                            localStorage.setItem('lastloader', 'true');
                          }
                          if (messages.data[messages.data.length - 1].variable && messages.data[messages.data.length - 1].variable == 'voice_or_text') {
                            if (rawUserResponse.toLowerCase() == 'voice' || rawUserResponse.toLowerCase() == 'continue with voice' || rawUserResponse.toLowerCase() == 'continuar con voz') {
                              volumeMute('false');
                            } else if (rawUserResponse.toLowerCase() == 'text' || rawUserResponse.toLowerCase() == 'continue with text' || rawUserResponse.toLowerCase() == 'type' || rawUserResponse.toLowerCase() == 'continuar con el texto') {
                              volumeMute('true');
                            }
                          } else {
                            if (localStorage.getItem('muteBot') == 'false') {
                              setConsec3(consec3 + 1);
                            }
                          }
                          if (localStorage.getItem('micStarted') != "true" || opera) {
                            setMessages({ ...messages, rawUserResponse, ynquestion: null });

                            setMessageUpdated({
                              ...msgUpdated,
                              messageUpdated: false,
                              // bufferLen: 0
                            });
                          } else if (localStorage.getItem('response') == "false") {
                            localStorage.setItem('response', rawUserResponse);

                            setMessageUpdated({
                              ...msgUpdated,
                              messageUpdated: "answered",
                              // bufferLen: 0
                            });
                          }
                        }}
                      />
                    </div>
                    <ChatFooter
                      consec3={consec3}
                      setCons={setCons}
                      goIntoView={navigateBot}
                      setMStart={setMic}
                      messages={messages}
                      setMessages={setMessages}
                      resetSession={resetSession}
                      useOldSession={useOldSession}
                      msgUpdated={msgUpdated}
                      setMessageUpdated={setMessageUpdated}
                      setLongMessages={setLongMessages}
                    />
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <Footer />
        </>
      )}
    />
  );
};
