import * as React from 'react';
import './chat-footer.scss';
import { Container } from '../Container';
import { Mic } from '../Mic';
import { IRenderProps } from '../../Containers/MessageContainer';
import { Button } from '../Button';
import { AuthContext } from '../../contexts/AuthContext';
import Axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import * as Config from '../../config';
import outer_ from '../../assets/images/outer.png';
import inputouter from '../../assets/images/inputouter.svg';
import * as LangConfig from '../../languages/languages';
import inputouter_mobile from '../../assets/images/mobile.svg';
import SendIcon from '@material-ui/icons/Send';
export interface IWindow extends Window {
  webkitSpeechRecognition: any;
}

export const ChatFooter: React.FC<IRenderProps> = ({
  consec3,
  setCons,
  goIntoView,
  setMStart,
  messages,
  setMessages,
  resetSession,
  msgUpdated,
  setMessageUpdated,
  setLongMessages
}) => {
  const auth = React.useContext(AuthContext);
  const [inputVal, setInputVal] = React.useState<string>('');
  const [translations, setTranslations] = React.useState(auth['user']['attributes'].locale ? LangConfig.languages[auth['user']['attributes'].locale]['translations'] : LangConfig.languages['en']['translations']);
  const [consec3status, setConsec3Status] = React.useState(false);
  const [consec3Popup, setConsec3Popup] = React.useState(false);
  const [botMute, setbotMute] = React.useState("false");
  const [lastIn, setlastIn] = React.useState(null);
  const opera = navigator.userAgent.match(/Opera|OPR\//) ? true : false;
  const [hasBrowserSupport, setHasBrowserSupport] = React.useState<Boolean>(
    false,
  );

  const [micStart, setMicStart] = React.useState<string>('');
  const [messageSend, setMessageSend] = React.useState<string>('');

  let query_params = new URLSearchParams(window.location.search);
  let interviewId = query_params.get('interviewId');
  let titleVal = query_params.get('title');


  const [values, setValues] = React.useState({
    myinterviews: []
  });

  const listAvailInterviews = async () => {
    let sess = await Axios.post(Config.API_BASE_URL + 'getDocAssembleUserData/', {
      userId: auth['user']['username'],
      host: Config.appHost
    });
    let data = sess.data.DocAssembleData.filter(ele => ele.title == titleVal && ele.fileNumber && ele.fileNumber != 0);
    for (let d of data) {
      await Axios.post(Config.API_BASE_URL + 'delDocAssembleUserData/', {
        id: d._id
      });
    }
  }


  const { webkitSpeechRecognition }: IWindow = window as IWindow;

  if (webkitSpeechRecognition) {
    !hasBrowserSupport && setHasBrowserSupport(true);
  }

  if (navigator.userAgent.indexOf('Chrome') == -1 && micStart != "messageInput") { //Chrome
    setMicStart('messageInput');
  }

  const sendUserMessage = () => {
    if (messages.data[messages.data.length - 1].sentBy == 'BOT' && msgUpdated.bufferLen != 0) {

      // if (messages.data[messages.data.length - 1].sentBy == 'BOT') {
      handleShow();
      let mes = inputVal ? inputVal : ' ';
      if (messages.data[messages.data.length - 1].variable && messages.data[messages.data.length - 1].variable == 'voice_or_text') {
        if (inputVal.toLowerCase() == 'voice' || inputVal.toLowerCase() == translations.Continue_with_voice.toLowerCase()) {
          mes = translations.Continue_with_voice;
          volumeMute('false');
        } else if (inputVal.toLowerCase() == 'text' || inputVal.toLowerCase() == translations.Continue_with_text.toLowerCase() || inputVal.toLowerCase() == 'type') {
          mes = translations.Continue_with_text;
          volumeMute('true');
        }
      } else {
        if (localStorage.getItem('muteBot') == 'false') {
          setCons(true);
        }
      }

      if (messages.data[messages.data.length - 1].questionRange.hint && messages.data[messages.data.length - 1].questionRange.hint == 'canskip') {
        if (mes.toLowerCase() == "i don't know" || mes.toLowerCase() == "i don't remember" || mes.toLowerCase() == "don't know" || mes.toLowerCase() == "don't remember") {
          mes = 'skipbtn';
        }
      }

      setMessages({ ...messages, rawUserResponse: mes, ynquestion: null });
      setInputVal('');
      setMessageSend('micDisable');
    }
  };

  const backButton = () => {
    if (messages.data.length > 0 && (messages.data[messages.data.length - 1].questionType == "deadend" || messages.rawUserResponse == 'deadend')) {
      return
    }
    let index = 0;
    let mess: any = messages.data;
    for (let i = messages.data.length - 1; i >= 0; i--) {
      if (messages.data[i].sentBy == 'PERSON') {
        mess.pop();
        break;
      } else {
        mess.pop();
      }
    }

    for (let i = mess.length - 1; i >= 0; i--) {

      if (mess[i].sentBy == 'BOT') {
        index = i
        break;
      }
    }

    setMessages({ ...messages, data: mess, lastBotIndex: index });
    setlastIn(index);
  }

  const checkBack = () => {
    let index = 0;
    let mess: any = messages.data;
    for (let i = mess.length - 1; i >= 0; i--) {
      if (mess[i].sentBy == 'BOT') {
        index = i
        break;
      }
    }
    if (index == lastIn || messages.data.length == 1) {
      return false;
    } else {
      return true
    }
  }

  const skipQuestion = () => {
    handleShow();
    setInputVal('');
    setMessageSend('micDisable');
    setMessages({ ...messages, rawUserResponse: 'skipbtn' });
  };

  const [show, setShow] = React.useState();
  const handleClose = () => {
    setMicStart('messageInput');
    setShow(false);
  }
  const handleShow = () => setShow(true);

  const instrPopup = () => {
    // if(localStorage.getItem('instpopup') && localStorage.getItem('instpopup') == 'true'){
    setTimeout(() => {
      if (window.location.pathname == '/' && titleVal != null) {
        if (messages.data.length < 1 && interviewId == null) {
          setMessages({ ...messages, fetchNextQuestion: true });
        }
      }
    }, 1400);
    // }else{
    //   setHelp(true);
    //   localStorage.setItem('instpopup', 'true');
    // }
  }

  const [help, setHelp] = React.useState(true);
  const helpClose = () => {
    if (window.location.pathname == '/' && titleVal != null) {
      setMessages({ ...messages, fetchNextQuestion: true });
    }
    setHelp(false);
  }
  const helpShow = () => setHelp(true);


  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      // if "Enter"
      e.preventDefault();
      sendUserMessage();
    } else {
      setMessageSend('msgSend');
    }
  };


  const footerInputClick = () => {
  }

  const inputOnFocus = () => {
    goIntoView();
    setTimeout(() => {
      goIntoView();
    }, 500);
  }

  const volumeMute = (vol) => {
    setbotMute(vol);
    localStorage.setItem('muteBot', vol);
    if (vol == "true") {
      document.getElementById('volume_on').classList.add("hidden");
      document.getElementById('volume_off').classList.remove("hidden");
    } else {
      document.getElementById('volume_off').classList.add("hidden");
      document.getElementById('volume_on').classList.remove("hidden");
    }
  }

  const setLongText = () => {
    let string = '';
    let longMessages = [];
    messages.data.forEach((m, i) => {
      if (((m.questionRange.hint || (messages.data[i - 1] && messages.data[i - 1].questionRange.hint)) && (m.questionRange.hint == 'longtext' || (messages.data[i - 1] && messages.data[i - 1].questionRange.hint == 'longtext'))) || ((m.message && typeof m.message === "string" && m.message.includes("&nbsp;")) || (messages.data[i - 1] && messages.data[i - 1].message && typeof messages.data[i - 1].message == "string" && messages.data[i - 1].message.includes("&nbsp;")))) {
        if (m.sentBy == 'BOT') {
          let text = m.raw && m.raw.fields && m.raw.fields[0] && m.raw.fields[0].helptext !== undefined ? m.raw.fields[0].helptext : m.message.replace(/ *\<[^>]*\> */g, "");
          string = string + '[BR][BR]Question: ' + text;
          longMessages.push(m);
        } else {
          string = string + '[BR]Answer: ' + m.message.replace(/ *\<[^>]*\> */g, "");
        }
      } else if ((m.questionRange.hint || (messages.data[i - 1] && messages.data[i - 1].questionRange.hint)) && (m.questionRange.hint && m.questionRange.hint.includes('textlength|') || (messages.data[i - 1] && messages.data[i - 1].questionRange.hint && messages.data[i - 1].questionRange.hint.includes('textlength|')))) {
        let length: any = 0;
        if (m.questionRange.hint && m.questionRange.hint.includes('textlength|')) {
          length = m.questionRange.hint.replace('textlength|', '');
        } else {
          length = messages.data[i - 1].questionRange.hint.replace('textlength|', '');
        }
        if (m.sentBy !== 'BOT' && m.message.length > parseInt(length)) {
          let text = messages.data[i - 1].message.replace(/ *\<[^>]*\> */g, "");
          string = string + '[BR][BR]Question: ' + text;
          string = string + '[BR]Answer: ' + m.message.replace(/ *\<[^>]*\> */g, "");
          longMessages.push(messages.data[i - 1]);
        }
      }
    });
    setLongMessages(longMessages);
    setMessages({ ...messages, rawUserResponse: string });
    setMessageUpdated({
      ...msgUpdated,
      messageUpdated: false
    });
  }

  const inputOnBlur = () => {

  }

  React.useEffect(() => {
    // instrPopup();
    listAvailInterviews();
  }, []);

  React.useEffect(() => {
    setMStart(micStart);
  }, [micStart]);

  React.useEffect(() => {
    if (consec3 == 3 && !consec3status) {
      setConsec3Popup(true);
      localStorage.setItem('muteBot', 'true');
    }
  }, [consec3]);

  if (messages.data.length == 1 && msgUpdated.bufferLen != 0) {
    let userD: any = (auth.user as any).attributes.given_name ? (auth.user as any).attributes.given_name : '  ';
    setMessages({ ...messages, rawUserResponse: userD });
    setMessageUpdated({
      ...msgUpdated,
      messageUpdated: false
    });
  }

  if (messages.data.length >= 1 && messages.data[messages.data.length - 1].raw.fields && messages.data[messages.data.length - 1].raw.fields[0].datatype == "file" && msgUpdated.messageUpdated == "file") {
    setMessages({ ...messages, rawUserResponse: "fileUpload" });
    setMessageUpdated({
      ...msgUpdated,
      messageUpdated: false,
      // bufferLen: 0
    });
  }

  if (messages.data.length > 1 && messages.data[messages.data.length - 1].message == 'text_long') {
    localStorage.setItem('lastloader', 'true');
    setLongText();
  }

  if (messages.data.length >= 1 && messages.data[messages.data.length - 1].raw.questionType == "deadend" && msgUpdated.messageUpdated == true) {
    setMessages({ ...messages, rawUserResponse: "deadend" });
    setMessageUpdated({
      ...msgUpdated,
      messageUpdated: false,
      // bufferLen: 0
    });
  }

  if ((micStart != "messageInput" && messages.data.length >= 1 && messages.data[messages.data.length - 1].sentBy == 'BOT' && msgUpdated.bufferLen != 0 && msgUpdated.messageUpdated == true && localStorage.getItem('muteBot') == "true")) {
    setMicStart('messageInput');
  }

  return (

    <footer className={(!hasBrowserSupport || opera) ? (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) ? "chat-footer browserCompatibility mobDevices" : "chat-footer browserCompatibility" : (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) ? "chat-footer mobDevices" : "chat-footer"}>


      <div className="shadowl_r pb-3">
        {messages.data.length >= 1 ? <div className="navigat_btn">

          {checkBack() && msgUpdated.bufferLen != 0 && (micStart == 'messageInput' || opera || (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))) ?
            <button disabled={messages.data.length > 1 && (messages.data[messages.data.length - 1].questionType == "deadend" || messages.rawUserResponse == 'deadend' || messages.data[messages.data.length - 1].message == 'text_long' || messages.data[messages.data.length - 2].message == 'text_long') ? true : false} className="btn btn-info" onClick={backButton} ><svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="7.29638" height="1.62142" transform="matrix(-0.707107 0.707107 0.707107 0.707107 11.6445 0)" fill="#16ABFF" /><rect width="7.29638" height="1.62142" transform="matrix(-0.707107 0.707107 0.707107 0.707107 5.15918 0)" fill="#16ABFF" /><rect width="7.29638" height="1.62142" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 13 9.24165)" fill="#16ABFF" /><rect width="7.29638" height="1.62142" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 6.51465 9.24165)" fill="#16ABFF" /></svg> {translations.Back}</button> :
            <button disabled className="btn btn-info" onClick={() => { }} ><svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="7.29638" height="1.62142" transform="matrix(-0.707107 0.707107 0.707107 0.707107 11.6445 0)" fill="#16ABFF" /><rect width="7.29638" height="1.62142" transform="matrix(-0.707107 0.707107 0.707107 0.707107 5.15918 0)" fill="#16ABFF" /><rect width="7.29638" height="1.62142" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 13 9.24165)" fill="#16ABFF" /><rect width="7.29638" height="1.62142" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 6.51465 9.24165)" fill="#16ABFF" /></svg> {translations.Back}</button>}

          {messages.data.length >= 1 && msgUpdated.bufferLen != 0 && messages.data[messages.data.length - 1].sentBy == 'BOT' && (micStart == 'messageInput' || opera || (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))) && messages.data[messages.data.length - 1].raw.questionType !== "deadend" ?
            <button disabled={messages.data.length > 0 && (messages.data[messages.data.length - 1].questionType == "deadend" || messages.rawUserResponse == 'deadend') ? true : false} className=" btn btn-info" onClick={skipQuestion}>{translations.Skip} <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.35547" width="7.29638" height="1.62142" transform="rotate(45 1.35547 0)" fill="#16ABFF" /><rect x="7.84082" width="7.29638" height="1.62142" transform="rotate(45 7.84082 0)" fill="#16ABFF" /><rect y="9.24165" width="7.29638" height="1.62142" transform="rotate(-45 0 9.24165)" fill="#16ABFF" /><rect x="6.48535" y="9.24165" width="7.29638" height="1.62142" transform="rotate(-45 6.48535 9.24165)" fill="#16ABFF" /></svg></button> :
            <button disabled className=" btn btn-info" onClick={() => { }} >{translations.Skip} <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.35547" width="7.29638" height="1.62142" transform="rotate(45 1.35547 0)" fill="#16ABFF" /><rect x="7.84082" width="7.29638" height="1.62142" transform="rotate(45 7.84082 0)" fill="#16ABFF" /><rect y="9.24165" width="7.29638" height="1.62142" transform="rotate(-45 0 9.24165)" fill="#16ABFF" /><rect x="6.48535" y="9.24165" width="7.29638" height="1.62142" transform="rotate(-45 6.48535 9.24165)" fill="#16ABFF" /></svg></button>}
        </div> : null}
        <Mic
          onResults={(rawUserResponse, messageUpdated) => {
            handleShow(); setMicStart('');
            if (localStorage.getItem('response') != "false" && (messages.data.length >= 1 && messages.data[messages.data.length - 1].raw.questionType == "yesno") && messageUpdated == "answered") {
              rawUserResponse = localStorage.getItem('response');
            }
            let mes = rawUserResponse ? rawUserResponse : ' ';
            if (messages.data[messages.data.length - 1].variable && messages.data[messages.data.length - 1].variable == 'voice_or_text') {
              if (rawUserResponse.toLowerCase() == 'voice' || rawUserResponse.toLowerCase() == translations.Continue_with_voice.toLowerCase()) {
                mes = translations.Continue_with_voice;
                volumeMute('false');
              } else if (rawUserResponse.toLowerCase() == 'text' || rawUserResponse.toLowerCase() == translations.Continue_with_text.toLowerCase() || rawUserResponse.toLowerCase() == 'type') {
                mes = translations.Continue_with_text;
                volumeMute('true');
              }
            }
            setCons(false);
            setMessages({ ...messages, rawUserResponse: mes, ynquestion: null });
            setMessageUpdated({
              ...msgUpdated,
              messageUpdated: false,
              // bufferLen: 0
            });
          }}
          onMicInput={(micStarted) => {
            if (micStarted == "true") {
              setMicStart('');  // disabled
            } else if (micStarted == "false") {
              setMicStart('messageInput');  // not disabled
              setMessageSend('');
            } else if (micStarted == "other") {
              setMicStart('validationCase');
            } else if (micStarted == "manualStop") {
              setMicStart('manualStop');  // disabled            
            }
          }}
          onBotResponse={(messages.data.length >= 1 && messages.data[messages.data.length - 1].raw.questionType != "deadend" ? msgUpdated.messageUpdated : (messages.data.length === 1 && messages.data[messages.data.length - 1].raw.questionType != "deadend" ? msgUpdated.messageUpdated : false))}
          inputError={show == true ? (messages.rawUserResponse == null ? undefined : show) : show}
          messageSend={messageSend}
          micStarted={micStart}
          onVolumeOff={(muteBot) => {
            setbotMute(muteBot);
            localStorage.setItem('muteBot', muteBot);
            //setMessages({...messages, botMute: (muteBot == "true" ? true : false)});
          }}
          interviewInitiated={messages.data.length}
          questionType={messages.data.length >= 1 && messages.data[messages.data.length - 1].raw.questionType}
          messages={messages.data}
        />
        <Modal show={consec3Popup && messages.data.length >= 1 && messages.data[messages.data.length - 1].raw.questionType && messages.data[messages.data.length - 1].raw.questionType !== 'deadend'}>
          <Modal.Header closeButton>
            <Modal.Title>Instructions</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: 'inherit' }} dangerouslySetInnerHTML={{ __html: '<p>We noticed you are not submitting your responses by voice. Would you like to proceed by text only ?</p>' }}></Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-primary text-white" onClick={() => {
              setConsec3Popup(false);
              volumeMute('true');
            }}>
              Yes
                </Button>
            <Button className="btn btn-primary text-white" onClick={() => {
              setConsec3Popup(false);
              volumeMute('false');
            }}>
              No
                </Button>
          </Modal.Footer>
        </Modal>
        <div className="test">
          {
            messages.data.length < 1 && interviewId == null ?
              (
                <Modal show={help && titleVal !== null}>
                  <Modal.Header closeButton>
                    <Modal.Title>{translations.Instructions}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ backgroundColor: 'inherit' }} dangerouslySetInnerHTML={{ __html: window.location.hostname == 'help4tn.atjbot.org' ? '<iframe width="100%" height="250" src="https://www.youtube.com/embed/AWGr3c9fMPc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' : '<iframe width="100%" height="250" src="https://www.youtube.com/embed/3xY5iC0p21E" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' }}></Modal.Body>
                  <Modal.Footer>
                    <Button className="btn btn-primary text-white" onClick={helpClose}>
                      {translations.close}
                    </Button>
                  </Modal.Footer>
                </Modal>
              )
              :
              ''
          }
          {
            messages.validation != null && messages.rawUserResponse != null && messages.rawUserResponse !== 'deadend' ?
              (
                <Modal show={show}>
                  <Modal.Header closeButton>
                    <Modal.Title>{translations.Error}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ backgroundColor: 'inherit' }} dangerouslySetInnerHTML={{ __html: messages.validation }}></Modal.Body>
                  <Modal.Footer>
                    <Button className="btn btn-primary text-white" onClick={handleClose}>
                      {translations.close}
                    </Button>
                  </Modal.Footer>
                </Modal>
              )
              :
              ''
          }
          {(messages.data.length >= 1 && msgUpdated.bufferLen != 0 && messages.data[messages.data.length - 1].sentBy == 'BOT' && (micStart == 'messageInput' || (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))) && messages.data[messages.data.length - 1].raw.questionType != "deadend") || (messages.data.length >= 1 && (!hasBrowserSupport || opera) && messages.rawUserResponse !== 'deadend') ?
            <div className="chat-footer-input">


              <div className="chat-footer-inputbox">
                <div className="chat-footer-inputouter">
                  <img src={outer_} />
                </div>
                <input
                  disabled={messages.data.length > 0 && messages.data[messages.data.length - 1].questionType == "deadend" ? true : false}
                  type="text"
                  ref={(node) => {
                    // let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                    // if(!isMobile){
                    let doc: any = document.getElementsByClassName('chat-footer-input__input')[0];
                    doc.focus();
                    goIntoView();
                    // }
                  }}
                  name="chat-footer-input"
                  className="chat-footer-input__input"
                  placeholder={translations.type_response + '...'}
                  onChange={ele => setInputVal(ele.target.value)}
                  value={inputVal}
                  onKeyDown={inputOnKeyDown}
                  onBlur={inputOnBlur}
                  onFocus={inputOnFocus}
                />
                <button
                  disabled={messages.data.length > 0 && messages.data[messages.data.length - 1].questionType == "deadend" ? true : false}
                  className="chat-footer-input__button"
                  onClick={sendUserMessage} > <span className="material-icons" aria-hidden="true">send</span> </button>
              </div>
            </div>
            :
            <div className="chat-footer-input" onClick={footerInputClick}>

              <div className="chat-footer-inputbox">
                <div className="chat-footer-inputouter">
                  <img src={outer_} />
                </div>
                <input disabled type="text" name="chat-footer-input" className="chat-footer-input__input"
                  placeholder={translations.type_response + '...'}
                  onChange={ele => setInputVal(ele.target.value)}
                  value={inputVal}
                  onKeyDown={inputOnKeyDown}
                />
                <button disabled className="chat-footer-input__button"
                  onClick={sendUserMessage}
                > <span className="material-icons" aria-hidden="true">send</span> </button>

              </div>
            </div>
          }
          {(!hasBrowserSupport || opera) ? <div className="text-white browserCompatible"><div className="alertMsg">
            Your Browser Doesn't Support SpeechRecognition. Try using the latest
                version of Google Chrome.</div>
          </div> : null}

        </div>
      </div>

    </footer>

  );
};
