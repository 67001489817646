import * as React from 'react';
import '../Chat/app.scss';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import { MessageContainer } from '../../Containers/MessageContainer';
import { AuthContext } from '../../contexts/AuthContext';
import Axios from 'axios';
import { DAClient } from '../../lib/DAClient';
import SimpleSnackbar from '../SimpleSnackbar';
import * as Config from '../../config';
import { Divider } from '@material-ui/core';
import Spinner from 'react-bootstrap/Spinner';
import Switch from '@material-ui/core/Switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const da = new DAClient();



export const UploadInterview: React.FC = (props: any) => {

	const [state, setState] = React.useState({
		checkedA: true,
		checkedB: true,
	});

	const handleChange = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};

	const auth = React.useContext(AuthContext);
	const [loading, setLoading] = React.useState(false);
	const [checkRef, setCheckRef] = React.useState(null);
	const [tloading, setTLoading] = React.useState(false);
	const [configuration, setConfiguration] = React.useState<any>({});
	const [files, setFiles] = React.useState<any>([]);
	const [interviews, setInterviews] = React.useState<any>([]);
	const [message, setMessage] = React.useState<any>([]);


	const getConfigurations = async () => {
		let configurations = await da.getConfigurations();
		let files = await da.listFiles('questions');
		let res = await Axios.get(Config.API_BASE_URL + 'getcompletedinterview/' + auth['user']['username']);
		if (res.data.data.length > 0) {
			setInterviews(res.data.data);
		}
		setFiles(files);
		setConfiguration(configurations);
		setLoading(false);
	}

	const getEnabled = (fileName) => {
		if (configuration.dispatch) {
			let testObj = Object.create(configuration);
			let file = Object.keys(testObj.dispatch).filter(e => {
				let sample = testObj.dispatch[e].replace('docassemble.playground1:', '');
				if (sample == fileName) {
					return true
				}
				return false;
			});
			if (file.length > 0) {
				return true
			}
		}
		return false;
	}

	const checkAvailability = (fileName) => {
		let added = interviews.filter(el => {
			if (el['interview'][0]['metadata']['title'].replace(/\s+/g, '_') + '.yml' == fileName && el.completed == true) {
				return true;
			}
			return false;
		});
		if (added.length > 0) {
			return true;
		}
		return false;
	}

	const changeConfig = (e, fileName) => {
		let testObj: any = JSON.parse(JSON.stringify(configuration));
		if (testObj.dispatch) {
			let file: any = Object.keys(testObj.dispatch).filter(el => {
				let sample = testObj.dispatch[el].replace('docassemble.playground1:', '');
				if (sample == fileName) {
					return true
				}
				return false
			});
			if (file.length > 0) {
				delete testObj.dispatch[file[0]];
			} else {
				testObj.dispatch[fileName.replace('.yml', '')] = 'docassemble.playground1:' + fileName;
			}
			setConfiguration(testObj);
			toast.success('Restart server for changes to take effect.', {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
			});
		}
	}

	const submit = async () => {
		if (window.confirm('Server will be down for about 20-30 seconds before it comes back up with the changes.')) {
			setTLoading(true);
			let m = [];
			try {
				await da.editConfigurations(configuration);
				m.push('Configurations updated successfully.');
				setMessage(m);
				m = []
				m.push('System Restarted, usually takes 25 to 30 seconds. Please have patience.');
				setTimeout(() => {
					setMessage(m);
				}, 2000);
				setTLoading(false);
			} catch (err) {
				setTLoading(false);
			}
		}
	}

	const checkSubmitStatus = (node) => {
		if (!checkRef && node) {
			setCheckRef(node);
		}
	}


	React.useEffect(() => {
		setLoading(true);
		getConfigurations();
	}, [auth]);

	return (
		<MessageContainer
			render={({ messages, setMessages, resetSession, useOldSession }) => (
				<>
					<ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
					<ToastContainer />
					<div>
						{loading ? <div className="loader-cont"><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div> : null}
						<Header resetSession={resetSession} />
						{message.map(
							(err, i) => <SimpleSnackbar key={i} message={err} />,
						)}
						<div className="app-main">
							<div className="app-main-inner">
								<Container
									style={{
										paddingTop: 30,
										paddingBottom: 30,
									}}
								>
									<div className="table-responsive configurations">
										<table className="table">
											<thead>
												<tr>
													<th colSpan={2} className="border-right-0 interviewHeading">
														<div className="row d-flex flex-wrap justify-content-center align-items-stretch py-2">
															<div className="col-md-6  col-xs-12 d-flex align-items-center"><h1 className="h2 mb-0">configurations</h1></div>
															<div className="col-md-6  col-xs-12 d-flex justify-content-end">{files.length > 0 && checkRef ? <div><button disabled={tloading} onClick={() => submit()} title="Restart Server" className="btn btn-primary text-capitalize">Restart Server</button>&nbsp;&nbsp;
											{tloading ? <Spinner animation="border" style={{ width: '1rem', height: '1rem', marginRight: '1em' }} /> : ''}
															</div> : null}</div>
														</div>

													</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														<div className="row d-flex flex-wrap justify-content-center align-items-stretch">
															<div className="col-12 bg-white">
																{files.map(e => {
																	if (e.includes('.yml')) {
																		return (
																			<div className='row'>
																				<div className="col-11 d-flex align-items-center brdr-btm pl-0">
																					<div className="config_heading">

																						{checkAvailability(e) ? <span className="con_switch"><Switch
																							ref={checkSubmitStatus}
																							checked={getEnabled(e)}
																							onChange={(ev) => changeConfig(ev, e)}
																							color="primary"
																							name="checkedB"
																							inputProps={{ 'aria-label': 'primary checkbox' }}
																						/></span> : null}

																						<span>{e}</span></div>
																				</div>

																			</div>
																		)
																	} else {
																		return null
																	}
																})}

															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Container>
							</div>
						</div>
						<Footer />
					</div>
				</>
			)}
		/>
	);
};
