import { ApiBase } from './ApiBase';
import Axios from 'axios';
import * as Config from '../config';

export interface MessageResponse {
  message?: string;
  error?: Error;
  data?: any;
}

export class DAClient extends ApiBase {
  public session: string = '';
  public secret: string = '';
  public i: string = Config.i;

  constructor() {
    super({ baseUrl: '/api' });
  }
  /**
   * Sets the Authorization header with the user's Clio Access Token
   * and sets the x-uat header with the Cognito Access Token for
   * situations where the Clio Access Token is expired and we
   * need to grab a new one with the Refresh Token.
   */
  private setAuthHeader() {
    return {
      headers: {
        //'X-API-Key': 'HYT310F0TFVD390WZ9YGBGKNIKJ76PM9',
        //'X-API-Key': '5ORgSSZT8mdIVf1mC8NhxavC4GL6tKRq', //admin
        'X-API-Key': Config.API_KEY, //abhishekarora1028+111
      },
    };
  }

  private setSession() {
    return ({
      i: this.i,
      session: this.session,
      secret: this.secret,
    });
  }

  private urlParams(obj: any) {
    // console.log(obj);
    return Object.entries(obj)
      .map(([key, val]) => `${key}=${encodeURIComponent(val.toString())}`)
      .join('&');
  }

  public async getQuestion(): Promise<any | Error> {
    const headers = this.setAuthHeader();
    const session = this.urlParams(this.setSession());
    // console.log(session);
    const { data } = await this.get(`session/question?${session}`, {
      ...headers,
    });
    // console.log(data);
    return data;
  }

  public async editInterview(result): Promise<any> {
    var that = this;
    const headers = this.setAuthHeader();
    const { data } = await this.get(`session/new?i=` + this.i, {
      ...headers,
    });
    this.secret = data.secret;
    this.session = data.session;
    return data;
  }


  public async updateVariable(
    questionName: string,
    variables: {},
  ): Promise<any | Error> {
    const headers = this.setAuthHeader();
    // console.log(this.setSession());

    const { data } = await this.post(
      `session`,
      {
        ...this.setSession(),
        variables,
        question_name: questionName,
        advance_progress_meter: 1
      },
      {
        ...headers,
      },
    );

    // console.log(data);
    return data;
  }

  public async newSession(): Promise<any> {
    // console.log(this.i);
    const headers = this.setAuthHeader();
    const i = this.urlParams({ i: this.i });
    const { data } = await this.get(`session/new?${i}`, {
      ...headers,
    });
    this.secret = data.secret;
    this.session = data.session;
    return data;
  }

  public async useOldSession(): Promise<any> {
    return this.setSession();
  }

  public async listInterviews(): Promise<any> {
    const headers = this.setAuthHeader();
    const { data } = await this.get(`list`, {
      ...headers,
    });
    return data;
  }

  public async retrieveFile(fileNumber, title = 'testname'): Promise<any> {
    const FileDownload = require('js-file-download');
    const i = this.urlParams({ i: this.i });
    return new Promise((resolve, reject) => {
      Axios.get(Config.DA_API_BASE_URL + `/file/${fileNumber}?${i}`, {
        headers: {
          'X-API-Key': Config.API_KEY,
        },
        responseType: 'blob', //Force to receive data in a Blob Format
      }).then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        // window.open(fileURL);

        // Download the file
        FileDownload(file, title + '_' + Date.now() + '.pdf');
        return resolve(true);
      }).catch(err => {
        return resolve(true);
      });
    });
  }

  public async resumeInterview(result): Promise<any> {
    var that = this;
    result.questionAns.map(function (res, i) {
      that.updateVariableforResume(res.questionName, res.variable, result);
    });
  }

  public async updateVariableforResume(
    questionName: string,
    variables: {},
    result: any
  ): Promise<any | Error> {
    const headers = this.setAuthHeader();

    const { data } = await this.post(
      `session`,
      {
        i: result.i,
        session: result.session,
        secret: result.secret,
        variables,
        question_name: questionName,
        advance_progress_meter: 1
      },
      {
        ...headers,
      },
    );

    this.i = result.i;
    this.session = result.session;
    this.secret = result.secret;
    return data;
  }

  public async UploadFile(formData): Promise<any> {
    const headers = this.setAuthHeader();
    const { data } = await this.post(`playground`, formData, {
      ...headers,
    });
    return data;
  }

  public async ExtractFields(formData): Promise<any> {
    const headers = this.setAuthHeader();
    const { data } = await this.post(`fields`, formData, {
      ...headers,
    });
    return data;
  }

  public async getConfigurations(): Promise<any> {
    const headers = this.setAuthHeader();
    const { data } = await this.get(`config`, {
      ...headers,
    });
    return data;
  }

  public async editConfigurations(formData): Promise<any> {
    const headers = this.setAuthHeader();
    const { data } = await this.post(`config`, { config: formData }, {
      ...headers,
    });
    return data;
  }

  public async listFiles(folder): Promise<any> {
    const headers = this.setAuthHeader();
    const { data } = await this.get(`playground?folder=${folder}`, {
      ...headers,
    });
    return data;
  }

  public async deleteFile(filename): Promise<any> {
    await this.delete(`playground?key=${Config.API_KEY}&folder=questions&filename=${filename}`);
  }
}

