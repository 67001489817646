import React, {
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

import Axios from 'axios';
import { DAClient } from '../lib/DAClient';
import { IMessage } from '../components/MessageList';
import { AuthContext } from '../contexts/AuthContext';
import AWS from 'aws-sdk';
import * as LangConfig from '../languages/languages';
import * as Config from '../config';

const da = new DAClient();
AWS.config.update(Config.AWSConfigExport);
const cognito = new AWS.CognitoIdentityServiceProvider();

interface IMessageState {
  data: IMessage[];
  lastBotIndex: number | null;
  rawUserResponse: any | null;
  fetchNextQuestion: boolean;
  link: string | null;
  progress: number | null;
  title: string | null;
  validation: string | null;
  botMute: boolean;
  resumeIndex: number | null;
  ynquestion: String | null;
}

interface IMsgUpdate {
  messageUpdated: any;
  bufferLen: any;
}

interface ISessionState {
  session: string | null;
  secret: string | null;
  i: string | null;
  idata: object | null;
}

interface ICurrentInterview {
  id: string | null;
}

export interface IRenderProps {
  consec3: any;
  setCons: (text: any) => any;
  goIntoView: () => any;
  setMStart: (text: any) => any;
  messages: IMessageState;
  setMessages: Dispatch<SetStateAction<IMessageState>>;
  resetSession: () => any;
  useOldSession: () => any;
  msgUpdated: IMsgUpdate;
  setMessageUpdated: Dispatch<SetStateAction<IMsgUpdate>>;
  setLongMessages
}

interface IMessageProps {
  render: (props: IRenderProps) => any;
}

function toArrayBuffer(myBuf: any) {
  var myBuffer = new ArrayBuffer(myBuf.length);
  var res = new Uint8Array(myBuffer);
  for (var i = 0; i < myBuf.length; ++i) {
    res[i] = myBuf[i];
  }
  return myBuffer;
}

export const MessageContainer: React.FC<IMessageProps> = ({ render }) => {
  const auth = React.useContext(AuthContext);
  const [langCode, setLangCode] = React.useState(auth['user']['attributes'].locale && auth['user']['attributes'].locale ? auth['user']['attributes'].locale : 'en');
  let query_params = new URLSearchParams(window.location.search);
  const [translations, setTranslations] = React.useState(auth['user']['attributes'].locale ? LangConfig.languages[auth['user']['attributes'].locale]['translations'] : LangConfig.languages['en']['translations']);
  let iVal = query_params.get('i');
  let titleVal = query_params.get('title');
  let total_steps = query_params.get('total_steps');
  let interviewId = query_params.get('interviewId');

  let userCognitoGroups = auth.accessToken.payload['cognito:groups'];

  if (iVal != null) {
    da.i = iVal;
  }

  const [messages, setMessages] = useState<IMessageState>({
    data: [],
    lastBotIndex: null,
    rawUserResponse: null,
    fetchNextQuestion: false,
    link: null,
    progress: 0.0,
    title: titleVal,
    validation: null,
    botMute: null,
    resumeIndex: null,
    ynquestion: null
  });

  const [msgUpdated, setMessageUpdated] = useState<IMsgUpdate>({
    messageUpdated: false,
    bufferLen: 0
  });

  const [longMessages, setLongMessages] = useState([]);

  const [sessiondata, setsessiondata] = useState<ISessionState>({
    session: null,
    secret: null,
    i: null,
    idata: null
  });

  const [currentInterview, setcurrentInterview] = useState<ICurrentInterview>({
    id: interviewId
  });
  const [playingAudios, setPlayingAudios] = useState([]);

  const volumeRateValues = ['x-slow', 'slow', 'medium', 'default', 'fast', 'x-fast'];

  const updateAndFetchNext = async (lastMessage: IMessage) => {
    await updateVariableInDocassemble(lastMessage);
  };

  const endInterview = () => {
    const raw = '';
    const link = '';
    const progress = Number((Number(total_steps) * 100.0) / Number(total_steps));
    const message: IMessage = {
      raw,
      questionName: '',
      message: 'You have reached the end of the interview. <br/> I hope you had a good time!',
      sentBy: 'BOT',
      createdAt: new Date(),
      variable: '',
      questionType: '',
      questionChoices: '',
      questionRange: '',
      bubbles: [],
      bubbleIndex: 0
    };
    const data = [...messages.data, message];
    setMessages({ ...messages, data, link, progress });
  };
  const consec3 = 0;

  const goIntoView = () => {

  }

  const setCons = () => {

  }

  const setMStart = () => {

  }

  const translateToEnglish = (text) => {
    return new Promise(async (resolve, reject) => {
      try {
        let trns = await Axios.post(Config.API_BASE_URL + 'translate/', {
          text: text
        });
        return resolve(trns.data.text);
      } catch (err) {
        return reject(err);
      }
    });
  }

  const updateVariableInDocassemble = async (lastMessage: IMessage) => {
    try {
      if (messages.lastBotIndex === null) {
        throw Error('No Bot Message Index');
      }

      let botMessage: any;
      let botIndex: any;
      if (interviewId && messages.data.length > 0 && messages.lastBotIndex + 1 == sessiondata.idata['questionAns'].length) {
        botIndex = messages.lastBotIndex * 2 + 1;
        botMessage = messages.data[messages.lastBotIndex * 2 + 1];
      } else {
        botIndex = messages.lastBotIndex;
        botMessage = messages.data[messages.lastBotIndex];
      }

      let correctedAnswer: any = lastMessage.message;
      if (botMessage.questionType == 'boolean') {
        let userMsgArr = lastMessage.message.toLowerCase().split(/\s+|\./); // split words based on whitespace or a '.'
        correctedAnswer = (lastMessage.message.toLowerCase() == "y" || lastMessage.message.toLowerCase() == "i don't know" || lastMessage.message.toLowerCase() == "don't know" || lastMessage.message.toLowerCase() == "do not know" || lastMessage.message.toLowerCase() == "i do not know" || userMsgArr.indexOf('okay') >= 0 || userMsgArr.indexOf('sure') >= 0 || userMsgArr.indexOf('yet') >= 0 || userMsgArr.indexOf('yes') >= 0 || userMsgArr.indexOf('sí') >= 0 || userMsgArr.indexOf('yep') >= 0 || userMsgArr.indexOf('ok') >= 0 || userMsgArr.indexOf('yeah') >= 0 || userMsgArr.indexOf('alright') >= 0 || userMsgArr.indexOf('continue') >= 0 || userMsgArr.indexOf('confirm') >= 0) ? true : false;
      }


      for (let long of longMessages) {
        let vab = {
          [long.variable]: 'See Continuation Sheet',
        }
        await da.updateVariable(long.questionName, vab);
      }

      if (((botMessage.questionType == "text" && (botMessage.questionChoices == undefined || botMessage.questionChoices == '')) || botMessage.questionType == "area") && langCode !== 'en') {
        try {
          correctedAnswer = await translateToEnglish(correctedAnswer);
        } catch (er) {
        }
      }

      let variables = {
        [botMessage.variable]: correctedAnswer,
      };

      await da.updateVariable(botMessage.questionName, variables);
      setMessages({ ...messages, fetchNextQuestion: true, title: titleVal });

      localStorage.setItem('response', "false");
    } catch (error) {
      // console.error(error);
    }
  };

  const isIE = () => {
    let userAgent = navigator.userAgent;
    return userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1 || userAgent.indexOf("Edge/") > -1;
  }

  const isSafari = () => {
    return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
  }

  var audioStarted = false;

  const bubbleAudio = async (filterMessage, bubblesLength, i, bubblesArr, lastMessage) => {
    if (localStorage.getItem('muteBot') != "true" && !isIE() && !isSafari() && filterMessage !== 'text_long') {
      filterMessage = ((filterMessage).toString().replace(/<br\/>/g, " ").replace(/<\/?p[^>]*>/g, " "));

      try {
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          if (i < bubblesLength - 1) {
            messages.data[messages.data.length - 1].bubbleIndex = i + 1;
            let data = [...messages.data];
            setMessages({ ...messages, data });

            setTimeout(function () {
              bubbleAudio(bubblesArr[i + 1], bubblesLength, i + 1, bubblesArr, lastMessage);
            }, 1000);
            // bubbleAudio(bubblesArr[i+1], bubblesLength, i+1, bubblesArr, lastMessage);
          } else {
            if (lastMessage.raw.fields && lastMessage.raw.fields[0].datatype == "file") {
              setMessageUpdated({
                ...msgUpdated,
                messageUpdated: "file",
                bufferLen: 1000
              });
            } else {
              setMessageUpdated({
                ...msgUpdated,
                messageUpdated: true,
                bufferLen: 1000
              });
            }
            localStorage.setItem('quesCompleted', "true");
          }

        } else {
          filterMessage = "<speak><prosody rate='" + volumeRateValues[Number(localStorage.getItem('volumeRate')) - 1] + "'>" + filterMessage + "</prosody></speak>";
          // console.log(filterMessage);

          const { data } = await Axios.post(Config.API_BASE_URL + 'audio/', {
            text: filterMessage,
            lang: langCode
          });

          const buffer = toArrayBuffer(data.audio.data);
          const audioCtx = new ((window as any).AudioContext ||
            (window as any).webkitAudioContext)();
          var source = audioCtx.createBufferSource();
          audioCtx.decodeAudioData(
            buffer,
            function (buffer: any) {
              source.buffer = buffer;

              // Create a gain node.
              var gainNode = audioCtx.createGain();
              // give the gain node a default value for volume of audio
              // gainNode.gain.value = 0.5;
              // Create a script processor node.
              var scriptNode = audioCtx.createScriptProcessor(4096, 1, 1);

              source.connect(gainNode);
              gainNode.connect(scriptNode);
              scriptNode.connect(audioCtx.destination);
              // source.connect(audioCtx.destination);

              // Give the node a function to process audio events
              scriptNode.onaudioprocess = function (audioProcessingEvent) {
                // The input buffer is the song we loaded earlier
                var inputBuffer = audioProcessingEvent.inputBuffer;

                // The output buffer contains the samples that will be modified and played
                var outputBuffer = audioProcessingEvent.outputBuffer;

                // Loop through the output channels (in this case there is only one)
                for (var channel = 0; channel < outputBuffer.numberOfChannels; channel++) {
                  if (localStorage.getItem('muteBot') == "true") {
                    // gainNode.gain.value = 0.0;
                    source.stop();
                  } else {
                    // gainNode.gain.value = 0.5;
                  }
                  var inputData = inputBuffer.getChannelData(channel);
                  var outputData = outputBuffer.getChannelData(channel);

                  // Loop through the 4096 samples
                  for (var sample = 0; sample < inputBuffer.length; sample++) {
                    // make output equal to the same as the input
                    outputData[sample] = inputData[sample];
                  }
                }
              }

            },
            function (e: any) {
              console.log('Error with decoding audio data' + e.err);
              if (i < bubblesLength - 1) {
                messages.data[messages.data.length - 1].bubbleIndex = i + 1;
                let data = [...messages.data];
                setMessages({ ...messages, data });

                setTimeout(function () {
                  bubbleAudio(bubblesArr[i + 1], bubblesLength, i + 1, bubblesArr, lastMessage);
                }, 1000);
                // bubbleAudio(bubblesArr[i+1], bubblesLength, i+1, bubblesArr, lastMessage);
              } else {
                if (lastMessage.raw.fields && lastMessage.raw.fields[0].datatype == "file") {
                  setMessageUpdated({
                    ...msgUpdated,
                    messageUpdated: "file",
                    bufferLen: 1000
                  });
                } else {
                  setMessageUpdated({
                    ...msgUpdated,
                    messageUpdated: true,
                    bufferLen: 1000
                  });
                }
                localStorage.setItem('quesCompleted', "true");
              }
            },
          );

          audioStarted = true;

          playingAudios.forEach(ele => {
            ele.stop();
          });

          source.start();
          let audioArray = [];
          audioArray.push(source);
          setPlayingAudios(audioArray);

          source.onended = function () {
            audioStarted = false;
            /*if (messages.data.length == 1) {
              localStorage.setItem('muteBot', 'true');
              document.getElementById('volume_on').classList.add("hidden");
              document.getElementById('volume_off').classList.remove("hidden");
            }*/
            if (i < bubblesLength - 1) {
              messages.data[messages.data.length - 1].bubbleIndex = i + 1;
              let data = [...messages.data];
              setMessages({ ...messages, data });

              setTimeout(function () {
                bubbleAudio(bubblesArr[i + 1], bubblesLength, i + 1, bubblesArr, lastMessage);
              }, 1000);
              // bubbleAudio(bubblesArr[i+1], bubblesLength, i+1, bubblesArr, lastMessage);
            } else {
              if (lastMessage.raw.fields && lastMessage.raw.fields[0].datatype == "file") {
                setMessageUpdated({
                  ...msgUpdated,
                  messageUpdated: "file",
                  bufferLen: 1000
                });
              } else {
                setMessageUpdated({
                  ...msgUpdated,
                  messageUpdated: true,
                  bufferLen: 1000
                });
              }
              localStorage.setItem('quesCompleted', "true");
            }
          }
        }
      } catch (error) {
        // console.error(error);
      }
    } else {
      if (i < bubblesLength - 1) {
        messages.data[messages.data.length - 1].bubbleIndex = i + 1;
        let data = [...messages.data];
        setMessages({ ...messages, data });

        setTimeout(function () {
          bubbleAudio(bubblesArr[i + 1], bubblesLength, i + 1, bubblesArr, lastMessage);
        }, 1000);

      } else {
        if (lastMessage.raw.fields && lastMessage.raw.fields[0].datatype == "file") {
          setMessageUpdated({
            ...msgUpdated,
            messageUpdated: "file",
            bufferLen: 1000
          });
        } else {
          setMessageUpdated({
            ...msgUpdated,
            messageUpdated: true,
            bufferLen: 1000
          });
        }
        localStorage.setItem('quesCompleted', "true");
      }
    }
  }

  const fetchAndPlayAudio = async (lastMessage: IMessage) => {
    if (lastMessage.bubbleIndex == 0) {
      var bubbles = ((lastMessage.message).toString().split('<delay>'));
      localStorage.setItem('quesCompleted', "false");

      setTimeout(function () {
        bubbleAudio(bubbles[0], bubbles.length, 0, bubbles, lastMessage);
      }, 1000);
    }
  };

  useEffect(() => {
    if (!interviewId && messages.data.length > 0) {
      let lastMessage = messages.data[messages.data.length - 1];
      if (lastMessage.sentBy === 'BOT') {
        setMessageUpdated({
          ...msgUpdated,
          messageUpdated: false,
          bufferLen: 0
        });
        setMessages({
          ...messages,
          fetchNextQuestion: false,
          lastBotIndex: messages.data.length - 1,
        });

        /** Message by BOT is added */
        fetchAndPlayAudio(lastMessage);
      } else {
        /** Message by PERSON is added */
        updateAndFetchNext(lastMessage);
        /**
         * 1. update variable in docassemble
         * 2. fetch next question
         */
      }
    }


    else if (interviewId && messages.data.length > 0 && messages.lastBotIndex + 1 == sessiondata.idata['questionAns'].length) {
      let lastMsg = messages.data[messages.data.length - 1];

      if (lastMsg.sentBy === 'BOT') {
        setMessageUpdated({
          ...msgUpdated,
          messageUpdated: false,
          bufferLen: 0
        });
        setMessages({
          ...messages,
          fetchNextQuestion: false,
          lastBotIndex: messages.data.length - 1,
        });

        if (messages.data.length > (messages.lastBotIndex + 1) * 2) {
          /** Message by BOT is added */
          fetchAndPlayAudio(lastMsg);
        }
      } else {
        updateAndFetchNext(lastMsg);
      }
    }

    else if (interviewId && messages.data.length > 0 && messages.lastBotIndex > sessiondata.idata['questionAns'].length) {

      console.log("new");
      let lastMessage = messages.data[messages.data.length - 1];
      if (lastMessage.sentBy === 'BOT') {
        setMessageUpdated({
          ...msgUpdated,
          messageUpdated: false,
          bufferLen: 0
        });
        setMessages({
          ...messages,
          fetchNextQuestion: false,
          lastBotIndex: messages.data.length - 1,
        });
        /** Message by BOT is added */
        fetchAndPlayAudio(lastMessage);
      } else {
        /** Message by PERSON is added */
        updateAndFetchNext(lastMessage);
        /**
         * 1. update variable in docassemble
         * 2. fetch next question
         */
      }
    }
  }, [messages.data]);

  useEffect(() => {
    if (sessiondata.idata != null) {
      let index: any = 0;
      if (messages.lastBotIndex != null) {
        index = messages.lastBotIndex + 1;
      }
      if (index < sessiondata.idata['questionAns'].length) {

        let bubbles = ((sessiondata.idata['questionAns'][index].variable.questionText).toString().split('<delay>'));

        let bmessage: IMessage = {
          raw: '',
          questionName: sessiondata.idata['questionAns'][index].questionName,
          message: sessiondata.idata['questionAns'][index].variable.questionText,
          sentBy: 'BOT',
          createdAt: new Date(),
          variable: Object.keys(sessiondata.idata['questionAns'][index].variable)[0],
          questionType: '',
          questionChoices: '',
          questionRange: '',
          bubbles: [],
          bubbleIndex: 0
        };
        let data = [...messages.data, bmessage];
        setMessages({ ...messages, data, fetchNextQuestion: false, lastBotIndex: index, resumeIndex: index });
      }
    }
  }, [sessiondata.idata, messages.title]);

  useEffect(() => {
    if (messages.resumeIndex != null && interviewId && messages.resumeIndex <= sessiondata.idata['questionAns'].length) {
      let index: any = messages.resumeIndex;
      let pmessage: IMessage = {
        raw: '',
        questionName: sessiondata.idata['questionAns'][index].questionName,
        message: sessiondata.idata['questionAns'][index].variable[Object.keys(sessiondata.idata['questionAns'][index].variable)[0]],
        sentBy: 'PERSON',
        createdAt: new Date(),
        variable: Object.keys(sessiondata.idata['questionAns'][index].variable)[0],
        questionType: '',
        questionChoices: '',
        questionRange: '',
        bubbles: [],
        bubbleIndex: -1
      };
      let data = [...messages.data, pmessage];
      setMessages({ ...messages, data, fetchNextQuestion: false, title: Object.keys(sessiondata.idata['questionAns'][index].variable)[0] });
    }
  }, [messages.resumeIndex]);

  useEffect(() => {
    if (msgUpdated.messageUpdated) {
      setMessageUpdated({
        ...msgUpdated,
        messageUpdated: false
      });
    }
  }, [msgUpdated.messageUpdated]);

  const getLinks = (link) => {
    let code = '';
    link.split("&nb&").forEach((el) => {
      if (el) {
        code = code + el.match(/(\d+)/)[0] + '&nb&';
      }
    });
    return code;
  }

  useEffect(() => {
    const updateInterviewEnd = async () => {
      let sess = await Axios.post(Config.API_BASE_URL + 'updateDocAssembleUserData/' + sessiondata.session, {
        fileNumber: messages.link == '' ? 0 : getLinks(messages.link),
      });
    }
    if (messages.link != null) {
      updateInterviewEnd();
    }
  }, [messages.link, auth, titleVal]);

  useEffect(() => {
    const am_pm_to_hours = (time) => {
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = (time.match(/\s(.*)$/)[1]).toString().toLowerCase().split(':')[0];
      if ((AMPM == "pm" || AMPM == "p.m." || AMPM == " pm" || AMPM == " p.m.") && hours < 12) hours = hours + 12;
      if ((AMPM == "am" || AMPM == "a.m." || AMPM == " am" || AMPM == " a.m.") && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      // console.log(sHours +':'+sMinutes);
      return (sHours + ':' + sMinutes);
    }

    const updateInterviewData = async (botMsg, variable) => {
      let vari = variable;
      if (botMsg.questionRange.hint && (botMsg.questionRange.hint == 'longtext' || botMsg.questionRange.hint.includes('textlength|'))) {
        vari['questionText'] = vari['questionText'] + '&nbsp;'
      }
      let sess = await Axios.post(Config.API_BASE_URL + 'updateDocAssembleUserData/' + sessiondata.session, {
        questionName: botMsg.questionName,
        variable: vari,
      });
    }

    const saveInterviewData = async () => {
      let sess = await Axios.post(Config.API_BASE_URL + 'saveDocAssembleUserData/', {
        userId: userCognitoGroups != undefined && userCognitoGroups.indexOf('Superadmin') != -1 && localStorage.getItem("userId")? localStorage.getItem("userId") : auth['user']['username'],
        title: (titleVal == null) ? '' : titleVal,
        session: sessiondata.session,
        secret: sessiondata.secret,
        i: sessiondata.i,
        total_steps: total_steps,
        host: Config.appHost
      });
      setcurrentInterview({ ...currentInterview, id: sess.data.lastInsertedRecord[0]._id });
      await Axios.post(Config.API_BASE_URL + 'savelog/', {
        userId: userCognitoGroups != undefined && userCognitoGroups.indexOf('Superadmin') != -1 && localStorage.getItem("userId")? localStorage.getItem("userId") : auth['user']['username'],
        title: (titleVal == null) ? '' : titleVal,
        fileName: sessiondata.i,
        host: Config.appHost
      });
    }

    const checkFileNumber = (result) => {
      if (result && result.fileNumber) {
        let file = result.fileNumber.split("&nb&");
        if (file[0] && file[0] > 0) {
          return true;
        }
      }
      return false;
    }

    const getInterviewData = async (currentInterviewId) => {
      let sess = await Axios.post(Config.API_BASE_URL + 'getDocAssembleUserData/' + currentInterviewId, { userId: '', host: Config.appHost });
      // console.log(sess.data.DocAssembleData[0].fileNumber);

      if (sess.data.DocAssembleData[0].fileNumber == undefined) {
        await Axios.post(Config.API_BASE_URL + 'updateDocAssembleUserData/' + sessiondata.session, {
          fileNumber: -1,
        });
      }
      // let fileRecords:any = sess.data.DocAssembleData[0].questionAns.filter((ques) => { 
      //     return ques.variable.questionType == "file" && ques.variable[Object.keys(ques.variable)[0]] == "Yes";
      // });
      // console.log(fileRecords.length);

      if (checkFileNumber(sess.data.DocAssembleData[0])) {
        const message: IMessage = {
          raw: '',
          questionName: '',
          message: `<a class="btn btn-primary text-white" style="text-transform:capitalize !important" href="/editinterview?v=${currentInterviewId}">${translations.review_documents}</a>`,
          sentBy: 'BOT',
          createdAt: new Date(),
          variable: '',
          questionType: 'deadend',
          questionChoices: '',
          questionRange: '',
          bubbles: [],
          bubbleIndex: 0
        };
        Axios.post(Config.API_BASE_URL + 'marklogascomplete/', {
          userId: (userCognitoGroups != undefined && userCognitoGroups.indexOf('Superadmin') != -1 && localStorage.getItem("userId"))? localStorage.getItem("userId") : auth['user']['username'],
          fileName: sessiondata.i
        });
        const data = [...messages.data, message];
        setMessages({ ...messages, data });
      }
      localStorage.setItem('lastloader', 'false');
    }


    if (messages.rawUserResponse == "deadend") {
      setMessages({ ...messages, validation: null });
      // console.log("deadend");
      // console.log(currentInterview.id);

      if (currentInterview.id) {
        getInterviewData(currentInterview.id);
      }
    }
    else if (messages.rawUserResponse || messages.rawUserResponse == ' ') {
      /*console.log(messages.lastBotIndex);
      if(interviewId && messages.lastBotIndex < messages.data.length -1 ) {
        messages.lastBotIndex = messages.data.length - 1;
      }

      console.log(messages.lastBotIndex);*/
      var botMsg = messages.data[messages.lastBotIndex];
      let valid: any = false;
      setMessages({ ...messages, validation: null });
      let correctedMsg: any = messages.rawUserResponse;
      if (messages.rawUserResponse == 'skipbtn') {
        correctedMsg = '';
        messages.rawUserResponse = '';
        valid = true;
      } else if (((botMsg.questionType == "text" && (botMsg.questionChoices == undefined || botMsg.questionChoices == '')) || botMsg.questionType == "area")) {
        // correctedMsg = correctedMsg.toString();
        if (((botMsg.questionRange.minlength != undefined && correctedMsg.length >= botMsg.questionRange.minlength) || (botMsg.questionRange.minlength == undefined)) && ((botMsg.questionRange.maxlength != undefined && correctedMsg.length <= botMsg.questionRange.maxlength) || (botMsg.questionRange.maxlength == undefined))) {

          valid = true;

        } else {
          let validation: any = "<span style='color:red'>" + translations.Please_enter_text_valid_length + (botMsg.questionRange.minlength != undefined ? "<br/> " + translations.Atleast + botMsg.questionRange.minlength + translations.chars : "") + (botMsg.questionRange.maxlength != undefined ? "<br/> " + translations.Atmost + botMsg.questionRange.maxlength + translations.chars : "") + "</span><br/><br/>" + translations.your_current_response + ": " + correctedMsg + "<br/>" + translations.not_able_to_understand;

          setMessages({ ...messages, validation });

          // alert(validation);
        }
      } else if (botMsg.questionType == "password") {
        correctedMsg = correctedMsg.replace(/ /g, "");
        if (((botMsg.questionRange.minlength != undefined && correctedMsg.length >= botMsg.questionRange.minlength) || (botMsg.questionRange.minlength == undefined)) && ((botMsg.questionRange.maxlength != undefined && correctedMsg.length <= botMsg.questionRange.maxlength) || (botMsg.questionRange.maxlength == undefined))) {
          valid = true;
        } else {
          let validation: any = "<span style='color:red'>" + translations.Please_enter_text_valid_length + (botMsg.questionRange.minlength != undefined ? "<br/> " + translations.Atleast + botMsg.questionRange.minlength + translations.chars : "") + (botMsg.questionRange.maxlength != undefined ? "<br/> " + translations.Atmost + botMsg.questionRange.maxlength + translations.chars : "") + "</span><br/><br/> " + translations.your_current_response + ": " + correctedMsg + "<br/>" + translations.not_able_to_understand;

          setMessages({ ...messages, validation });

          // alert(validation);
        }
      } else if (botMsg.questionType == "boolean") {
        let userMsgArr = correctedMsg.toLowerCase().split(/\s+|\./); // split words based on whitespace or a '.'
        if (messages.data[messages.data.length - 1].raw && messages.data[messages.data.length - 1].raw.fields && messages.data[messages.data.length - 1].raw.fields[0] && messages.data[messages.data.length - 1].raw.fields[0].hint && messages.data[messages.data.length - 1].raw.fields[0].hint == 'onlyyes') {
          if (correctedMsg.toLowerCase() == "y" || correctedMsg.toLowerCase() == "i don't know" || correctedMsg.toLowerCase() == "don't know" || correctedMsg.toLowerCase() == "do not know" || correctedMsg.toLowerCase() == "i do not know" || userMsgArr.indexOf('okay') >= 0 || userMsgArr.indexOf('sure') >= 0 || userMsgArr.indexOf('sí') >= 0 || userMsgArr.indexOf('yes') >= 0 || userMsgArr.indexOf('yet') >= 0 || userMsgArr.indexOf('ok') >= 0 || userMsgArr.indexOf('yep') >= 0 || userMsgArr.indexOf('yeah') >= 0 || userMsgArr.indexOf('alright') >= 0 || userMsgArr.indexOf('continue') >= 0 || userMsgArr.indexOf('confirm') >= 0) {
            correctedMsg = true;
            valid = true;
          } else {
            setMessages({ ...messages, ynquestion: 'onlyyes' });
          }
        } else {
          if (correctedMsg.toLowerCase() == "y" || correctedMsg.toLowerCase() == "i don't know" || correctedMsg.toLowerCase() == "don't know" || correctedMsg.toLowerCase() == "do not know" || correctedMsg.toLowerCase() == "i do not know" || userMsgArr.indexOf('okay') >= 0 || userMsgArr.indexOf('sure') >= 0 || userMsgArr.indexOf('yes') >= 0 || userMsgArr.indexOf('sí') >= 0 || userMsgArr.indexOf('yet') >= 0 || userMsgArr.indexOf('ok') >= 0 || userMsgArr.indexOf('yep') >= 0 || userMsgArr.indexOf('yeah') >= 0 || userMsgArr.indexOf('alright') >= 0 || userMsgArr.indexOf('continue') >= 0 || userMsgArr.indexOf('confirm') >= 0) {
            correctedMsg = true;
            valid = true;
          } else if (correctedMsg.toLowerCase() == "n" || correctedMsg.toLowerCase() == "no" || correctedMsg.toLowerCase() == "nope" || correctedMsg.toLowerCase() == "not" || correctedMsg.toLowerCase() == "not yet") {
            correctedMsg = false;
            valid = true;
          } else {
            setMessages({ ...messages, ynquestion: 'notget' });
          }
        }
      } else if (botMsg.questionType == "integer") {
        correctedMsg = correctedMsg.toString().replace(/ /g, "");
        correctedMsg = correctedMsg.toString().replace('one', "1");
        correctedMsg = correctedMsg.toString().replace('two', "2");
        correctedMsg = correctedMsg.toString().replace('three', "3");
        correctedMsg = correctedMsg.toString().replace('four', "4");
        correctedMsg = correctedMsg.toString().replace('five', "5");
        correctedMsg = correctedMsg.toString().replace('six', "6");
        correctedMsg = correctedMsg.toString().replace('seven', "7");
        correctedMsg = correctedMsg.toString().replace('eight', "8");
        correctedMsg = correctedMsg.toString().replace('nine', "9");
        if (correctedMsg == 'on') {
          correctedMsg = '1';
        }
        let final = correctedMsg.toString().replace(/\-/g, "");
        correctedMsg = correctedMsg.toString().replace('.', "");
        let test12 = Number(correctedMsg.toString().replace(/\-/g, ""));
        messages.rawUserResponse = parseFloat(final);
        // console.log(correctedMsg); 
        if (!isNaN(test12) && test12 % 1 == 0 && test12 >= 0 && ((botMsg.questionRange.min != undefined && parseFloat(final) >= botMsg.questionRange.min) || (botMsg.questionRange.min == undefined)) && ((botMsg.questionRange.max != undefined && parseFloat(final) <= botMsg.questionRange.max) || (botMsg.questionRange.max == undefined)) && ((botMsg.questionRange.default != undefined && botMsg.questionRange.hint != undefined && ((botMsg.questionRange.hint.toLowerCase() == 'maxlength' && test12.toString().length <= (botMsg.questionRange.default)) || (botMsg.questionRange.hint.toLowerCase() == 'minlength' && test12.toString().length >= (botMsg.questionRange.default)) || ((botMsg.questionRange.hint.toLowerCase() == 'bothlength' || botMsg.questionRange.hint.toLowerCase() == 'ssn') && test12.toString().length == (botMsg.questionRange.default)))) || (botMsg.questionRange.default == undefined) || (botMsg.questionRange.hint == undefined))) {
          valid = true;

          correctedMsg = parseFloat(final);

        } else {
          let validation: any = "<span style='color:red'>" + translations.zero_to_nine_digits + (botMsg.questionRange.min != undefined ? "<br/>" + translations.greater_the_equal + botMsg.questionRange.min : "") + (botMsg.questionRange.max != undefined ? "<br/> " + translations.less_then_equal + botMsg.questionRange.max : "") + (botMsg.questionRange.default != undefined && botMsg.questionRange.hint != undefined && botMsg.questionRange.hint.toLowerCase() == 'maxlength' && correctedMsg.toString().length > (botMsg.questionRange.default) ? "<br/> " + translations.Atmost + (botMsg.questionRange.default) + " " + translations.digits : "") + (botMsg.questionRange.default != undefined && botMsg.questionRange.hint != undefined && botMsg.questionRange.hint.toLowerCase() == 'minlength' && correctedMsg.toString().length < (botMsg.questionRange.default) ? "<br/> " + translations.Atleast + (botMsg.questionRange.default) + " " + translations.digits : "") + (botMsg.questionRange.default != undefined && botMsg.questionRange.hint != undefined && (botMsg.questionRange.hint.toLowerCase() == 'bothlength' || botMsg.questionRange.hint.toLowerCase() == 'ssn') && correctedMsg.toString().length != (botMsg.questionRange.default) ? "<br/> " + translations.exactly + (botMsg.questionRange.default) + translations.digits : "") + "</span><br/><br/> " + translations.your_current_response + ": " + correctedMsg + "<br/>" + translations.not_able_to_understand;

          setMessages({ ...messages, validation });

          // alert(validation);
        }
      } else if (botMsg.questionType == "currency") {
        let tempCurrency = correctedMsg.replace('$', '');
        correctedMsg = correctedMsg.replace(/ /g, "");
        correctedMsg = correctedMsg.replace('$', '');
        correctedMsg = correctedMsg.replace(/,/g, '');
        correctedMsg = Number(correctedMsg);
        messages.rawUserResponse = tempCurrency;
        // console.log(correctedMsg);

        if (!isNaN(correctedMsg) && correctedMsg >= 0 && ((botMsg.questionRange.min != undefined && correctedMsg >= botMsg.questionRange.min) || (botMsg.questionRange.min == undefined)) && ((botMsg.questionRange.max != undefined && correctedMsg <= botMsg.questionRange.max) || (botMsg.questionRange.max == undefined))) {
          correctedMsg = tempCurrency;
          valid = true;
        } else {
          let validation: any = "<span style='color:red'>" + translations.enter_currency_value + (botMsg.questionRange.min != undefined ? "<br/> " + translations.greater_the_equal + botMsg.questionRange.min : "") + (botMsg.questionRange.max != undefined ? "<br/> " + translations.less_then_equal + botMsg.questionRange.max : "") + "</span><br/><br/>" + translations.not_able_to_understand;

          setMessages({ ...messages, validation });
          // alert(validation);
        }
      } else if (botMsg.questionType == "number") {
        correctedMsg = Number(correctedMsg.replace(/ /g, ""));
        messages.rawUserResponse = correctedMsg;
        // console.log(correctedMsg);

        if (!isNaN(correctedMsg) && ((botMsg.questionRange.min != undefined && correctedMsg >= botMsg.questionRange.min) || (botMsg.questionRange.min == undefined)) && ((botMsg.questionRange.max != undefined && correctedMsg <= botMsg.questionRange.max) || (botMsg.questionRange.max == undefined))) {
          valid = true;
        } else {
          let validation: any = "<span style='color:red'>" + translations.enter_num_value + (botMsg.questionRange.min != undefined ? "<br/> " + translations.greater_the_equal + botMsg.questionRange.min : "") + (botMsg.questionRange.max != undefined ? "<br/> " + translations.less_then_equal + botMsg.questionRange.max : "") + "</span><br/><br/>  " + translations.your_current_response + ": " + correctedMsg + "<br/>" + translations.not_able_to_understand;

          setMessages({ ...messages, validation });
          // alert(validation);
        }
      } else if (botMsg.questionType == "date") {
        // console.log(correctedMsg);
        let currentRes = correctedMsg;
        let invalidDate: any;
        correctedMsg = correctedMsg.replace("-", ",").replace("-", ",");
        let dateArray: any = correctedMsg.replace(/, /g, ",").replace(/,/g, " ").replace(/\//g, " ").split(" ");
        let year: any = dateArray[2];
        let month: any = dateArray[0];
        let day: any = dateArray[1];

        let monthnumnames: any = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        let monthnumnamesfull: any = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

        let fullMonthNames: any = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

        let shortMonthNames: any = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

        let days = [{ day: 'one', day1: '1st', name: 1 }, { day: 'two', day1: '2nd', name: 2 }, { day: 'three', day1: '3rd', name: 3 }, { day: 'four', day1: '4th', name: 4 }, { day: 'five', day1: '5th', name: 5 }, { day: 'six', day1: '6th', name: 6 }, { day: 'seven', day1: '7th', name: 7 }, { day: 'eight', day1: '8th', name: 8 }, { day: 'nine', day1: '9th', name: 9 }, { day: 'ten', day1: '10th', name: 10 }, { day: 'eleven', day1: '11th', name: 11 }, { day: 'twelve', day1: '12th', name: 12 }, { day: 'thirteen', day1: '13th', name: 13 }, { day: 'fourteen', day1: '14th', name: 14 }, { day: 'fifteen', day1: '15th', name: 15 }, { day: 'sixteen', day1: '16th', name: 16 }, { day: 'seventeen', day1: '17th', name: 17 }, { day: 'eighteen', day1: '18th', name: 18 }, { day: 'nineteen', day1: '19th', name: 19 }, { day: 'twenty', day1: '20th', name: 20 }, { day: 'twenty first', day1: '21st', name: 21 }, { day: 'twenty second', day1: '22nd', name: 22 }, { day: 'twenty third', day1: '23rd', name: 23 }, { day: 'twenty fourth', day1: '24th', name: 24 }, { day: 'twenty fifth', day1: '25th', name: 25 }, { day: 'twenty sixth', day1: '26th', name: 26 }, { day: 'twenty seventh', day1: '27th', name: 27 }, { day: 'twenty eigth', day1: '28th', name: 28 }, { day: 'twenty ninth', day1: '29th', name: 29 }, { day: 'thirty', day1: '30th', name: 30 }, { day: 'thirty first', day1: '31st', name: 31 }];

        let dayFound = days.filter(ele => (ele.day == day || ele.day1 == day));
        if (dayFound.length > 0) {
          let key = 'day';
          if (day == dayFound[0].day1) {
            key = 'day1';
          }
          day = dayFound[0].name;
          correctedMsg = correctedMsg.replace(dayFound[0][key], dayFound[0].name);
        }

        if (month != undefined && isNaN(Number(month)) && fullMonthNames.indexOf(month.toLowerCase()) > -1) {
          month = fullMonthNames.indexOf(month.toLowerCase()) + 1;
        } else if (month != undefined && isNaN(Number(month)) && shortMonthNames.indexOf(month.toLowerCase()) > -1) {
          month = shortMonthNames.indexOf(month.toLowerCase()) + 1;
        } else if (month != undefined && isNaN(Number(month)) && monthnumnames.indexOf(month) > -1) {
          month = monthnumnames.indexOf(month) + 1;
        } else if (month != undefined && isNaN(Number(month)) && monthnumnamesfull.indexOf(month) > -1) {
          month = monthnumnamesfull.indexOf(month) + 1;
        }
        // console.log(year, month, day);

        if (year == undefined || month == undefined || day == undefined || isNaN(Number(year)) || isNaN(Number(month)) || isNaN(Number(day))) {
          invalidDate = true;
        } else {
          if (month < 1 || month > 12) {
            invalidDate = true;
          } else if (day < 1 || day > 31) {
            invalidDate = true;
          } else if ((month == 4 || month == 6 || month == 9 || month == 11) && day == 31) {
            invalidDate = true;
          } else if (month == 2) {
            let isLeapYear: any = (year % 4 == 0 && (year % 100 != 0 || year % 400 == 0));
            if (day > 29 || (day == 29 && !isLeapYear)) {
              invalidDate = true;
            }
          } else {
            invalidDate = false;
          }
        }

        if (!invalidDate) {
          correctedMsg = new Date(correctedMsg);
          // console.log(correctedMsg);

          if (correctedMsg != "Invalid Date" && ((botMsg.questionRange.default != undefined && botMsg.questionRange.hint != undefined && ((botMsg.questionRange.hint.toLowerCase() == 'after' && correctedMsg >= new Date(botMsg.questionRange.default)) || (botMsg.questionRange.hint.toLowerCase() == 'before' && correctedMsg <= new Date(botMsg.questionRange.default)))) || (botMsg.questionRange.default == undefined) || (botMsg.questionRange.hint == undefined))) {
            valid = true;
            correctedMsg = year + '-' + month + '-' + day;
            messages.rawUserResponse = month + '-' + day + '-' + year;
            // console.log(correctedMsg);
          } else {
            let defaultDate: any = fullMonthNames[new Date(botMsg.questionRange.default).getMonth()] + " " + new Date(botMsg.questionRange.default).getDate() + " " + new Date(botMsg.questionRange.default).getFullYear();

            let validation: any = "<span style='color:red'> " + translations.correct_date_format + (botMsg.variable).replace(/_/g, " ") + "." + (botMsg.questionRange.default != undefined && botMsg.questionRange.hint != undefined && botMsg.questionRange.hint.toLowerCase() == 'after' && correctedMsg < new Date(botMsg.questionRange.default) ? "<br/> " + translations.Should_be_after + defaultDate : "") + (botMsg.questionRange.default != undefined && botMsg.questionRange.hint != undefined && botMsg.questionRange.hint.toLowerCase() == 'before' && correctedMsg > new Date(botMsg.questionRange.default) ? "<br/> " + translations.Should_be_before + defaultDate : "") + "</span><br/><br/>  " + translations.your_current_response + ": " + currentRes + "<br/>" + translations.not_able_to_understand;

            setMessages({ ...messages, validation });
            // alert(validation);
          }
        } else if (botMsg.questionType == 'date') {
          let validation: any = "<span style='color:red'>" + translations.date_guidence + "</span><br/><br/>  " + translations.your_current_response + ": " + currentRes + "<br/>" + translations.not_able_to_understand;
          setMessages({ ...messages, validation });
          // alert(validation);
        }
      } else if (botMsg.questionType == "email") {
        correctedMsg = correctedMsg.replace(/ at /g, "@");
        // console.log(correctedMsg);

        let email_service_providers = ['outlook.com', 'yahoo.com', 'msn.com', 'rediff.com'];

        for (var i = 0; i < email_service_providers.length; i++) {
          let findIndex = correctedMsg.indexOf(email_service_providers[i]);

          if (findIndex > -1) {
            let is_at_exists = correctedMsg.substr(findIndex - 2, 2);
            if (is_at_exists == "at") {
              correctedMsg = correctedMsg.replace(is_at_exists, "@");
              // console.log(correctedMsg);
            }
          }
        }

        correctedMsg = correctedMsg.replace(/ /g, "");
        messages.rawUserResponse = correctedMsg;
        // console.log(correctedMsg);
        if (correctedMsg.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
          valid = true;
        } else {
          let validation: any = "<span style='color:red'>" + translations.valid_email + "</span><br/><br/> " + translations.your_current_response + ": " + correctedMsg + "<br/>" + translations.not_able_to_understand;
          setMessages({ ...messages, validation });
          // alert(validation);
        }
      } else if (botMsg.questionType == "range") {
        correctedMsg = Number(correctedMsg.replace(/ /g, ""));
        messages.rawUserResponse = correctedMsg;
        if (!isNaN(correctedMsg) && correctedMsg >= botMsg.questionRange.min && correctedMsg <= botMsg.questionRange.max && (correctedMsg % Number(botMsg.questionRange.step) == 0)) {
          valid = true;
        } else {
          let validation: any = "<span style='color:red'>" + translations.valid_range_of_numbers + botMsg.questionRange.min + " - " + botMsg.questionRange.max + translations.with_step_of + botMsg.questionRange.step + ".</span><br/><br/>  " + translations.your_current_response + ": " + correctedMsg + "<br/>" + translations.not_able_to_understand;
          setMessages({ ...messages, validation });
          // alert(validation);
        }
      } else if (botMsg.questionType == "text" && botMsg.questionChoices != undefined && botMsg.questionChoices != '') {
        if (botMsg.questionRange.hint != undefined && botMsg.questionRange.hint.toLowerCase() == "convert mail to male" && correctedMsg.toLowerCase() == "mail") {
          correctedMsg = "male";
          messages.rawUserResponse = correctedMsg;
        }

        let matchedChoice: any = botMsg.questionChoices.filter(function (choice) {
          return choice.value.toLowerCase() == correctedMsg.toLowerCase();
        });
        // console.log(correctedMsg.toLowerCase());

        if (matchedChoice.length) {
          correctedMsg = matchedChoice[0].value;
          messages.rawUserResponse = correctedMsg;
          valid = true;
        } else {
          let validation: any = "<span style='color:red'>" + translations.enter_valid_choice + " <br/>" + translations.Valid_Choices_are + ": <br/>" + botMsg.questionChoices.map(function (choice) { return choice.value; }).join("<br/>") + "</span><br/><br/> " + translations.your_current_response + ": " + correctedMsg + "<br/>" + translations.not_able_to_understand;
          setMessages({ ...messages, validation });
          // alert(validation);
        }
      } else if (botMsg.questionType == "time") {
        // console.log(correctedMsg);

        // add minutes to time like 7PM, 7p.m., 7 pm, 7 p.m. etc. 
        if (correctedMsg.toLowerCase().match(/^((1[0-2]|0?[1-9]) ?([APap].?[mM].?))$/)) {
          correctedMsg = correctedMsg.replace(/[AaPp]/, " $&");
          let timeArr = correctedMsg.split(' ');
          // console.log(timeArr);
          correctedMsg = timeArr[0] + ':00 ' + timeArr[timeArr.length - 1];
          // console.log(correctedMsg);
        }

        // match time must be in any of 12 hours time format pattern.
        if (correctedMsg.toLowerCase().match(/^(0?[1-9]|1[012])(:[0-5]\d) ?([APap].?[mM].?)$/)) {
          // add a space before start of am/pm
          correctedMsg = correctedMsg.replace(/[AaPp]/, " $&");
          // convert it to 24-hour format time
          // correctedMsg = am_pm_to_hours(correctedMsg);
          // console.log(correctedMsg);
          valid = true;
        } else {
          let validation: any = "<span style='color:red'>" + translations.enter_valid_time + "</span><br/><br/>  " + translations.your_current_response + ": " + correctedMsg + "<br/>" + translations.not_able_to_understand;
          setMessages({ ...messages, validation });
          // alert(validation);
        }
      } else if (botMsg.questionType == "file") {
        correctedMsg = "Yes";
        messages.rawUserResponse = "Yes";
        valid = true;
      }


      if (botMsg.questionRange.hint != undefined && botMsg.questionRange.hint == "leave blank") {
        // console.log(correctedMsg);
        if (correctedMsg.toLowerCase() == "leave blank" || correctedMsg.toLowerCase() == "not applicable" || correctedMsg.toLowerCase() == "none" || messages.rawUserResponse == '') {
          correctedMsg = '';
          messages.rawUserResponse = '';
        } else {
          if (!window.confirm("Is this your correct response?")) {
            valid = false;
            let validation: any = "Please enter the correct resposne";
            setMessages({ ...messages, validation });
          }

        }
      }

      // console.log(messages.validation);

      if (valid) {
        var variable = {
          [botMsg.variable]: correctedMsg,
          ['questionText']: botMsg.message,
          ['questionType']: botMsg.questionType,
          ['questionChoices']: botMsg.questionChoices,
          ['questionRange']: botMsg.questionRange
        };
        // console.log(variable);

        interviewId = null;

        if (messages.data.length == 1) {
          saveInterviewData();
        }

        updateInterviewData(botMsg, variable);

        const message: IMessage = {
          raw: messages.rawUserResponse,
          questionName: '',
          message: messages.rawUserResponse,
          sentBy: 'PERSON',
          createdAt: new Date(),
          variable: '',
          questionType: botMsg.questionType,
          questionChoices: '',
          questionRange: '',
          bubbles: [],
          bubbleIndex: -1
        };

        const data = [...messages.data, message];
        setMessageUpdated({
          ...msgUpdated,
          messageUpdated: false,
          bufferLen: 0
        });
        setMessages({ ...messages, rawUserResponse: null, data });
      } else {
        setMessageUpdated({
          ...msgUpdated,
          messageUpdated: true
        });
      }
    }
    else {

    }
  }, [messages.rawUserResponse]);

  const getAttachments = (attachments) => {
    let string = '';
    attachments.forEach(element => {
      string = string + Config.DA_BASE_URL + `${element.url.pdf}` + '&nb&';
    });
    return string;
  }

  useEffect(() => {
    const fetchNextQuestion = async () => {
      const raw = await da.getQuestion();
      const link = raw.attachments
        ? getAttachments(raw.attachments)
        : null;
      const progress = Number((raw.steps * 100.0) / Number(total_steps));

      var bubbles = ((raw.questionText).toString().split('<delay>'));
      if (raw.fields && raw.fields[0].inputtype && raw.fields[0].inputtype == 'radio') {
        localStorage.setItem('quesCompleted', 'false');
      }
      const message: IMessage = {
        raw,
        questionName: raw.questionName,
        message: raw.questionText,
        sentBy: 'BOT',
        createdAt: new Date(),
        variable: raw.fields && raw.fields[0].variable_name,
        questionType: raw.fields && raw.fields[0].datatype,
        questionChoices: raw.fields && raw.fields[0].choices,
        questionRange: { 'min': raw.fields && raw.fields[0].min, 'max': raw.fields && raw.fields[0].max, 'step': raw.fields && raw.fields[0].step ? raw.fields && raw.fields[0].step : "1", 'maxlength': raw.fields && raw.fields[0].maxlength, 'minlength': raw.fields && raw.fields[0].minlength, 'default': raw.fields && raw.fields[0].default, 'hint': raw.fields && raw.fields[0].hint },
        bubbles: bubbles,
        bubbleIndex: 0
      };
      if (messages.data.length == 0) {
        const rawUsers: any = await cognito.getUser({
          "AccessToken": (auth.user as any).signInUserSession.accessToken.jwtToken
        }).promise();

        let userD = rawUsers.UserAttributes.filter(ele => ele.Name == 'given_name');

        let userName = ((userCognitoGroups != undefined && userCognitoGroups.indexOf('Superadmin') != -1 && localStorage.getItem("userId")) ? JSON.parse(localStorage.getItem("userDetails")).FirstName : (userD[0] ? userD[0].Value : ''));
        
        message.message = `Hi ${userName}` + message.message;
      }
      const data = [...messages.data, message];
      setMessages({ ...messages, data, link, progress });
      localStorage.setItem('response', "false");
      localStorage.setItem('lastloader', 'false');
    };

    if (messages.fetchNextQuestion && localStorage.getItem('response') == "false" && (messages.data.length == 0 || (messages.data.length > 0 && messages.data[messages.data.length - 1].sentBy == 'PERSON' && messages.data[messages.data.length - 1].bubbleIndex == -1 && ((interviewId && messages.lastBotIndex + 1 == sessiondata.idata['questionAns'].length) || (!interviewId) || (interviewId && messages.lastBotIndex > sessiondata.idata['questionAns'].length))))) {
      fetchNextQuestion();
    }

  }, [messages.fetchNextQuestion, messages.data]);

  const resetSession = async () => {
    if (interviewId) {
      let sess = await Axios.post(Config.API_BASE_URL + 'getDocAssembleUserData/' + interviewId, { userId: '', host: Config.appHost });
      // console.log(sess.data.DocAssembleData);
      da.session = sess.data.DocAssembleData[0].session;
      da.secret = sess.data.DocAssembleData[0].secret;
      setsessiondata({ ...sessiondata, session: sess.data.DocAssembleData[0].session, secret: sess.data.DocAssembleData[0].secret, i: iVal, idata: sess.data.DocAssembleData[0] });
    } else {
      let s: any = await da.newSession();
      setsessiondata({ ...sessiondata, session: s.session, secret: s.secret, i: s.i });
    }

    // console.log(sessiondata);

    localStorage.setItem('muteBot', "false");
    localStorage.setItem('volumeRate', "4");
    localStorage.setItem('response', "false");

    (window.location.pathname == '/' && titleVal != null)
      ?
      await setMessages({
        data: [],
        lastBotIndex: null,
        rawUserResponse: null,
        fetchNextQuestion: false,
        link: null,
        progress: 0.0,
        title: titleVal,
        validation: null,
        botMute: null,
        resumeIndex: null,
        ynquestion: null
      })
      :
      await setMessages({
        data: [],
        lastBotIndex: null,
        rawUserResponse: null,
        fetchNextQuestion: false,
        link: null,
        progress: 0.0,
        title: titleVal,
        validation: null,
        botMute: null,
        resumeIndex: null,
        ynquestion: null
      })
  };

  const useOldSession = async () => {
  };

  React.useEffect(() => {
    resetSession();
  }, []);

  return render({ consec3, setCons, goIntoView, setMStart, messages, resetSession, useOldSession, setMessages, msgUpdated, setMessageUpdated, setLongMessages });
};
