import * as React from 'react';
import { Container } from '../Container';
import AWS from 'aws-sdk';
import Button from '@material-ui/core/Button';
import * as Config from '../../config';
import * as LangConfig from '../../languages/languages';
import { AuthContext } from '../../contexts/AuthContext';
import { DAClient } from '../../lib/DAClient';
import '../Chat/app.scss';
import './listaws.scss';
import Axios from 'axios';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import * as moment from 'moment';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { MessageContainer } from '../../Containers/MessageContainer';
import * as InterviewRef from '../../languages/langconfig';

const da = new DAClient();

export const Analytics: React.FC = () => {
	const auth: any = React.useContext(AuthContext);
	let userCognitoGroups = auth.accessToken.payload['cognito:groups'];
	const [loading, setLoading] = React.useState(true);
	const [translations, setTranslations] = React.useState(auth['user']['attributes'].locale ? LangConfig.languages[auth['user']['attributes'].locale]['translations'] : LangConfig.languages['en']['translations']);
	const [selectedLng, setselectedLng] = React.useState(auth['user']['attributes'].locale ? auth['user']['attributes'].locale : 'en');
	const [availInterviews, setAvailInterviews] = React.useState([]);
	const [allInterviews, setAllInterviews] = React.useState([]);
	const [AllQuestions, setAllQuestions] = React.useState([]);
	const [otherLangQuestions, setLangQuestions] = React.useState({});
	const [usefullinterview, setusefullinterview] = React.useState([]);
	const [active, setActive] = React.useState(0);
	const listAvailInterviews = async () => {
		let AvailableInterviews: any = await da.listInterviews();
		AvailableInterviews = AvailableInterviews.filter((e) => {
			return e.metadata['required privileges'].indexOf(Config.appHost) != -1;
		})
		// sort in descending order by interview title
		AvailableInterviews.sort((a, b) => (a.title > b.title) ? -1 : ((b.title > a.title) ? 1 : 0));
		let sess = await Axios.post(Config.API_BASE_URL + 'getAllDocAssembleUserData', {
			host: Config.appHost
		});
		sess.data.DocAssembleData.sort((a, b) => (a.title > b.title) ? -1 : ((b.title > a.title) ? 1 : 0));
		AvailableInterviews = AvailableInterviews.map(e => {
			if (InterviewRef.languagesconfigs[e.filename]) {
				let myArray = AvailableInterviews.filter(el => el.filename == InterviewRef.languagesconfigs[e.filename][selectedLng]);
				return myArray[0];
			} else {
				return e;
			}
		});

		let result = [];
		for (var i = 0; i <= AvailableInterviews.length - 1; i++) {
			let inde = result.findIndex(el => el.filename == AvailableInterviews[i].filename);
			if (inde > -1) {
			}
			else {
				result.push(AvailableInterviews[i]);
			}
		}
		AvailableInterviews = result;
		setAvailInterviews(AvailableInterviews);
		setAllInterviews(sess.data.DocAssembleData);
	}

	const exportData = async () => {
		let alls = AllQuestions.map((ques, qi) => {
			let obj = {};
			obj[translations.Questions] = ques;
			obj[translations.Responses] = getTotalResponses(ques, qi);
			obj[translations.big_yes] = answeredYes(ques, qi);
			obj['NO'] = answeredNo(ques, qi)
			return obj;
		})
		var d = new Date();
		var n = d.toString();
		let data: any = await Axios.post(Config.API_BASE_URL + 'export', {
			fields: [translations.Questions, translations.Responses, translations.big_yes, 'NO'],
			data: alls,
			name: availInterviews[active].title.replace(/ /g, "_") + n
		});
		setLoading(false);
		return window.open(Config.API_BASE_URL + 'download/' + data.data.filename);
	}

	React.useEffect(() => {
		if (allInterviews.length > 0 && availInterviews.length > 0) {
			interviewChanged(availInterviews[0]);
		}
	}, [allInterviews, availInterviews]);

	const interviewChanged = async (obj) => {
		setLoading(true);
		setAllQuestions([]);
		setLangQuestions({});
		try {
			let final = obj.filename.replace(`${obj.package}:`, '');
			let response = await Axios.get(Config.DA_API_BASE_URL + `/playground?folder=questions&filename=${final}`, { headers: { 'X-API-Key': Config.API_KEY } });
			let array = response.data.split("---");
			let allques: any = [];
			array.forEach(element => {
				element = element.replace(/\n/g, "");
				if (element.includes('yesno:') && element.includes("question: |") && !element.includes("sets:") && !element.includes("${")) {
					var start_pos = element.indexOf('|') + 1;
					var end_pos = element.indexOf('?', start_pos);
					var text_to_get = element.substring(start_pos, end_pos)
					if (!text_to_get.includes("question: |")) {
						text_to_get = text_to_get.trim();
						allques.push(text_to_get);
					}
				}
			});
			if (allInterviews.length > 0) {
				let used: any = allInterviews.filter(el => {
					if (InterviewRef.languagesconfigs[el.i]) {
						let files = InterviewRef.languagesconfigs[el.i];
						files = Object.keys(files).map((key) => files[key]);
						return files.indexOf(obj.filename) > -1 && el.questionAns;
					}
					return true
				}).map(el => el.questionAns);
				let fm: any = [].concat.apply([], used);
				fm = fm.filter(el => el.variable && el.variable.questionType && el.variable.questionType == "boolean");
				setusefullinterview(fm);
				setAllQuestions(allques);
			}
			if (InterviewRef.languagesconfigs[obj.filename]) {
				let langObj = InterviewRef.languagesconfigs[obj.filename];
				Object.keys(langObj).forEach(async function (key) {
					if (key !== selectedLng && langObj[key] !== obj.filename) {
						let response1 = await Axios.get(Config.DA_API_BASE_URL + `/playground?folder=questions&filename=${langObj[key].replace(`${obj.package}:`, '')}`, { headers: { 'X-API-Key': Config.API_KEY } });
						let array = response1.data.split("---");
						let allques1: any = [];
						array.forEach(element => {
							element = element.replace(/\n/g, "");
							if (element.includes('yesno:') && element.includes("question: |") && !element.includes("sets:") && !element.includes("${")) {
								var start_pos1 = element.indexOf('|') + 1;
								var end_pos1 = element.indexOf('?', start_pos1);
								var text_to_get1 = element.substring(start_pos1, end_pos1)
								if (!text_to_get1.includes("question: |")) {
									text_to_get1 = text_to_get1.trim();
									allques1.push(text_to_get1);
								}
							}
						});
						let finalObj = otherLangQuestions;
						finalObj[key] = allques1;
						setLangQuestions(finalObj);
					}
				});
			} else {
				let response1 = await Axios.get(Config.DA_API_BASE_URL + `/playground?folder=questions&filename=${obj.filename.replace(`${obj.package}:`, '')}`, { headers: { 'X-API-Key': Config.API_KEY } });
				let array = response1.data.split("---");
				let allques1: any = [];
				array.forEach(element => {
					element = element.replace(/\n/g, "");
					if (element.includes('yesno:') && element.includes("question: |") && !element.includes("sets:") && !element.includes("${")) {
						var start_pos1 = element.indexOf('|') + 1;
						var end_pos1 = element.indexOf('?', start_pos1);
						var text_to_get1 = element.substring(start_pos1, end_pos1)
						if (!text_to_get1.includes("question: |")) {
							text_to_get1 = text_to_get1.trim();
							allques1.push(text_to_get1);
						}
					}
				});
				let finalObj = otherLangQuestions;
				finalObj[selectedLng] = allques1;
				setLangQuestions(finalObj);
			}
			setTimeout(() => {
				setLoading(false);
			}, 4000);
		} catch (err) {
			setTimeout(() => {
				setLoading(false);
			}, 4000);
		}
	}

	const letChange = (obj) => {
		obj = JSON.parse(obj);
		interviewChanged(obj);
	}

	const createMarkup = (text, i) => {
		let q = text;
		if (text.includes('% if')) {
			q = text.split('% if')[0] + text.split('% if')[1].split(':')[1];
		}
		return { __html: q + '?' };
	}

	const getTotalResponses = (ques, index) => {
		let founded = usefullinterview.filter((e) => {
			let test = e.variable.questionText.replace(/\n/g, "");
			let string = test.split('?')[0];
			let otherlangStrings = Object.keys(otherLangQuestions).map((key) => {
				if (otherLangQuestions[key][index]) {
					return otherLangQuestions[key][index].replace(/ /g, "");
				}
				return '';
			});
			if (string.replace(/ /g, "") == ques.replace(/ /g, "") || otherlangStrings.indexOf(string.replace(/ /g, "")) > -1) {
				return true;
			}
			return false;
		})
		return founded.length;
	}

	const answeredYes = (ques, index) => {
		let yesAnswered = [];
		let booleanA = [];
		let founded = usefullinterview.filter(e => {
			let test = e.variable.questionText.replace(/\n/g, "");
			let string = test.split('?')[0];
			let otherlangStrings = Object.keys(otherLangQuestions).map((key) => {
				if (otherLangQuestions[key][index]) {
					return otherLangQuestions[key][index].replace(/ /g, "");
				}
				return '';
			});
			if ((string.replace(/ /g, "") == ques.replace(/ /g, "") || otherlangStrings.indexOf(string.replace(/ /g, "")) > -1) && e.variable.questionType == 'boolean') {
				Object.keys(e.variable).forEach(function (key) {
					if (key !== 'questionRange' && key !== 'questionText' && key !== 'questionType') {
						booleanA.push(true);
						if (e.variable[key] == true) {
							yesAnswered.push(true);
						}
					}
				});
				return true;
			}
			return false;
		})
		let percent: any = (yesAnswered.length * 100) / founded.length;
		if (booleanA.length > 0) {
			if (percent == NaN) {
				percent = 0;
			}
			return parseInt(percent) + '%';
		}
		return '-';
	}


	const answeredNo = (ques, index) => {
		let noAnswered = [];
		let booleanA = [];
		let founded = usefullinterview.filter(e => {
			let test = e.variable.questionText.replace(/\n/g, "");
			let string = test.split('?')[0];
			let otherlangStrings = Object.keys(otherLangQuestions).map((key) => {
				if (otherLangQuestions[key][index]) {
					return otherLangQuestions[key][index].replace(/ /g, "");
				}
				return '';
			});
			if ((string.replace(/ /g, "") == ques.replace(/ /g, "") || otherlangStrings.indexOf(string.replace(/ /g, "")) > -1) && e.variable.questionType == 'boolean') {
				Object.keys(e.variable).forEach(function (key) {
					if (key !== 'questionRange' && key !== 'questionText' && key !== 'questionType') {
						booleanA.push(true);
						if (e.variable[key] == false) {
							noAnswered.push(true);
						}
					}
				});
				return true;
			}
			return false;
		})
		let percent: any = (noAnswered.length * 100) / founded.length;
		if (booleanA.length > 0) {
			if (percent == NaN) {
				percent = 0;
			}
			return parseInt(percent) + '%';
		}
		return '-';
	}

	const nextInterview = () => {
		let current = active;
		setActive(current + 1);
		interviewChanged(availInterviews[current + 1]);
	}

	const backInterview = () => {
		let current = active;
		setActive(current - 1);
		interviewChanged(availInterviews[current - 1]);
	}

	React.useEffect(() => {
		listAvailInterviews();
	}, [auth]);

	return (
		<MessageContainer
			render={({ messages, setMessages, resetSession, useOldSession }) => (
				<>
					<div>
						{loading ? <div className="loader-cont"><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div> : null}
						<Header resetSession={resetSession} />
						<div className="app-main">
							<div className="app-main-inner">
								<Container
									style={{
										paddingTop: 30,
										paddingBottom: 30,
									}}
								>
									{/* <select onChange={(e) => letChange(e.target.value)} className="form-control">
										{availInterviews.map((interview, index) => {
											return (<option key={index} value={JSON.stringify(interview)}>{interview.title}</option>);
										})}
									</select> */}
									<div className="table-responsive analytics">
										{/*  <div className="tableBorder"></div> */}
										<table className="table table-bordered">
											<thead>
												<tr>
													<th colSpan={4} className="border-right-0 interviewHeading">
														<div className="row d-flex flex-wrap justify-content-center align-items-stretch py-4">
															<div className="col-md-6  col-xs-12 border-right-0 d-flex align-items-center"><h1 className="h2 mb-0">{translations.Interview_Analytics}</h1>&nbsp;&nbsp;&nbsp;<a href="javascript:void(0)" onClick={() => {
																setLoading(true);
																setTimeout(() => {
																	exportData();
																}, 3000);
															}} className="btn btn-outline1">{translations.Export_CSV}</a></div>
															<div className="col-md-6 col-xs-12 border-right-0 d-flex flex-wrap justify-content-end align-items-center mb_ana_head">
																{!loading ? <div className="d-inline mb_link">
																	{active !== 0 ? <svg onClick={() => backInterview()} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<rect x="0.5" y="0.5" width="20" height="20" rx="10" fill="white" stroke="#555555" />
																		<path fillRule="evenodd" clipRule="evenodd" d="M10.9917 6.19651C11.2537 5.9345 11.6785 5.9345 11.9405 6.19651C12.2025 6.45853 12.2025 6.88333 11.9405 7.14535L8.64333 10.4425L12.0554 13.8547C12.3175 14.1167 12.3175 14.5415 12.0554 14.8035C11.7934 15.0655 11.3686 15.0655 11.1066 14.8035L7.36866 11.0656C7.30652 11.0342 7.24824 10.9926 7.19634 10.9407C6.93433 10.6787 6.93433 10.2539 7.19634 9.99186L10.9917 6.19651Z" fill="#555555" />
																	</svg> : null}
																</div> : null}
																{!loading ? <div className="mb_half d-inline int_title">{availInterviews[active].title}</div> : null}
																{!loading ? <div className="d-inline mb_link">
																	{active < availInterviews.length - 1 ? <svg onClick={() => nextInterview()} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<rect x="0.5" y="0.5" width="20" height="20" rx="10" fill="white" stroke="#555555" />
																		<path fillRule="evenodd" clipRule="evenodd" d="M9.26026 6.19651C8.99825 5.9345 8.57344 5.9345 8.31142 6.19651C8.04941 6.45853 8.04941 6.88333 8.31142 7.14535L11.6086 10.4425L8.19651 13.8547C7.9345 14.1167 7.9345 14.5415 8.19651 14.8035C8.45853 15.0655 8.88333 15.0655 9.14535 14.8035L12.8833 11.0656C12.9454 11.0342 13.0037 10.9926 13.0556 10.9407C13.3176 10.6787 13.3176 10.2539 13.0556 9.99186L9.26026 6.19651Z" fill="#555555" />
																	</svg> : null}
																</div> : null}
															</div>
														</div>
													</th>
												</tr>
												<tr className="hide-mb">
													<th className="border-right-0" colSpan={4}>
														<div className="row d-flex flex-wrap justify-content-center align-items-stretch">
															<div className="col-6 d-flex flex-wrap align-items-center pl-60">{translations.Question_Analytics}</div>
															<div className="col-2 d-flex flex-wrap justify-content-center align-items-center text-center">{translations.Responses}</div>
															<div className="col-2 d-flex flex-wrap justify-content-center align-items-center text-center">{translations.Answered} <br /> <div className="success">{translations.big_yes}</div></div>
															<div className="col-2 d-flex flex-wrap justify-content-center align-items-center text-center border-right-0">{translations.Answered} <br /> <div className="danger">{translations.big_no}</div></div>
														</div>
													</th>
												</tr>
											</thead>
											<tbody>
												{AllQuestions.map((ques, qi) => {
													return (<tr>
														<td>
															<div className="row commonStyle_mb">
																<div className="col-md-1 commonStyle d-flex flex-wrap justify-content-end align-items-center border-right-0"><span className="counter">{qi + 1}</span></div>
																<div className="col-md-5 commonStyle d-flex  flex-wrap justify-content-start align-items-center" dangerouslySetInnerHTML={createMarkup(ques, qi)}></div>
																<div className="col-md-2 commonStyle d-flex  flex-wrap justify-content-center align-items-center text-center">
																	<div className="default">
																		<span className="mobile-view">{translations.Responses}</span>
																		<span>{getTotalResponses(ques, qi)}</span>
																	</div>
																</div>
																<div className="col-md-2 commonStyle d-flex  flex-wrap justify-content-center align-items-center text-center">
																	<div className="success">
																		<span className="mobile-view">{translations.big_yes}</span>
																		<span>{answeredYes(ques, qi)}</span>
																	</div>
																</div>
																<div className="col-md-2 commonStyle d-flex  flex-wrap justify-content-center align-items-center text-center"><div className="danger">
																	<span className="mobile-view">{translations.big_no}</span>
																	<span>{answeredNo(ques, qi)}</span>
																</div>
																</div>
															</div>

														</td>
													</tr>);
												})}
											</tbody>
										</table>
									</div>
								</Container>
							</div>
						</div>
						<Footer />
					</div>
				</>
			)}
		/>
	);
};