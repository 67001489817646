export const languagesconfigs = {
    "docassemble.playground1:Divorce-form-final.yml": {
        en: "docassemble.playground1:Divorce-form-final.yml",
        es: "docassemble.playground1:Divorce-form-final.yml"
    },
    "docassemble.playground1:Expedited-Hearing.yml": {
        en: "docassemble.playground1:Expedited-Hearing.yml",
        es: "docassemble.playground1:Expedited-Hearing-Spanish.yml"
    },
    "docassemble.playground1:Expedited-Hearing-Spanish.yml": {
        en: "docassemble.playground1:Expedited-Hearing.yml",
        es: "docassemble.playground1:Expedited-Hearing-Spanish.yml"
    },
    "docassemble.playground1:Fee-Waiver.yml": {
        en: "docassemble.playground1:Fee-Waiver.yml",
        es: "docassemble.playground1:Fee-Waiver.yml"
    },
    "docassemble.playground1:Permanent-Parenting.yml": {
        en: "docassemble.playground1:Permanent-Parenting.yml",
        es: "docassemble.playground1:Permanent-Parenting.yml"
    },
    "docassemble.playground1:sample-Fill-Docx.yml": {
        en: "docassemble.playground1:sample-Fill-Docx.yml",
        es: "docassemble.playground1:sample-Fill-Docx.yml"
    },
    "docassemble.playground1:sample-Fill-Pdf.yml": {
        en: "docassemble.playground1:sample-Fill-Pdf.yml",
        es: "docassemble.playground1:sample-Fill-Pdf.yml"
    },
    "docassemble.playground1:testing-date.yml": {
        en: "docassemble.playground1:testing-date.yml",
        es: "docassemble.playground1:testing-date.yml"
    },
    "docassemble.playground1:TN-workers-progress.yml": {
        en: "docassemble.playground1:TN-workers-progress.yml",
        es: "docassemble.playground1:TN-workers-spanish.yml"
    },
    "docassemble.playground1:TN-workers-spanish.yml": {
        en: "docassemble.playground1:TN-workers-progress.yml",
        es: "docassemble.playground1:TN-workers-spanish.yml"
    },
    "docassemble.playground1:causation-interview.yml": {
        en: "docassemble.playground1:causation-interview.yml",
        es: "docassemble.playground1:causation-interview.yml"
    }/*,
    "docassemble.playground1:Medical-Records-Certification.yml": {
        en: "docassemble.playground1:Medical-Records-Certification.yml",
        es: "docassemble.playground1:Medical-Records-Certification.yml"
    }*/
}