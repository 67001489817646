import * as React from 'react';
import './header.scss';
import { Button } from '../Button';
import AWS from 'aws-sdk';
import { Container } from '../Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import * as Config from '../../config';
import { AuthContext } from '../../contexts/AuthContext';
import NavItem from 'react-bootstrap/NavItem';
import * as LangConfig from '../../languages/languages';

interface IHeaderProps {
    resetSession: () => void;
}
export const Header: React.FC<IHeaderProps> = ({ resetSession }) => {
    const auth = React.useContext(AuthContext);
    const [loading, setLoading] = React.useState(false);
    const [translations, setTranslations] = React.useState(auth['user']['attributes'].locale ? LangConfig.languages[auth['user']['attributes'].locale]['translations'] : LangConfig.languages['en']['translations']);
    const [language, setLanguage] = React.useState(auth['user']['attributes'].locale ? auth['user']['attributes'].locale : 'en');
    let userCognitoGroups = auth.accessToken.payload['cognito:groups'];
    function handleClick(e) {
        e.preventDefault();
        var element = document.getElementById('toggler_custom');
        element.classList.add("show");
        var element = document.getElementById('overlay');
        element.classList.add("active");
    }
    function overlayClick(e) {
        e.preventDefault();
        document.getElementById("toggler_custom").click();
        var element = document.getElementById('overlay');
        element.classList.remove("active");
        // console.log('The link was clicked.');
    }

    const updateUser = async (lang) => {
        setLoading(true);
        let params = {
            "UserAttributes": [
                {
                    "Name": "locale",
                    "Value": lang
                }
            ],
            "Username": auth['user']['username'],
            "UserPoolId": Config.AWS_userPoolId
        }
        AWS.config.update(Config.AWSConfigExport);
        const cognito = new AWS.CognitoIdentityServiceProvider();
        await cognito.adminUpdateUserAttributes(params).promise();
        await auth.isAuthenticated();
        setLoading(false);
        window.location.reload();
    }

    let query_params = new URLSearchParams(window.location.search);
    let iVal = query_params.get('i');
    let titleVal = query_params.get('title');
    let total_steps = query_params.get('total_steps');
    let interviewId = query_params.get('interviewId');
    let windowHref = "/?i=" + iVal + "&title=" + titleVal + "&total_steps=" + total_steps;
    let adminPaths = ['/admindashboard', '/listusers', '/userinterviews', '/viewinterview', '/analytics'];
    if (userCognitoGroups != undefined && (userCognitoGroups.indexOf('admin') != -1 || userCognitoGroups.indexOf('Superadmin') != -1)) {
        if (userCognitoGroups.indexOf('admin') == -1 && userCognitoGroups.indexOf('Superadmin') == -1 && adminPaths.indexOf(window.location.pathname) == -1) {
            window.location.href = '/admindashboard';
        }
    } else {
        if (adminPaths.indexOf(window.location.pathname) != -1) {
            window.location.href = '/availableInterviews';
        }
    }
    return (
        <header className="header">
            <meta property="og:title" content="WC bot" />
            <title>WC bot</title>
            {loading ? <div className="loader-cont"><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div> : null}
            <Container>
                <div className="navigation">
                    <Navbar collapseOnSelect expand="lg" className="p-0 d-flex justify-content-end navbar-expand">
                        <Navbar.Brand className="">
                            
                        </Navbar.Brand>
                        <Navbar.Collapse id="responsive-navbar-nav" className="profile-links">
                            <Nav className="nav_outer">
                                <div className="row py-3 d-block text-right">
                                    <a className="nav-close" href="javascript:void(0)" onClick={overlayClick}> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="3.51465" y="0.686279" width="28" height="4" rx="2" transform="rotate(45 3.51465 0.686279)" fill="#333333" />
                                        <rect x="0.686523" y="20.4852" width="28" height="4" rx="2" transform="rotate(-45 0.686523 20.4852)" fill="#333333" />
                                    </svg></a>
                                </div>
                                <div className="row py-3 d-block">
                                    <div className="col-12 profile-data">
                                        <h5>{auth.user['attributes']['given_name']} {auth.user['attributes']['family_name']}</h5>
                                        <p>{auth.user['attributes']['email']}</p>
                                    </div>
                                </div>
                                {
                                    (userCognitoGroups != undefined && (userCognitoGroups.indexOf('admin') != -1 || userCognitoGroups.indexOf('Superadmin') != -1)) ?
                                        <Nav.Link href="/admindashboard" className={window.location.pathname == '/admindashboard' ? "active d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0" : "d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0"}>{translations.dashboard}</Nav.Link>
                                        : ''
                                }
                                {
                                    (userCognitoGroups != undefined && (userCognitoGroups.indexOf('admin') == -1 && userCognitoGroups.indexOf('Superadmin') != -1)) ?
                                        <Nav.Link href="/listusers" className={window.location.pathname == '/listusers' ? "active d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0" : "d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0"}>{translations.users}</Nav.Link>
                                        : ''
                                }
                                {
                                    <Nav.Link href="/availableInterviews" className={window.location.pathname == '/availableInterviews' ? "active d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0" : "d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0"}>{translations.interviews}</Nav.Link>
                                }
                                {/* {
                                    (userCognitoGroups != undefined && (userCognitoGroups.indexOf('admin') != -1 || userCognitoGroups.indexOf('Superadmin') != -1)) ?
                                        <Nav.Link href="/added-interviews" className={window.location.pathname == '/added-interviews' ? "active d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0" : "d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0"}>{translations.manage_interviews}</Nav.Link>
                                        : ''
                                }
                                {
                                    (userCognitoGroups != undefined && (userCognitoGroups.indexOf('admin') != -1 || userCognitoGroups.indexOf('Superadmin') != -1)) ?
                                        <Nav.Link href="/configurations" className={window.location.pathname == '/configurations' ? "active d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0" : "d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0"}>{translations.configurations}</Nav.Link>
                                        : ''
                                } */}
                                {/* {
                            (userCognitoGroups != undefined && userCognitoGroups.indexOf('admin') != -1 && userCognitoGroups.indexOf('Superadmin') == -1) ?
                            ''
                            : <Nav.Link href="/interviews" className={window.location.pathname=='/interviews' ?"active d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0":"d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0"}>Completed Interviews</Nav.Link>
                            } */}
                                {
                                    (window.location.pathname == '/') ?
                                        (interviewId ?
                                            (<Nav.Link href={windowHref} className={window.location.pathname == '/' ? "active d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0" : "d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0"}>{translations.restart_current_interview}</Nav.Link>)
                                            :
                                            (<Nav.Link href={windowHref} className={window.location.pathname == '/' ? "active d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0" : "d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0"}> {translations.restart_current_interview}</Nav.Link>)
                                        )
                                        : ('')
                                }
                                <Nav.Link href="/profile" className={window.location.pathname == '/profile' ? "active d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0" : "d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0"}>
                                    <div className="d-flex align-items-center w-100">
                                        {translations.profile}
                                    </div>
                                </Nav.Link>
                                <Nav.Link href="/terms" className={window.location.pathname == '/terms' ? "active d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0" : "d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0"}>
                                    <div className="d-flex align-items-center w-100">
                                        {translations.Terms_Conditions}
                                    </div>
                                </Nav.Link>
                                <Nav.Link href="/privacy" className={window.location.pathname == '/privacy' ? "active d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0" : "d-flex align-items-center px-3 mr-lg-2 mr-sm-0 mr-0"}>
                                    <div className="d-flex align-items-center w-100">
                                        {translations.privacy_policy}
                                    </div>
                                </Nav.Link>
                                <Nav.Link onClick={async () => {
                                    await auth.logout();
                                    localStorage.setItem('iview', 'false');
                                    window.location.href = "/";
                                }}
                                    className="px-3 d-flex align-items-center">{translations.logout}</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                        <div className="navigationStyle">
                            {window.location.pathname !== '/' ? <select defaultValue={language} style={{ padding: '10px' }} className="select_lang" onChange={(e) => updateUser(e.target.value)}>
                                <option value="en">English</option>
                                <option value="es">Spanish</option>
                            </select> : null}
                            {
                                (userCognitoGroups != undefined && (userCognitoGroups.indexOf('admin') != -1 || userCognitoGroups.indexOf('Superadmin') != -1)) ?
                                    <Nav.Link href="/admindashboard" className={window.location.pathname == '/admindashboard' ? "current_nav mr-lg-2 mr-sm-0 mr-0 desktop nav-link" : "mr-lg-2 mr-sm-0 mr-0 desktop nav-link"}>{translations.dashboard}</Nav.Link>
                                    : ''
                            }
                            {
                                (userCognitoGroups != undefined && (userCognitoGroups.indexOf('admin') == -1 && userCognitoGroups.indexOf('Superadmin') != -1)) ?
                                    <Nav.Link href="/listusers" className={window.location.pathname == '/listusers' ? "current_nav mr-lg-2 mr-sm-0 mr-0 desktop nav-link" : "mr-lg-2 mr-sm-0 mr-0 desktop nav-link"}>{translations.users}</Nav.Link>
                                    : ''
                            }
                            {
                                <Nav.Link href="/availableInterviews" className={window.location.pathname == '/availableInterviews' ? "current_nav mr-lg-2 mr-sm-0 mr-0 desktop nav-link" : "mr-lg-2 mr-sm-0 mr-0 desktop nav-link"}>{translations.interviews}</Nav.Link>
                            }
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="p-0 border-0 font-weight-bold" id="toggler_custom" onClick={handleClick} />
                        </div>
                    </Navbar>
                    <div className="overlay" id="overlay" onClick={overlayClick}></div>
                </div>
            </Container>
        </header>
    );
};