import * as React from 'react';
import '../Chat/app.scss';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import { MessageContainer } from '../../Containers/MessageContainer';
import { AuthContext } from '../../contexts/AuthContext';
import Axios from 'axios';
import { DAClient } from '../../lib/DAClient';
import * as Config from '../../config';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SimpleSnackbar from '../SimpleSnackbar';

const da = new DAClient();

export const AddedInterviews: React.FC = (props: any) => {
    const auth = React.useContext(AuthContext);
    let userCognitoGroups = auth.accessToken.payload['cognito:groups'];
    const [loading, setLoading] = React.useState(false);
    const [values, setValues] = React.useState<any>([]);
    const [files, setFiles] = React.useState<any>([]);
    const [configuration, setConfiguration] = React.useState<any>({});
    const [message, setMessage] = React.useState<any>([]);

    const listAvailInterviews = () => {
        return new Promise(async (resolve, reject) => {
            let res = await Axios.get(Config.API_BASE_URL + 'getcompletedinterview/' + auth['user']['username']);
            let configurations = await da.getConfigurations();
            let files = await da.listFiles('questions');
            setConfiguration(configurations);
            setFiles(files);
            setValues(res.data.data);
            return resolve(true);
        });
    }

    const deleteInterview = async (el) => {
        if(!window.confirm('Are you sure you want to delete interview Permanently ?')){
            return
        }
        setLoading(true);
        let testObj = configuration;
        let file = Object.keys(testObj.dispatch).filter(e => {
            let sample = testObj.dispatch[e].replace('docassemble.playground1:', '');
            if (sample == el['interview'][0]['metadata']['title'].replace(/\s+/g, '_') + '.yml') {
                return true
            }
            return false;
        });
        if (files.indexOf(el['interview'][0]['metadata']['title'].replace(/\s+/g, '_') + '.yml') > -1) {
            await da.deleteFile(el['interview'][0]['metadata']['title'].replace(/\s+/g, '_') + '.yml');
        }
        await Axios.get(Config.API_BASE_URL + 'deleteinterview/' + el._id);
        await listAvailInterviews();
        if (file.length > 0) {
            delete testObj.dispatch[file[0]];
            await da.editConfigurations(testObj);
            setLoading(false);
            let m = [];
            m.push('Interview Deleted.');
            setMessage(m);
            m = []
            m.push('System Restarted, usually takes 25 to 30 seconds. Please have patience.');
            setTimeout(() => {
                setMessage(m);
            }, 2000);
            return
        }
        setLoading(false);
        let m = [];
        m.push('Interview Deleted.');
        setMessage(m);
    }

    const loadData = async () => {
        setLoading(true);
        await listAvailInterviews();
        setLoading(false);
    }

    React.useEffect(() => {
        loadData();
    }, [auth]);


    return (
        <MessageContainer render={({ messages, setMessages, resetSession, useOldSession }) => (
            <>
                <div>
                    {loading ? <div className="loader-cont">
                        <div className="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div> : null}
                    <Header resetSession={resetSession} />
                    {message.map(
                        (err, i) => <SimpleSnackbar key={i} message={err} />,
                    )}
                    <div className="app-main">
                        <div className="app-main-inner">
                            <Container style={{ paddingTop: 30, paddingBottom: 30, }}>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-sm-10">
                                        <div className="addbtn">
                                            {(userCognitoGroups != undefined && (userCognitoGroups.indexOf('admin') != -1 || userCognitoGroups.indexOf('Superadmin') != -1)) ? <a title="Edit Interview" href="/addnewquestions" className="btn btn-outline">+ Add Interview</a> : null}
                                        </div>
                                        <div className="interviews-list addedint">
                                            <Tabs defaultActiveKey={props.location.state && props.location.state.fromEdit ? "Completed" : "Available"} id="interviews-list">
                                                <Tab eventKey="Available" title="Manage Interviews">
                                                    <ul>


                                                        {values.map(function (result, index) {
                                                            return (
                                                                <li>
                                                                    <div className="interviews-list-item d-flex align-items-center">
                                                                        <div className="d-flex flex-column flex-grow-1 ">
                                                                            <span className="question_title">{result.interview[0].metadata['title']}</span>
                                                                            <span className="question_short_title"> {result.interview[0].metadata['comment']}</span>


                                                                            <span className="interview-ico">
                                                                                <a title="Edit Interview" onClick={() => props.history.push(`interview/${result._id}`)}><i className="material-icons">edit</i></a>
                                                                                <a href="javascript:void(0)" onClick={() => deleteInterview(result)}><i className="material-icons">delete</i></a>

                                                                            </span>


                                                                        </div>
                                                                        {/* <span className="interviews-list">
                                                                    <a className="ply-btn" href="#"><i className="material-icons">play_arrow</i></a>
                                                                </span> */}
                                                                    </div>
                                                                </li>
                                                            );
                                                        }
                                                        )
                                                        }
                                                    </ul>
                                                    {values.length == 0 && !loading ? <div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-12 col-md-6">
                                                                <ul>
                                                                    <li>
                                                                        <div className="interviews-list-item d-flex align-items-center">
                                                                            <div className="d-flex flex-column flex-grow-1 ">
                                                                                <p className="interviews-list-empty">No interviews available, click Add interview to start.</p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div> : null}
                                                </Tab>

                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        )}
        />
    );
};