import en from './en.json';
import es from './es.json';

export const languages = {
    en: {
        translations: en,
        langCode: 'en-US'
    },
    es: {
        translations: es,
        langCode: 'es-ES'
    }
}