import * as React from 'react';
import '../Chat/app.scss';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import { MessageContainer } from '../../Containers/MessageContainer';
import { AuthContext } from '../../contexts/AuthContext';
import Axios from 'axios';
import { DAClient } from '../../lib/DAClient';
import * as Config from '../../config';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
const da = new DAClient();
interface IValueState {
    availInterviews: any[];
    myinterviews: any[];
}
export const AddNewInterview: React.FC = () => {
    const auth = React.useContext(AuthContext);
    const [active, setActive] = React.useState(2);
    const [startDate, setStartDate] = React.useState(new Date());
    const [tab, setTab] = React.useState(1);
    const [inId, setInId] = React.useState(null);
    const [error, setError] = React.useState('');
    const [otherValue, setOtherValue] = React.useState({});
    const [ymlObj, setYmlObj] = React.useState<any>([
        {
            'metadata': {
                'title': '',
                'short title': '',
                'required privileges': [
                    "dwc",
                    "tnwc",
                    "admin"
                ]
            }
        },
        {
            'mandatory': true,
            'code': ['name', 'voice_or_text']
        },
        {
            'question': '! Welcome, I’m WC Bot.\n',
            'fields': [
                {
                    'variable': 'name'
                }
            ]
        },
        {
            'question': 'How do you want to continue?\n',
            'fields': [
                {
                    'variable': 'voice_or_text',
                    'datatype': 'radio',
                    'choices': [
                        'Continue with voice',
                        'Continue with text'
                    ]
                }
            ]
        },
        {
            'question': '',
            'fields': [
                {
                    'variable': ''
                }
            ]
        }
    ]);
    const getPendingInterviews = async () => {
        let res = await Axios.get(Config.API_BASE_URL + 'getpendinginterview/' + auth['user']['username']);
        if (res.data.data.length > 0) {
            let interview = res.data.data[res.data.data.length - 1];
            setInId(interview._id);
            setYmlObj(interview.interview);
        }
    }
    const validate = () => {
        let obj = JSON.parse(JSON.stringify(ymlObj));
        if (!obj[0].metadata.title) {
            setError('Title should not be empty.');
            return false;
        }
        if (!obj[0].metadata['short title']) {
            setError('Description should not be empty.');
            return false;
        }
        if (!obj[ymlObj.length - 1].question) {
            setError('Question text should not be empty.');
            return false;
        }
        if (!obj[ymlObj.length - 1]['fields'][0]['variable']) {
            setError('Variable name should not be empty.');
            return false;
        }
        if (obj[ymlObj.length - 1]['fields'][0]['variable'] && obj[ymlObj.length - 1]['fields'][0]['variable'].indexOf(" ") > 0) {
            setError('Space is not allowed in variable name.');
            return false;
        }
        if (obj[ymlObj.length - 1]['fields'][0]['variable']) {
            let already = obj.filter((el, index) => {
                if (index > 1 && index !== (ymlObj.length - 1) && obj[ymlObj.length - 1]['fields'][0]['variable'] == el['fields'][0]['variable']) {
                    return true;
                }
                return false;
            });
            if (already.length > 0) {
                setError('Variable name is already used.');
                return false;
            }
        }
        if (obj[ymlObj.length - 1]['fields'][0]['datatype'] && obj[ymlObj.length - 1]['fields'][0]['datatype'] == 'radio') {
            let blankChoice = obj[ymlObj.length - 1]['fields'][0]['choices'].filter(e => e == '');
            if (blankChoice.length > 0) {
                setError('Choice fields should not be empty.');
                return false;
            }
        }
        return true;
    }
    const next = async () => {
        if (!validate()) {
            return
        }
        setError('');
        let obj = JSON.parse(JSON.stringify(ymlObj));
        if (inId) {
            await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: inId, data: { interview: obj } });
        } else {
            let temp = await Axios.post(Config.API_BASE_URL + 'saveinterview', { interview: obj, userId: auth['user']['username'] });
            if (temp.data.data) {
                setInId(temp.data.data._id);
            }
        }
        obj.push({
            'question': '',
            'fields': [
                {
                    'variable': ''
                }
            ]
        });
        setYmlObj(obj);
    }
    const remove = (index) => {
        setError('');
        let obj = JSON.parse(JSON.stringify(ymlObj));
        obj.splice(index, 1);
        setYmlObj(obj);
    }
    const submit = async () => {
        if (!validate()) {
            return
        }
        setError('');
        let obj = JSON.parse(JSON.stringify(ymlObj));
        await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: inId, data: { completed: true, interview: obj, userId: auth['user']['username'] } });
        window.location.href = '/interview';
    }
    const onTitleChange = (e) => {
        setError('');
        let obj = JSON.parse(JSON.stringify(ymlObj));
        obj[0].metadata.title = e.target.value;
        setYmlObj(obj);
    }
    const onDescriptionChange = (e) => {
        setError('');
        let obj = JSON.parse(JSON.stringify(ymlObj));
        obj[0].metadata['short title'] = e.target.value;
        setYmlObj(obj);
    }
    const setQuestionText = (e, index) => {
        setError('');
        let obj = JSON.parse(JSON.stringify(ymlObj));
        obj[index].question = e.target.value + '\n';
        setYmlObj(obj);
    }
    const setVariableText = (e, index) => {
        setError('');
        let obj = JSON.parse(JSON.stringify(ymlObj));
        obj[1].code[index - 2] = e.target.value;
        obj[index]['fields'][0]['variable'] = e.target.value;
        setYmlObj(obj);
    }
    const changeQuestionType = (e, index) => {
        setError('');
        let obj = JSON.parse(JSON.stringify(ymlObj));
        if (e.target.value == 'text') {
            delete obj[index]['fields'][0]['input type'];
            delete obj[index]['fields'][0]['datatype'];
            delete obj[index]['fields'][0]['choices'];
            delete obj[index]['fields'][0]['help'];
            delete obj[index]['fields'][0]['default'];
            delete obj[index]['fields'][0]['hint'];
        } else if (e.target.value == 'yesno') {
            delete obj[index]['fields'][0]['input type'];
            delete obj[index]['fields'][0]['choices'];
            delete obj[index]['fields'][0]['help'];
            delete obj[index]['fields'][0]['default'];
            delete obj[index]['fields'][0]['hint'];
            obj[index]['fields'][0]['datatype'] = 'yesno';
        } else if (e.target.value == 'date') {
            delete obj[index]['fields'][0]['input type'];
            delete obj[index]['fields'][0]['choices'];
            delete obj[index]['fields'][0]['default'];
            delete obj[index]['fields'][0]['hint'];
            obj[index]['fields'][0]['datatype'] = 'date';
            obj[index]['fields'][0]['help'] = "speechengine";
        } else if (e.target.value == 'email') {
            delete obj[index]['fields'][0]['input type'];
            delete obj[index]['fields'][0]['choices'];
            delete obj[index]['fields'][0]['default'];
            delete obj[index]['fields'][0]['hint'];
            obj[index]['fields'][0]['datatype'] = 'email';
            obj[index]['fields'][0]['help'] = "speechengine";
        } else if (e.target.value == 'phone') {
            delete obj[index]['fields'][0]['input type'];
            delete obj[index]['fields'][0]['choices'];
            delete obj[index]['fields'][0]['help'];
            obj[index]['fields'][0]['datatype'] = 'integer';
            obj[index]['fields'][0]['hint'] = 'bothlength';
            obj[index]['fields'][0]['default'] = 10;
        } else if (e.target.value == 'integer') {
            delete obj[index]['fields'][0]['input type'];
            delete obj[index]['fields'][0]['choices'];
            delete obj[index]['fields'][0]['help'];
            delete obj[index]['fields'][0]['default'];
            delete obj[index]['fields'][0]['hint'];
            obj[index]['fields'][0]['datatype'] = 'integer';
        } else if (e.target.value == 'radio') {
            delete obj[index]['fields'][0]['input type'];
            delete obj[index]['fields'][0]['help'];
            delete obj[index]['fields'][0]['default'];
            delete obj[index]['fields'][0]['hint'];
            obj[index]['fields'][0]['datatype'] = 'radio';
            obj[index]['fields'][0]['choices'] = [''];
        }
        setYmlObj(obj);
    }
    const addChoice = (index) => {
        setError('');
        let obj = JSON.parse(JSON.stringify(ymlObj));
        obj[index]['fields'][0]['choices'].push('');
        setYmlObj(obj);
    }
    const changeChoice = (e, i, pi) => {
        setError('');
        let obj = JSON.parse(JSON.stringify(ymlObj));
        obj[pi]['fields'][0].choices[i] = e.target.value;
        setYmlObj(obj);
    }
    const removeChoice = (i, pi) => {
        setError('');
        let obj = JSON.parse(JSON.stringify(ymlObj));
        obj[pi]['fields'][0].choices.splice(i, 1);
        setYmlObj(obj);
    }
    const logicChange = (index) => {
        setError('');
        let variable: any = document.getElementById('variable' + index);
        variable = variable.selectedOptions[0].value;
        let toValue: any = document.getElementById('tovalue' + index);
        toValue = toValue.selectedOptions[0].value;
        toValue = toValue == 'true' ? 'True' : toValue == 'other' ? 'other' : 'False';
        if (toValue == 'other') {
            let oder = otherValue;
            oder[index] = true
            setOtherValue(oder);
        } else {
            let oder = otherValue;
            oder[index] = false;
            setOtherValue(oder);
        }
        let obj = JSON.parse(JSON.stringify(ymlObj));
        if (variable !== 'null' && toValue !== 'null') {
            obj[1].code[index - 2] = `if ${variable} == ${toValue}:\n ${obj[index]['fields'][0]['variable']}\nelse:\n ${obj[index]['fields'][0]['variable']}=''`;
            setYmlObj(obj);
        }
    }
    const otherValueChange = (value, index) => {
        let variable: any = document.getElementById('variable' + index);
        variable = variable.selectedOptions[0].value;
        let obj = JSON.parse(JSON.stringify(ymlObj));
        obj[1].code[index - 2] = `if ${variable} == '${value}':\n ${obj[index]['fields'][0]['variable']}\nelse:\n ${obj[index]['fields'][0]['variable']}=''`;
        setYmlObj(obj);
    }
    const onDateHintChange = (hint, index) => {
        setError('');
        let obj = JSON.parse(JSON.stringify(ymlObj));
        obj[index]['fields'][0].hint = hint;
        obj[index]['fields'][0]['default'] = moment(startDate).format('YYYY-MM-DD');
        setYmlObj(obj);
    }
    const removeDateHint = (index) => {
        setError('');
        let obj = JSON.parse(JSON.stringify(ymlObj));
        delete obj[index]['fields'][0].hint;
        delete obj[index]['fields'][0]['default'];
        setYmlObj(obj);
    }
    const onDateChange = (date, index) => {
        setStartDate(date);
        let obj = JSON.parse(JSON.stringify(ymlObj));
        if (obj[index]['fields'][0].hint) {
            obj[index]['fields'][0]['default'] = moment(date).format('YYYY-MM-DD');
        }
        setYmlObj(obj);
    }
    React.useEffect(() => {
        getPendingInterviews();
    }, [auth]);
    return (
        <MessageContainer render={({ messages, setMessages, resetSession, useOldSession }) => (
            <>
                <div>
                    <Header resetSession={resetSession} />
                    <div className="app-main">
                        <div className="app-main-inner">
                            <Container>
                                <div className="table-responsive addnewinterview_">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th colSpan={2} className="border-right-0 interviewHeading">
                                                    <div className="row d-flex flex-wrap justify-content-center align-items-stretch py-2">
                                                        <div className="col-md-6  col-xs-12 d-flex align-items-center">
                                                            <h1 className="h2 mb-0">Add New Interview</h1>
                                                        </div>
                                                        <div className="col-md-6  col-xs-12 d-flex justify-content-end">
                                                            {ymlObj.length > 5 ? <a title="Complete Interview" href="javascript:void(0)" onClick={() => submit()} className="btn btn-primary text-capitalize">Complete interview</a> : <a title="Complete Interview" href="javascript:void(0)" className="btn btn-default text-capitalize">Complete interview</a>}
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="row mt-3">
                                                        <div className="col-12">
                                                            <form action="" className="addnewint">
                                                                <div className="form-group">
                                                                    <label>Title</label>
                                                                    <input placeholder="Enter interview title...
" className="form-control" value={ymlObj[0].metadata.title} onChange={(e) => onTitleChange(e)} type="text" required />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Description</label>
                                                                    <input placeholder="Enter interview description...
" className="form-control" value={ymlObj[0].metadata['short title']} type="text" onChange={(e) => onDescriptionChange(e)} required />
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className='col-6'>
                                                            <h4 className="mb-0">Questions</h4>
                                                        </div>
                                                        <div className='col-6 text-right'>
                                                            <a title="Add Question" href="javascript:void(0)" onClick={() => next()} className="btn btn-outline">+ Add Question</a>
                                                        </div>
                                                        <div className="col-12">
                                                            {error ? <div className="form-group">
                                                                <span style={{ fontSize: '14px', color: 'red' }}>{error}</span>
                                                            </div> : null}
                                                        </div>
                                                        {
                                                            ymlObj.map(item => item).reverse().map((el, index) => {
                                                                if (ymlObj.length - index <= 4) { return null } return (<div className="col-12">
                                                                    <div className="">
                                                                        <div className="row">
                                                                            <div className="sequence text-center h-100">
                                                                                <div>{ymlObj.length - index - 4}.</div>
                                                                                <div className="deletenewint">
                                                                                    {ymlObj.length - index - 4 > 1 ? <a href="javascript:void(0)" onClick={() => remove(ymlObj.length - index - 1)}><svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.78902 0.290092L10.499 1.00009H12.999C13.549 1.00009 13.999 1.45009 13.999 2.00009C13.999 2.55009 13.549 3.00009 12.999 3.00009H0.999023C0.449023 3.00009 -0.000976562 2.55009 -0.000976562 2.00009C-0.000976562 1.45009 0.449023 1.00009 0.999023 1.00009H3.49902L4.20902 0.290092C4.38902 0.110092 4.64902 9.15527e-05 4.90902 9.15527e-05H9.08902C9.34902 9.15527e-05 9.60902 0.110092 9.78902 0.290092ZM2.99902 18.0001C1.89902 18.0001 0.999023 17.1001 0.999023 16.0001V6.00009C0.999023 4.90009 1.89902 4.00009 2.99902 4.00009H10.999C12.099 4.00009 12.999 4.90009 12.999 6.00009V16.0001C12.999 17.1001 12.099 18.0001 10.999 18.0001H2.99902Z" fill="black" fill-opacity="0.54" />
                                                                                    </svg></a> : null}
                                                                                </div>
                                                                            </div>
                                                                            <div className="addInterview">
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <form action="" className="addnewint">
                                                                                            <div className="form-group">
                                                                                                <label>Title</label>
                                                                                                <input className="form-control" onChange={(e) => setQuestionText(e, ymlObj.length - index - 1)} type="text" value={el.question} required />
                                                                                            </div>
                                                                                            <div className="form-group">
                                                                                                <label>Variable Name*</label>
                                                                                                <input className="form-control" onChange={(e) => setVariableText(e, ymlObj.length - index - 1)} type="text" value={el['fields'][0]['variable']} required />
                                                                                            </div>

                                                                                            {
                                                                                                el['fields'][0].choices ? el['fields'][0].choices.map((e, i) => {
                                                                                                    return (

                                                                                                        <div className="form-group" style={{ position: 'relative' }}>
                                                                                                            <label>Choice</label>
                                                                                                            <input className="form-control" value={e} onChange={(e) => changeChoice(e, i, ymlObj.length - index - 1)} type="text" required />
                                                                                                            {el['fields'][0].choices.length > 1 ? <a style={{ top: '20px' }} className="del-icon" onClick={() => removeChoice(i, ymlObj.length - index - 1)} > <i className="material-icons">delete</i></a> : null}
                                                                                                        </div>
                                                                                                    )
                                                                                                }) : null
                                                                                            }
                                                                                            {el['fields'][0].choices ? <div className="form-group">
                                                                                                <a title="Add Choice" href="javascript:void(0)" onClick={() => addChoice(ymlObj.length - index - 1)} className="btn btn-primary text-white">Add Choice</a>
                                                                                            </div> : null}

                                                                                        </form>
                                                                                    </div>
                                                                                    <div className="col-md-12 addnewint">
                                                                                        <div className="form-group">
                                                                                            <label>Datatype</label>
                                                                                            <select onChange={(e) => changeQuestionType(e, ymlObj.length - index - 1)} className="form-control">
                                                                                                <option value='text'>Text</option>
                                                                                                <option value='date'>Date</option>
                                                                                                <option value='integer'>Integer</option>
                                                                                                <option value='radio'>Radio</option>
                                                                                                <option value='yesno'>Boolean</option>
                                                                                                <option value='email'>Email</option>
                                                                                                <option value='phone'>Phone</option>
                                                                                            </select>
                                                                                            {/* <a title="" className="btn btn-primary text-white w-100 mb-3">Add Help Text</a> */}
                                                                                            {/* {active > 2 ? <a onClick={()=> remove()} title="" className="text-danger float-right w-100 text-right">Remove</a> : null} */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {el['fields'][0].datatype == 'date' ? <div className="row mb-3">
                                                                                <div className="col-md-12 addnewint">
                                                                                            <div className="form-group">
                                                                                    <label>Add Hint:</label>
                                                                                        <div className="row">
                                                                                            <div>
                                                                                                <RadioGroup onChange={(ev) => onDateHintChange(ev.target.value, ymlObj.length - index - 1)} value={el['fields'][0].hint ? el['fields'][0].hint : false} aria-label="gender" name="gender1" row>
                                                                                                    <FormControlLabel
                                                                                                        value="before"
                                                                                                        control={<Radio color="primary"
                                                                                                        />}
                                                                                                        label="before" />
                                                                                                    <FormControlLabel value="after" control={<Radio
                                                                                                        color="primary" />} label="after" />
                                                                                                </RadioGroup>
                                                                                            </div>
                                                                                            <div className="datepicui">
                                                                                                <DatePicker className="form-control" selected={startDate} onChange={date => onDateChange(date, ymlObj.length - index - 1)} /> {el['fields'][0].hint ? <a className="del-icon" onClick={() => removeDateHint(ymlObj.length - index - 1)} > <i className="material-icons">delete</i> </a> : null}
                                                                                            </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> : null}
                                                                                {ymlObj.length - index - 4 > 1 ?
                                                                                    <div className="row addnewint">
                                                                                        <div className='col-sm-12'>
                                                                                            <h2 className="subheading">Show If</h2>
                                                                                            <div className="form-group">
                                                                                                <div className="row">
                                                                                                    <div className='pl-0 col-sm-6 col-xs-12'>
                                                                                                        <label>Variable</label>
                                                                                                        <select onChange={(e) => logicChange(ymlObj.length - index - 1)} id={`variable${ymlObj.length - index - 1}`} className="form-control w-100 mb-3">
                                                                                                            <option selected disabled value='null'>Variable</option>
                                                                                                            {ymlObj.map((e, indo) => {
                                                                                                                if (indo > 1 && indo !== ymlObj.length - index - 1) {
                                                                                                                    return (
                                                                                                                        <option value={e['fields'][0]['variable']}>{e['fields'][0]['variable']}</option>
                                                                                                                    )
                                                                                                                }
                                                                                                            })}
                                                                                                        </select>
                                                                                                    </div>
                                                                                                    <div className='pr-0 col-sm-6 col-xs-12'>
                                                                                                        <label>Value</label>
                                                                                                        <select onChange={(e) => logicChange(ymlObj.length - index - 1)} id={`tovalue${ymlObj.length - index - 1}`} className="form-control w-100 mb-3">
                                                                                                            <option selected disabled value='null'>Value</option>
                                                                                                            <option value='true'>Yes</option>
                                                                                                            <option value='false'>No</option>
                                                                                                            <option value='other'>Other</option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-3'>
                                                                                            {otherValue[ymlObj.length - index - 1] ? <input type="text" onChange={(e) => otherValueChange(e.target.value, ymlObj.length - index - 1)} id='tovalueother' placeholder="Value to check" className="form-control" /> : null}
                                                                                        </div>
                                                                                    </div> : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                            })}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Container>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        )}
        />
    );
};