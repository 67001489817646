import * as React from 'react';
import '../Chat/app.scss';
import './availableInterviews.scss';
import Modal from 'react-bootstrap/Modal';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import { MessageContainer } from '../../Containers/MessageContainer';
import { AuthContext } from '../../contexts/AuthContext';
import Axios from 'axios';
import { DAClient } from '../../lib/DAClient';
import * as Config from '../../config';
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import * as LangConfig from '../../languages/languages';

const da = new DAClient();
interface IValueState {
	availInterviews: any[];
	myinterviews: any[];
	completedInterviews: any[];
}

export const AvailableInterviews: React.FC = (props: any) => {

	const auth = React.useContext(AuthContext);
	let userCognitoGroups = auth.accessToken.payload['cognito:groups'];
	const [translations, setTranslations] = React.useState(auth['user']['attributes'].locale ? LangConfig.languages[auth['user']['attributes'].locale]['translations'] : LangConfig.languages['en']['translations']);
	const [userLang, seruserlang] = React.useState(auth['user']['attributes'].locale ? auth['user']['attributes'].locale : 'en');
	const [loading, setLoading] = React.useState(false);
	const [modal, setModal] = React.useState({
		show: false,
		data: null,
		id: null
	});


	let userDetails = '';
	if(localStorage.getItem("userId")) {
		if(JSON.parse(localStorage.getItem("userDetails")).fileMatter) {
			userDetails += JSON.parse(localStorage.getItem("userDetails")).fileMatter + ' - ';
		}
		userDetails += JSON.parse(localStorage.getItem("userDetails")).FirstName;
	}

	const [values, setValues] = React.useState<IValueState>({
		availInterviews: [],
		myinterviews: [],
		completedInterviews: []

	});

	const listAvailInterviews = async () => {
		let sess = await Axios.post(Config.API_BASE_URL + 'getDocAssembleUserData/', {
			userId: userCognitoGroups != undefined && userCognitoGroups.indexOf('Superadmin') != -1 && localStorage.getItem("userId")? localStorage.getItem("userId") : auth['user']['username'],
			host: Config.appHost
		});
		let AvailableInterviews: any = await da.listInterviews();
		AvailableInterviews = AvailableInterviews.filter((e) => {
			return e.metadata['required privileges'].indexOf(Config.appHost) != -1;
		}).map(e => {
			if (!e.metadata.authors) {
				e.metadata['authors'] = [];
				e.metadata['authors'].push({ 'name': 'en' });
			}
			return e;
		});
		AvailableInterviews = AvailableInterviews.filter((e) => {
			return e.metadata.authors && e.metadata.authors[0].name == userLang;
		})
		AvailableInterviews = AvailableInterviews.sort((a, b) => {
			var nameA = a.title.toUpperCase();
			var nameB = b.title.toUpperCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		});
		let completed = sess.data.DocAssembleData.filter(result => {
			let avail = AvailableInterviews.filter(e => e.filename == result.i);
			if (checkFileNumber(result) && avail.length > 0) {
				return true;
			} else {
				return false;
			}
		});
		console.log(completed, AvailableInterviews);
		setValues({ ...values, availInterviews: AvailableInterviews, myinterviews: sess.data.DocAssembleData, completedInterviews: completed });
		setLoading(false);
	}

	const checkForAvailable = async (result) => {
		let data = values.myinterviews.filter(ele => ele.i == result.filename && (!ele.fileNumber || ele.fileNumber == 0));
		if (data.length == 0) {
			window.location.href = `/?i=${result.filename}&title=${result.metadata.title}&total_steps=${result.metadata.total_steps}`;
			return
		}
		if (!data[data.length - 1].questionAns) {
			window.location.href = `/?i=${result.filename}&title=${result.metadata.title}&total_steps=${result.metadata.total_steps}`;
			return
		}
		setModal({ ...modal, show: true, data: data, id: result });
	}

	const continueWithOld = async () => {
		let result = modal.data[modal.data.length - 1];
		await da.resumeInterview(result);
		window.location.href = "/?i=" + result.i + "&title=" + result.title + "&total_steps=" + result.total_steps + "&interviewId=" + result._id;
	}

	const startNew = async () => {
		let data = values.myinterviews.filter(ele => ele.i == modal.id.filename);
		if (window.confirm(translations.Your_old_data_will_be_lost)) {
			for (let d of data) {
				await Axios.post(Config.API_BASE_URL + 'delDocAssembleUserData/', {
					id: d._id
				});
			}
			window.location.href = `/?i=${modal.id.filename}&title=${modal.id.metadata.title}&total_steps=${modal.id.metadata.total_steps}`;
		}
	}

	const editInterview = async (result) => {
		window.location.href = "/editinterview?v=" + result._id;
	}

	const checkFileNumber = (result) => {
		if (result && result.fileNumber) {
			let file = result.fileNumber.split("&nb&");
			if (file[0] && file[0] > 0) {
				return true;
			}
		}
		return false;
	}

	const checkForCompleted = (result) => {
		let data = values.myinterviews.filter(ele => ele.i == result.filename && ele.fileNumber && ele.fileNumber !== 0);
		if (data.length > 0) {
			return data[0];
		}
		return false;
	}


	const downloadFile = async (result) => {
		let file = result.fileNumber.split("&nb&");
		for (let f of file) {
			await da.retrieveFile(f, result.title);
		}
	}

	const delInterviewData = async (result) => {
		if (window.confirm(translations.interview_delete_confirm)) {
			let sess = await Axios.post(Config.API_BASE_URL + 'delDocAssembleUserData/', {
				id: result._id
			});

			if (sess.data.DocAssembleData.deletedCount == 1) {
				window.location.reload();
			} else {
			}
		}
	}

	const startFromCompleted = (result) => {
		let data = values.availInterviews.filter(ele => ele.filename == result.i);
		if (data.length > 0) {
			checkForAvailable(data[0])
		}
	}


	React.useEffect(() => {
		setLoading(true);
		listAvailInterviews();
	}, [auth]);

	return (
		<MessageContainer
			render={({ messages, setMessages, resetSession, useOldSession }) => (
				<>
					<div>
						<Modal show={modal.show}>
							<Modal.Header closeButton>
								<Modal.Title>{modal.id ? modal.id.title : ''}</Modal.Title>
							</Modal.Header>
							<Modal.Body style={{ background: 'white', textAlign: 'center' }}>
								<p>{translations.continue_interview_confirmation}</p>
								<a onClick={() => { continueWithOld() }} href="javascript:void(0)" className="btn btn-primary text-white">{translations.continue}</a><br /><br />
								<p style={{ textAlign: 'center', fontWeight: 'bold' }}>{translations.OR}</p>
								<p style={{ marginBottom: '0' }}>{translations.Would_you_like_to_start_a_new_interview}</p>
								<span style={{ color: 'red', fontSize: '12px' }}>({translations.old_data_warning})</span><br /><br />
								<a onClick={() => { startNew() }} href="javascript:void(0)" className="btn btn-primary text-white">
									{translations.Start_New}
								</a>
							</Modal.Body>
							<Modal.Footer>
								<a onClick={() => { setModal({ ...modal, show: false, data: null, id: null }); }} href="javascript:void(0)" className="btn btn-danger text-white">
									{translations.cancel}
								</a>
							</Modal.Footer>
						</Modal>
						<Header resetSession={resetSession} />
						<div className="app-main">
							<div className="app-main-inner">
								<Container style={{ paddingTop: 30, paddingBottom: 30, }} >
									<div className="table-responsive reviewEditInterview position-r">
										<table className="table table-bordered">
											<thead>
												<tr>
													<th className="border-right-0 reviewHeading py-4 px-0">
														<div className="row d-flex flex-wrap justify-content-center align-items-stretch">
															<div className="col-12 col-sm-8 border-right-0 d-flex align-items-center">
																<h1 className="h2 mb-0">
																	<span>
																		<span>Interviews: </span>
																	</span>
																	<span className="text-primary border-b-blue">{userDetails == "" ? auth['user']['username'] : userDetails}</span>
																</h1>
															</div>
															<div className="col-12 col-sm-4 px-3 d-flex justify-content-start justify-content-sm-end align-items-center">
																<span className="int_title">&nbsp;&nbsp;</span>
															</div>
														</div>
													</th>
												</tr>
											</thead>
											<tbody>
												<div className="row justify-content-center">
													<div className="col-12 col-sm-10">
														<div className=" addbtn">
															{/* {(userCognitoGroups != undefined && (userCognitoGroups.indexOf('admin') != -1 || userCognitoGroups.indexOf('Superadmin') != -1)) ? <a title={translations.edit_interview} href="/addnewquestions" className="btn btn-outline">+ {translations.Add_interview}</a> : null} */}
														</div>
														<div className="interviews-list">
															<Tabs defaultActiveKey={props.location.state && props.location.state.fromEdit ? "Completed" : "Available"} id="interviews-list" style={{"margin": "20px auto"}}>

																<Tab eventKey="Available" title={translations.available}>
																	<ul>
																		{values.availInterviews.map(function (result, index) {
																			return (
																				<li>
																					<div className="interviews-list-item d-flex align-items-center">
																						<div className="d-flex flex-column flex-grow-1 ">
																							<a href="javascript:void(0)" onClick={() => checkForAvailable(result)}>{result.metadata.title}</a>
																							{result.metadata && result.metadata.comment ? <p>{result.metadata.comment}</p> : <p className="text-muted">{translations.no_description} </p>}
																						</div>
																						<span className="interviews-list">
																							<a onClick={() => checkForAvailable(result)} className="ply-btn" title="Start Interview"><i _ngcontent-jlq-c19="" className="material-icons">play_arrow</i></a>
																						</span>
																					</div>
																				</li>
																			);
																		})
																		}
																	</ul>
																	{values.availInterviews.length == 0 && !loading ? <div>
																		<div className="row justify-content-center">
																			<div className="col-12 col-md-6">
																				<ul>
																					<li>
																						<div className="interviews-list-item d-flex align-items-center">
																							<div className="d-flex flex-column flex-grow-1 ">
																								<p className="interviews-list-empty">{translations.no_avail_interview}</p>
																							</div>
																						</div>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</div> : null}
																</Tab>
																<Tab eventKey="Completed" title={translations.completed}>
																	<ul>
																		{values.completedInterviews.map(function (result, index) {
																			return (
																				<li>
																					<div className="interviews-list-item d-flex align-items-center">
																						<div className="d-flex flex-column flex-grow-1 ">
																							<a href="javascript:void(0)">{result.title}</a>
																							{/* {result.metadata && result.metadata.comment ? <p>{result.metadata.comment}</p> : <p className="text-muted">No Description </p>} */}
																							<span className="interview-ico">
																								<a title={translations.edit_interview} onClick={() => editInterview(result)}><i _ngcontent-jlq-c19="" className="material-icons">edit</i></a>
																								<a title={translations.delete_interview} onClick={() => delInterviewData(result)}><i _ngcontent-jlq-c19="" className="material-icons">delete</i></a>
																								{checkFileNumber(result) ? <a target="_blank" title={translations.download_interview_pdf} onClick={() => downloadFile(result)}><i _ngcontent-jlq-c19="" className="material-icons">get_app</i></a> : null}
																							</span>
																						</div>
																						<span className="interviews-list">
																							<a onClick={() => startFromCompleted(result)} className="ply-btn" title={translations.start_interview}><i _ngcontent-jlq-c19="" className="material-icons">play_arrow</i></a>
																						</span>
																					</div>
																				</li>
																			);
																		})
																		}
																	</ul>
																	{values.completedInterviews.length == 0 && !loading ? <div>
																		<div className="row justify-content-center">
																			<div className="col-12 col-md-6">
																				<ul>
																					<li>
																						<div className="interviews-list-item d-flex align-items-center">
																							<div className="d-flex flex-column flex-grow-1 ">
																								<p className="interviews-list-empty">{translations.no_completed_interview}</p>
																							</div>
																						</div>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</div> : null}
																</Tab>
															</Tabs>
														</div>
													</div>
												</div>
												<div className="mt-3 mb-3">
													{loading ? <div className="loader-cont"><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div> : null}
												</div>
											</tbody>
										</table>
									</div>
								</Container>
							</div>
						</div>
						<Footer />
					</div>
				</>
			)}
		/>
	);
};
