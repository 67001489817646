import React from 'react';
import Icon from '@material-ui/core/Icon';
import { AuthContext } from '../../contexts/AuthContext';
import Recorder from 'recorder-js';
import Slider from '@material-ui/core/Slider';
import Axios from 'axios';
import * as Config from '../../config';
import speed_ from '../../assets/images/speed_.svg';
import reload_ from '../../assets/images/reload.svg';
import volume_off_ from '../../assets/images/volume_off.svg';
import boxarrow_ from '../../assets/images/boxarrow.svg';
import * as LangConfig from '../../languages/languages';
import './mic.scss';

export interface IWindow extends Window {
  webkitSpeechRecognition: any;
}

var recorder = null;
var audioStream = null;

interface Props {
  onResults: (userResponse: string, msgUpdated: any) => void;
  onMicInput: (micStarted: any) => void;
  onBotResponse: any;
  inputError: any;
  messageSend: any;
  micStarted: any;
  onVolumeOff: (muteBot: string) => void
  interviewInitiated: any;
  questionType: any;
  messages: any;
}

export const Mic: React.FC<Props> = ({ onResults, onMicInput, onBotResponse, inputError, messageSend, micStarted, onVolumeOff, interviewInitiated, questionType, messages }) => {
  let query_params = new URLSearchParams(window.location.search);
  let iVal = query_params.get('i');
  let titleVal = query_params.get('title');
  let total_steps = query_params.get('total_steps');
  let interviewId = query_params.get('interviewId');
  let windowHref = "/?i=" + iVal + "&title=" + titleVal + "&total_steps=" + total_steps;
  const auth = React.useContext(AuthContext);
  const [hasBrowserSupport, setHasBrowserSupport] = React.useState<Boolean>(
    false,
  );
  const [languageData, setlanguageData] = React.useState(auth['user']['attributes'].locale ? LangConfig.languages[auth['user']['attributes'].locale] : LangConfig.languages['en']);
  const [loading, setloading] = React.useState<Boolean>(false);
  let myRef = React.useRef<any>();
  let recognition: any;
  let interim_transcript: String = '';
  let soundDetect: any = false;
  let soundStart: any = false;
  let soundError: any = false;
  let soundResults: any = false;
  let recognizedResults: any = '';
  let recognizedIndexes: any = 0;
  let userResponseStarted: any = false;

  let volumeBox: any = false;

  const { webkitSpeechRecognition }: IWindow = window as IWindow;
  const opera = navigator.userAgent.match(/Opera|OPR\//) ? true : false;
  if (webkitSpeechRecognition && !opera) {
    if (!(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))) {
      !hasBrowserSupport && setHasBrowserSupport(true);
      recognition = new webkitSpeechRecognition();
      // const speechRecognitionList = new SpeechGrammarList();
      // speechRecognitionList.addFromString(grammar, 1);
      // recognition.grammars = speechRecognitionList;
      recognition.continuous = true;

      recognition.lang = languageData.langCode;
      recognition.interimResults = true;
      recognition.maxAlternatives = 1;

      // recognition.interimResults = (event: any) => {
      //   // console.log(event);
      // };

      setTimeout(() => {
        if (localStorage.getItem('response') != "false" && onBotResponse == "answered" && questionType == "yesno") {
          recognition.onend();
          document.getElementById('autoStartMic').classList.add("hidden");
          document.getElementById('startMic').classList.remove("hidden");
          document.getElementById('stopMic').classList.add("hidden");
          localStorage.setItem('micStarted', 'false');
          // micManualStop();
        }

        if (onBotResponse == true && !soundStart && inputError == undefined && messageSend != "msgSend" && localStorage.getItem('muteBot') != "true" && messages.length > 1) {
          if (messages[messages.length - 1].message !== 'text_long') {
            if (messages[messages.length - 2]) {
              if (messages[messages.length - 2].message !== 'text_long') {
                micAutoStart();
              }
            } else {
              micAutoStart();
            }
          }
        }

        if ((inputError == false && messageSend != "msgSend")) {
          // micAutoStartOnValidation();
          document.getElementById('autoStartMic').classList.add("hidden");
          document.getElementById('startMic').classList.remove("hidden");
        }

        if ((onBotResponse == true && !soundStart && inputError == undefined && messageSend != "msgSend") && (localStorage.getItem('muteBot') == "true")) {
          document.getElementById('autoStartMic').classList.add("hidden");
          document.getElementById('startMic').classList.remove("hidden");
        }
      }, 750);


      recognition.onstart = function () {
        soundStart = true;
        userResponseStarted = true;
        interim_transcript = '';
        accessMic();
      }

      recognition.onaudiostart = function () {
        setTimeout(() => {
          if (!soundDetect || interim_transcript == '') {
            recognition.onerror({ error: 'no-speech' });
          }
        }, 3000);
      }

      recognition.onerror = function (event) {
        if (event.error === "no-speech") {
          // console.log('mic stop no sound soundDetect');
          recognition.onend();
        }
      }

      recognition.onspeechstart = function () {
        soundDetect = true;
      }

      recognition.onnomatch = function () {
        // console.log('no match - Speech not recognized'); 
      }

      recognition.onspeechend = function () {
        // console.log('onspeechend');
      }

      recognition.onsoundend = function () {
        // console.log('onsoundend');
      }

      recognition.onaudioend = function () {
        // console.log('onaudioend');
      }

      recognition.onend = function (event) {
        // console.log('end'); 
        audioStream && audioStream.getTracks()[0].stop();
        if (recognizedResults == '' && localStorage.getItem('response') != "false") {
          onResults(localStorage.getItem('response'), "answered");
        }

        if (soundStart) {
          // console.log(onBotResponse, soundStart, soundDetect, soundError, soundResults, inputError, messageSend);

          if (recognizedResults != '') {
            // console.log(recognizedResults);
            recognizedIndexes = recognizedIndexes + 1;
          }

          if (soundStart && !soundDetect) {
            soundError = true;
          } else if (soundStart && soundDetect && !soundResults) {
            soundError = true;
          } else {
            soundError = false;
          }

          soundStart = false;
          soundDetect = false;
          soundResults = false;

          // console.log(onBotResponse, soundStart, soundDetect, soundError, soundResults, inputError, messageSend);
        }
        micStop();
        localStorage.setItem('micStarted', 'false');
      }

      recognition.onresult = (event: any) => {
        for (var i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            // console.log(event);
          } else {
            interim_transcript += event.results[i][0].transcript;
            return;
          }
        }


        audioStream && audioStream.getTracks()[0].stop();
        const last = event.results.length - 1;
        const text = event.results[last][0].transcript;

        recognizedResults = recognizedResults + text;

        if (localStorage.getItem('micStarted') == 'true') {
          setTimeout(() => {
            let string = false;
            if (recognizedResults != '' && last + 1 == recognizedIndexes) {
              // console.log("-----"+recognizedResults);
              if (messages[messages.length - 1].raw && messages[messages.length - 1].raw.fields && messages[messages.length - 1].raw.fields[0] && messages[messages.length - 1].raw.fields[0].helptext) {
                string = messages[messages.length - 1].raw.fields[0].helptext.includes("speechengine");
              }
              if (localStorage.getItem('micStarted') == 'true' && (!messages[messages.length - 1].raw || !messages[messages.length - 1].raw.fields || !messages[messages.length - 1].raw.fields[0] || !messages[messages.length - 1].raw.fields[0].helptext || !string)) {
                onResults(recognizedResults, false);
                soundResults = true;
                recognition.onend();
              }
              // else if(messages[messages.length - 1].questionRange && messages[messages.length - 1].questionRange.hint && messages[messages.length - 1].questionRange.hint === 'speechengine'){
              else if (messages[messages.length - 1].raw && messages[messages.length - 1].raw.fields && messages[messages.length - 1].raw.fields[0] && messages[messages.length - 1].raw.fields[0].helptext && string) {
                stopAccessingMic();
              }
            }
          }, 3000);
          recognizedIndexes = recognizedIndexes + 1;
        } else {
          recognition.onend();
        }

        // console.log('Confidence: ' + event.results[0][0].confidence);
      };
    }
  }

  const accessMic = () => {
    const audioContext = new AudioContext();
    recorder = new Recorder(audioContext);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(handleSuccess)
      .catch(err => console.log('Uh oh... unable to get stream...', err));
  }

  const handleSuccess = (stream) => {
    audioStream = stream;

    recorder.init(stream);
    recorder.start();
  }

  const stopAccessingMic = () => {
    recorder.stop().then(({ blob, buffer }) => {
      getTextFromGoogle(blob);
      setloading(true);
      console.log("loader started");

      document.getElementById('autoStartMic').classList.remove("hidden");
      document.getElementById('stopMic').classList.add("hidden");
      document.getElementById('startMic').classList.add("hidden");
    });
  }

  const getTextFromGoogle = async (blob) => {
    audioStream && audioStream.getTracks()[0].stop();
    let filename = new Date().toISOString();
    let formData = new FormData();
    formData.append('audio_data', blob, filename);
    formData.append('lang', auth['user']['attributes'].locale ? auth['user']['attributes'].locale : 'en');
    try {
      let response = await Axios.post(Config.API_BASE_URL + 'audioText', formData);
      setloading(false);
      if (response.data.audioText) {
        onResults(response.data.audioText, false);
      } else {
        onResults(recognizedResults, false);
      }
      soundResults = true;
      recognition.onend();
    } catch (err) {
      setloading(false);
      onResults(recognizedResults, false);
      soundResults = true;
      recognition.onend();
    }
  }



  const micAutoStart = async () => {
    if (messageSend != "msgSend") {
      onMicInput('true');
      recognition.start();
      localStorage.setItem('micStarted', 'true');

      document.getElementById('autoStartMic').classList.add("hidden");
      document.getElementById('stopMic').classList.remove("hidden");
      document.getElementById('startMic').classList.add("hidden");
    }
  }

  const micAutoStartOnValidation = async () => {
    if (micStarted == "") {
      onMicInput('other');
    }
    if ((messageSend != "micDisable" || inputError == false) && micStarted == 'validationCase') {
      recognition.start();
      localStorage.setItem('micStarted', 'true');

      document.getElementById('autoStartMic').classList.add("hidden");
      document.getElementById('stopMic').classList.remove("hidden");
      document.getElementById('startMic').classList.add("hidden");
    }
  }

  const micStart = async () => {
    if (loading || localStorage.getItem('lastloader') == 'true') {
      return
    }
    // console.log(messageSend, onBotResponse);

    if (messageSend != "micDisable" || (onBotResponse == false && messageSend == "micDisable") || inputError == false) {
      onMicInput('true');
      recognition.start();
      localStorage.setItem('micStarted', 'true');

      document.getElementById('autoStartMic').classList.add("hidden");
      document.getElementById('stopMic').classList.remove("hidden");
      document.getElementById('startMic').classList.add("hidden");
    }
  }

  const micStop = async () => {
    // console.log("stop mic");
    onMicInput('false');
    recognition.stop();

    // console.log(soundError);
    document.getElementById('stopMic').classList.add("hidden");
    if (soundError) {
      document.getElementById('autoStartMic').classList.add("hidden");
      document.getElementById('startMic').classList.remove("hidden");
    } else {
      document.getElementById('startMic').classList.add("hidden");
      document.getElementById('autoStartMic').classList.remove("hidden");
    }
  }

  const micManualStop = async () => {
    if (loading || localStorage.getItem('lastloader') == 'true') {
      return
    }
    // console.log("manual stop mic");
    // console.log(micStarted);

    if (micStarted != "validationCase") {
      recognition.stop();
    }
  }

  const volumeMute = (vol) => {
    // console.log(userResponseStarted);
    if (loading || localStorage.getItem('lastloader') == 'true') {
      return
    }
    if (!userResponseStarted) {
      onVolumeOff(vol);
      if (vol == "true") {
        document.getElementById('volume_on').classList.add("hidden");
        document.getElementById('volume_off').classList.remove("hidden");
      } else {
        document.getElementById('volume_off').classList.add("hidden");
        document.getElementById('volume_on').classList.remove("hidden");
      }
    }
  }

  const showHideVol = () => {
    if (loading || localStorage.getItem('lastloader') == 'true') {
      return
    }
    // if(!volumeBox) {
    document.getElementById('volControl').classList.remove("hidden");
    // volumeBox = true;
    // } else {
    //   document.getElementById('volControl').classList.add("hidden");
    //   volumeBox = false;
    // } 

    // setTimeout(() => {
    //   document.getElementById('volControl').classList.add("hidden");
    // }, 5000);

  }

  const volumeLabels = [
    {
      value: 1
    },
    {
      value: 2
    },
    {
      value: 3
    },
    {
      value: 4
    },
    {
      value: 5
    },
    {
      value: 6
    }
  ];

  if (interviewInitiated < 1) {
    localStorage.setItem('volumeRate', "2");
  }
  const [lvalue, setValue] = React.useState(Number(localStorage.getItem('volumeRate')));

  const volumeSet = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('volumeRate', newValue.toString());
    // console.log(Number(localStorage.getItem('volumeRate')));
  };

  const micBrowserSupport = () => {
    if (hasBrowserSupport && !opera) {
      if (!(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))) {
        return (<span className="mic_pos">
          <Icon id="autoStartMic" className="mic-icon">
            mic
          </Icon>

          <Icon onClick={() => micStart()} id="startMic" className="mic-icon hidden">
            mic
          </Icon>

          <Icon id="stopMic" onClick={() => micManualStop()} className="stop-icon hidden">
            clear
          </Icon>
        </span>)
      } else {
        return null;
      }
    } else {
      return null
    }
  }

  const handleClickOutside = (event) => {
    if (myRef && myRef.current && !myRef.current.contains(event.target)) {
      document.getElementById('volControl').classList.add("hidden");
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <>
      {micBrowserSupport()}
      {loading || localStorage.getItem('lastloader') == 'true' ? <div className="loader_container">
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div> : null}
      <div className="icons">
        <Icon id="ReplayIcon" className="mic-icon">
          <a href={windowHref}><em><img src={reload_} /></em></a>
        </Icon>
        <Icon id="volume_off" className="mic-icon hidden" onClick={() => volumeMute("false")}>
          <em><img src={volume_off_} /> </em>
        </Icon>
        <Icon id="volume_on" className="mic-icon " onClick={() => volumeMute("true")}>
          volume_up
        </Icon>
        <Icon id="speed_test" onClick={() => showHideVol()} className="mic-icon">
          <em><img src={speed_} /> <span>x{lvalue}</span></em>
        </Icon>
        <div ref={myRef} id="volControl" className="volControl hidden">
          <div className="boxarrow"><img src={boxarrow_} /></div>
          <p>Speed control for bot voice</p>
          <Slider
            defaultValue={Number(localStorage.getItem('volumeRate'))}
            min={1}
            max={6}
            aria-labelledby="discrete-slider-restrict"
            step={null}
            marks={volumeLabels}
            onChange={volumeSet}
          />
        </div>

      </div>
    </>
  );
};
