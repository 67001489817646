import * as React from 'react';
import '../Chat/app.scss';
import './Interviews.scss';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Button } from '../Button';
import { Container } from '../Container';
import { MessageContainer } from '../../Containers/MessageContainer';
import { AuthContext } from '../../contexts/AuthContext';
import Axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { DAClient } from '../../lib/DAClient';
import * as Config from '../../config';
import Badge from 'react-bootstrap/Badge'
const da = new DAClient();
interface IValueState {
interviews: any[];
}
export const Interviews: React.FC = () => {
const auth = React.useContext(AuthContext);
const [loading, setLoading] = React.useState(false);
const [values, setValues] = React.useState<IValueState>({
    interviews: []
    });
    const [help, setHelp] = React.useState(false);
    const instrPopup = () => {
    if (localStorage.getItem('instpopup') && localStorage.getItem('instpopup') == 'true') {
    } else {
    setTimeout(() => {
    setHelp(true);
    localStorage.setItem('instpopup', 'true');
    }, 1000);
    }
    }
    const saveInterviewData = async () => {
    let sess = await Axios.post(Config.API_BASE_URL + 'getDocAssembleUserData/', {
    userId: auth['user']['username'],
    host: Config.appHost
    });
    setValues({ ...values, interviews: sess.data.DocAssembleData });
    setLoading(false);
    }
    const downloadFile = async (fileNumber, title) => {
    let file = fileNumber.split("&nb&");
    for (let f of file) {
    await da.retrieveFile(f, title);
    }
    }
    const resumeInterview = async (result) => {
    await da.resumeInterview(result);
    window.location.href = "/?i=" + result.i + "&title=" + result.title + "&total_steps=" + result.total_steps + "&interviewId=" + result._id;
    }
    const editInterview = async (result) => {
    window.location.href = "/editinterview?v=" + result._id;
    }
    const delInterviewData = async (interviewId) => {
    if (window.confirm("Are you sure you want to delete this interview ?")) {
    // console.log(interviewId);
    let sess = await Axios.post(Config.API_BASE_URL + 'delDocAssembleUserData/', {
    id: interviewId
    });
    if (sess.data.DocAssembleData.deletedCount == 1) {
    // console.log("Interview deleted successfully.");
    window.location.reload();
    } else {
    // console.log("Interview deletion failed.");
    }
    }
    // setValues({...values , interviews: sess.data.DocAssembleData});
    }
    const checkFileNumber = (result) => {
    if (result && result.fileNumber) {
    let file = result.fileNumber.split("&nb&");
    if (file[0] && file[0] > 0) {
    return true;
    }
    }
    return false;
    }
    React.useEffect(() => {
    setLoading(true);
    saveInterviewData();
    }, [auth]);
    return (
    <MessageContainer render={({ messages, setMessages, resetSession, useOldSession })=> (
        <>
            <div>
                <Header resetSession={resetSession} />
                <Modal show={help}>
                    <Modal.Header closeButton style={{ backgroundColor: '#305cc5', color: '#fff' }}>
                        <Modal.Title>Welcome</Modal.Title>
                    </Modal.Header>
                    {/* <Modal.Body style={{ backgroundColor: 'inherit' }} dangerouslySetInnerHTML={{ __html: Config.instructions }}></Modal.Body> */}
                    <Modal.Body style={{ backgroundColor: 'inherit' }}>
                        <p>Welcome to {window.location.hostname == 'help4tn.atjbot.org' ? `Help4tn` : `WC Bot`}, your legal form assistant!</p>
                        <span><b>1.</b> Click on the button at the top of the screen labeled, “Start/Continue Interview.”</span><br />
                        <span><b>2.</b> Select the interview for the legal form you want to create.</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary text-white" onClick={()=> { setHelp(false) }}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="app-main">
                    <div className="app-main-inner">
                        <Container style={{
										paddingTop: 30,
										paddingBottom: 30,
									}}>
                            <h1 className="h2 border-custom">Completed Interviews</h1>
                            <div className="table-responsive mt-3 mb-3 completedInterviews">
                                {loading ? <div className="loader-cont">
                                    <div className="lds-roller">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div> : null}
                                <table className="table table-bordered">
                                    <tbody>
                                        {
                                        (values.interviews.length == 0 || (values.interviews.filter(ele => ele.fileNumber && ele.fileNumber != 0).length == 0))
                                        ? loading ? null : <h4>You do not have completed interviews. Go to 'Start/Continue Interview' page and start a new interview!</h4>
                                        : values.interviews.reverse().map(function (result, index) {
                                        let fileUploadQues: any = [];
                                        if (result.questionAns != undefined) {
                                        fileUploadQues = result.questionAns.filter((ques) => {
                                        return ques.variable.questionType == "file" && ques.variable[Object.keys(ques.variable)[0]] != "Yes";
                                        });
                                        console.log(fileUploadQues);
                                        }
                                        if (result.fileNumber && result.fileNumber != 0) {
                                        return (
                                        <tr key={index}>
                                            <td>{result.title} &nbsp;
                                                {result.fileNumber && result.fileNumber != 0 ?
                                                <Badge variant="success">Completed</Badge>
                                                : <Badge variant="primary">In-Progress</Badge>
                                                }
                                                {
                                                fileUploadQues != undefined ? fileUploadQues.map(function (ques, index) {
                                                return (<div><a href={Config.API_URL + ques.variable[Object.keys(ques.variable)[0]]} target="_blank">{ques.variable[Object.keys(ques.variable)[0]]}</a></div>);
                                                })
                                                :
                                                ''
                                                }
                                            </td>
                                            {/* <td>{result.i}</td> */}
                                            <td>{new Date(result.createdAt).toLocaleString()}</td>
                                            <td style={{ width: "20%" }}>
                                                {result.fileNumber && result.fileNumber != 0 ?
                                                <a title="Edit Interview" className="btn btn-primary mr-2 text-white" onClick={()=> editInterview(result)}><i _ngcontent-jlq-c19="" className="d-block  material-icons icon-image-preview">edit</i></a>
                                                : <a title="Resume Interview" className="btn btn-primary mr-2 text-white" onClick={()=> resumeInterview(result)}><i _ngcontent-jlq-c19="" className="d-block material-icons icon-image-preview">refresh</i></a>
                                                }
                                                {checkFileNumber(result) ?
                                                <a target="_blank" title="Download Interview Pdf" className="btn btn-success mr-2 text-white" onClick={()=> downloadFile(result.fileNumber, result.title)} ><i _ngcontent-jlq-c19="" className="d-block material-icons icon-image-preview">get_app</i></a>
                                                : ''}
                                                {((result.fileNumber == 0) ?
                                                <a title="Edit Interview" className="btn btn-primary mr-2 text-white" onClick={()=> editInterview(result)}><i _ngcontent-jlq-c19="" className="d-block  material-icons icon-image-preview">edit</i></a>
                                                :
                                                '')
                                                }
                                                <a title="Delete Interview" className="btn btn-danger  text-white" onClick={()=> delInterviewData(result._id)}><i _ngcontent-jlq-c19="" className="d-block material-icons icon-image-preview">delete</i></a>
                                            </td>
                                        </tr>
                                        );
                                        } else {
                                        return null;
                                        }
                                        })
                                        }
                                    </tbody>
                                </table>
                                {/* <a className="btn btn-primary text-white" style={{float: 'right'}} href="/availableInterviews">Start New Interview</a> */}
                            </div>
                        </Container>
                    </div>
                </div>
                <Footer />
            </div>
        </>
        )}
        />
        );
        };