import * as React from 'react';
import '../Chat/app.scss';

import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import { MessageContainer } from '../../Containers/MessageContainer';
import { AuthContext } from '../../contexts/AuthContext';
import Axios from 'axios';
import { DAClient } from '../../lib/DAClient';
import * as Config from '../../config';
import Badge from 'react-bootstrap/Badge'

const da = new DAClient();

interface IValueState {
	userInterviews:any[];
}
export const UserInterviews: React.FC = () => {
  	const auth = React.useContext(AuthContext);
	
	const [values, setValues] = React.useState<IValueState>({
		userInterviews: []
	});

  	let query_params = new URLSearchParams(window.location.search);
  	let userId = query_params.get('u');

  	const saveInterviewData = async () => {
      let sess = await Axios.post(Config.API_BASE_URL+'getDocAssembleUserData/', {
        userId: userId ? window.atob(userId) : '',
        host: Config.appHost
      });

      setValues({...values , userInterviews: sess.data.DocAssembleData});
    }

    const downloadFile = async(fileNumber,title) => {
    	let file = await da.retrieveFile(fileNumber,title);
    }

    React.useEffect(() => {
		saveInterviewData();
    }, [auth]);

	return (
	  <MessageContainer
	  	render={({ messages, setMessages, resetSession, useOldSession }) => (
		<>
	    <div>
		    <Header resetSession={resetSession} />
		   	<div className="app-main">
				<div className="app-main-inner">
				  <Container
				    style={{
				      paddingTop: 30,
				      paddingBottom: 30,
				    }}
				  >
				   	<h1 className="h2 border-custom">Completed Interviews</h1>
				   	<div className="table-responsive mt-3 mb-3">
				   		<table className="table table-bordered">
				   			<tbody>
					   			{
					   				values.userInterviews.length == 0
					   				?	<h4>No Interviews Completed</h4>  
					   				:   values.userInterviews.reverse().map(function(result, index) {
					   					if(result.fileNumber && result.fileNumber != 0) {
						   					let fileUploadQues:any = [];
						   					if(result.questionAns != undefined) {
							   					fileUploadQues = result.questionAns.filter((ques) => {
							   						return ques.variable.questionType == "file" && ques.variable[Object.keys(ques.variable)[0]] != "Yes";
							   					});
							   				}
						   					return(
						   						<tr key={index}>
						   							<td>{result.title} &nbsp;
							   							<Badge variant="success">Completed</Badge>
							   							{
							   								fileUploadQues != undefined ? fileUploadQues.map(function(ques, index) {
							   									return(<div><a href={Config.API_URL+ques.variable[Object.keys(ques.variable)[0]]} target="_blank">{ques.variable[Object.keys(ques.variable)[0]]}</a></div>);
							   									})
							   								: 
							   								''
							   							}		
						   							</td>
										   			<td>{new Date(result.createdAt).toLocaleString()}</td>
										   			<td  style={{width:"20%"}}>
											   			<a target="_blank" title="Download Interview Pdf" className="btn btn-success mr-2 text-white" onClick={() => downloadFile(result.fileNumber,result.title)} ><i _ngcontent-jlq-c19="" className="d-block material-icons icon-image-preview">get_app</i></a>
											   		</td>
										   		</tr>
										   	);
						   				}
					   				})
			   					}
							</tbody>					   		
					   	</table>
				   	</div>
				  </Container>
				</div>
			</div>
			<Footer/>
		</div>
		 </>
	      )}
	    />
	);
};
