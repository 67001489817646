import * as React from 'react';
import { Container } from '../Container';
import AWS from 'aws-sdk';
import { ExportToCsv } from 'export-to-csv';
import Button from '@material-ui/core/Button';
import * as Config from '../../config';

export const ExportAWSUsers: React.FC = () => {
  const [users, setUsers] = React.useState([]);

  const getUsers = async (applicationName) => {
    try {
		let allUsers = [];
		let more = true;
		let paginationToken = '';

		AWS.config.update(Config.AWSConfigExport);

		const cognito = new AWS.CognitoIdentityServiceProvider();
		
		let app_pool_Id:any;
  		if(applicationName == 'ATJ') {
	       app_pool_Id = Config.AWS_userPoolId;
	    } else {
	       app_pool_Id = Config.AWS_LDV_userPoolId;
	    }
	    
	    let params:any;

      	while (more) {  
	        if (paginationToken !== '') {
	        	params = {
		          UserPoolId: app_pool_Id,
		          PaginationToken: paginationToken
		        }
	        } else {
			    params = {
		          UserPoolId: app_pool_Id 
		        }
	        }

	        // console.log(params);
	        const rawUsers = await cognito.listUsers(params).promise();
	        // console.log(rawUsers.Users);

	        rawUsers.Users.map(function(result, index) {
	        	let resultProperties = result.Attributes.map((attr) => attr.Name);
	        	/*allUsers.push({
	        		"Email": result.Attributes.find((item,key) => { return(item.Name == "email")}).Value,
	        		"Username": result.Username,
	        		"Status": result.UserStatus,
	        		"Email Verified": result.Attributes.find((item,key) => { return(item.Name == "email_verified")}).Value,
	        		"Created": new Date(result.UserCreateDate).toISOString().split('T')[0],
	        		"Last Modified": new Date(result.UserLastModifiedDate).toISOString().split('T')[0]
	        	});*/
	        	
	        	allUsers.push({
	        		"name": resultProperties.indexOf('name') > -1 ? result.Attributes.find((item,key) => { return(item.Name == "name")}).Value: '',
	        		"given_name": resultProperties.indexOf('given_name') > -1 ? result.Attributes.find((item,key) => { return(item.Name == "given_name")}).Value : '',
	        		"family_name": resultProperties.indexOf('family_name') > -1 ? result.Attributes.find((item,key) => { return(item.Name == "family_name")}).Value : '',
	        		"middle_name": resultProperties.indexOf('middle_name') > -1 ? result.Attributes.find((item,key) => { return(item.Name == "middle_name")}).Value : '',
	        		"nickname": '',
	        		"preferred_username": result.Username,
	        		"profile": '',
	        		"picture": '',
	        		"website": '',
	        		"email": resultProperties.indexOf('email') > -1 ? result.Attributes.find((item,key) => { return(item.Name == "email")}).Value : '',
	        		// "email_verified": resultProperties.indexOf('email_verified') > -1 ? result.Attributes.find((item,key) => { return(item.Name == "email_verified")}).Value : '',
	        		"email_verified": 'true',
	        		"gender": resultProperties.indexOf('gender') > -1 ? result.Attributes.find((item,key) => { return(item.Name == "gender")}).Value : '',
	        		"birthdate": '',
	        		"zoneinfo": resultProperties.indexOf('zoneinfo') > -1 ? result.Attributes.find((item,key) => { return(item.Name == "zoneinfo")}).Value : '',
	        		"locale": resultProperties.indexOf('locale') > -1 ? result.Attributes.find((item,key) => { return(item.Name == "locale")}).Value : '',
	        		"phone_number": resultProperties.indexOf('phone_number') > -1 ? result.Attributes.find((item,key) => { return(item.Name == "phone_number")}).Value : '',
	        		"phone_number_verified": resultProperties.indexOf('phone_number_verified') > -1 ? result.Attributes.find((item,key) => { return(item.Name == "phone_number_verified")}).Value : 'false',
	        		"address": resultProperties.indexOf('address') > -1 ? result.Attributes.find((item,key) => { return(item.Name == "address")}).Value : '',
	        		"updated_at": '', //result.UserLastModifiedDate,
	        		"custom:userType": '',
	        		"cognito:mfa_enabled": 'false',
	        		"cognito:username": resultProperties.indexOf('email') > -1 ? result.Attributes.find((item,key) => { return(item.Name == "email")}).Value : '',
	        		/*"UserStatus": result.UserStatus,
	        		"UserCreateDate": new Date(result.UserCreateDate).toISOString().split('T')[0],
	        		"UserLastModifiedDate": new Date(result.UserLastModifiedDate).toISOString().split('T')[0]*/
	        	});
	        });

	        if (rawUsers.PaginationToken) {
	          paginationToken = rawUsers.PaginationToken;
	        } else {
	          more = false;
	        }
	    }
	    // console.log(allUsers);

      setUsers(allUsers);
    	const options = { 
				fieldSeparator: ',',
				quoteStrings: '"',
				filename: 'Export_AWS_Users_'+Date.now(),
				decimalSeparator: '',
				showLabels: true, 
				showTitle: false,
				title: '',
				useTextFile: false,
				useBom: true,
				useKeysAsHeaders: true,
				// headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
			};
		const csvExporter = new ExportToCsv(options);
		csvExporter.generateCsv(allUsers);

    } catch (e) {
      // console.log(e);
    }
  };

  return (
  	<div>
  		<Button style={{
				      margin: 30
				    }}
			onClick={() => getUsers('LDV')}
        	variant="contained"
        	className="btn btn-primary text-white" 
      	>
        	Export LDV Users
     	</Button>

     	<Button 
			onClick={() => getUsers('ATJ')}
        	variant="contained"
        	className="btn btn-primary text-white" 
      	>
        	Export ATJ Users
     	</Button>

	  	{/*<table>
	  		{
	        	users.map(function(result, index) {
	          		return (
	          			<tr key = {index}>
	          				<td>{result.Email}</td>
	          				<td>{result.Username}</td>
	          				<td>{result.Status}</td>
	          				<td>{result["Email Verified"]}</td>
	          				<td>{result.Created}</td>
	          				<td>{result["Last Modified"]}</td>
	          			</tr>
	          		);
		        })
		    }
		</table>*/}
  	</div>
  );
};
