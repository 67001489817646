export const i = process.env.REACT_APP_i;

export const API_KEY = process.env.REACT_APP_API_KEY;

export const DA_API_BASE_URL = process.env.REACT_APP_DA_API_BASE_URL;

export const DA_BASE_URL = process.env.REACT_APP_DA_BASE_URL;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const API_URL = process.env.REACT_APP_API_URL;

const hostname = window && window.location && window.location.hostname;
let AWS_userPoolId1, AWS_userPoolWebClientId1, host, help, helpSpanish;

if ((hostname == 'devfront.atjbot.org' || hostname == "localhost") && localStorage.getItem('iview') && localStorage.getItem('iview') !== 'false' && (localStorage.getItem('iview') == 'dv' || localStorage.getItem('iview') == 'wc')) {
	if (localStorage.getItem('iview') == 'dv') {
		host = process.env.REACT_APP_dwc_host;
		AWS_userPoolId1 = process.env.REACT_APP_dwc_AWS_userPoolId;
		AWS_userPoolWebClientId1 = process.env.REACT_APP_dwc_AWS_userPoolWebClientId;
		help = process.env.REACT_APP_dwc_help;
		helpSpanish = process.env.REACT_APP_dwc_help_es;
	} else {
		host = process.env.REACT_APP_tnwc_host;
		AWS_userPoolId1 = process.env.REACT_APP_tnwc_AWS_userPoolId;
		AWS_userPoolWebClientId1 = process.env.REACT_APP_tnwc_AWS_userPoolWebClientId;
		help = process.env.REACT_APP_tnwc_help;
		helpSpanish = process.env.REACT_APP_tnwc_help_es;
	}
} else if (hostname == 'tnwc.atjbot.org') {
	host = process.env.REACT_APP_tnwc_host;
	AWS_userPoolId1 = process.env.REACT_APP_tnwc_AWS_userPoolId;
	AWS_userPoolWebClientId1 = process.env.REACT_APP_tnwc_AWS_userPoolWebClientId;
	help = process.env.REACT_APP_tnwc_help;
	helpSpanish = process.env.REACT_APP_tnwc_help_es;
} else if (hostname == 'dwc.atjbot.org') {
	host = process.env.REACT_APP_dwc_host;
	AWS_userPoolId1 = process.env.REACT_APP_dwc_AWS_userPoolId;
	AWS_userPoolWebClientId1 = process.env.REACT_APP_dwc_AWS_userPoolWebClientId;
	help = process.env.REACT_APP_dwc_help;
	helpSpanish = process.env.REACT_APP_dwc_help_es;
} else if (hostname == "tndv.atjbot.org") {
	host = process.env.REACT_APP_tndv_host;
	AWS_userPoolId1 = process.env.REACT_APP_tndv_AWS_userPoolId;
	AWS_userPoolWebClientId1 = process.env.REACT_APP_tndv_AWS_userPoolWebClientId;
	help = process.env.REACT_APP_tndv_help;
	helpSpanish = process.env.REACT_APP_tndv_help_es;
} else if (hostname == "help4tn.atjbot.org"   || hostname == "localhost") {
	host = process.env.REACT_APP_help4tn_host;
	AWS_userPoolId1 = process.env.REACT_APP_help4tn_AWS_userPoolId;
	AWS_userPoolWebClientId1 = process.env.REACT_APP_help4tn_AWS_userPoolWebClientId;
	help = process.env.REACT_APP_help4tn_help;
	helpSpanish = process.env.REACT_APP_help4tn_help_es;
} else if (hostname == 'lawdroid.atjbot.org' || hostname == 'devfront.atjbot.org') {
	host = process.env.REACT_APP_lawdroid_host;
	AWS_userPoolId1 = process.env.REACT_APP_lawdroid_AWS_userPoolId;
	AWS_userPoolWebClientId1 = process.env.REACT_APP_lawdroid_AWS_userPoolWebClientId;
	help = process.env.REACT_APP_lawdroid_help;
	helpSpanish = process.env.REACT_APP_lawdroid_help_es;
} 

// host = process.env.REACT_APP_lawdroid_host;
// AWS_userPoolId1 = process.env.REACT_APP_lawdroid_AWS_userPoolId;
// AWS_userPoolWebClientId1 = process.env.REACT_APP_lawdroid_AWS_userPoolWebClientId;
// help = process.env.REACT_APP_lawdroid_help;
// helpSpanish = process.env.REACT_APP_lawdroid_help_es;

export const AWS_userPoolWebClientId = AWS_userPoolWebClientId1;
export const AWS_userPoolId = AWS_userPoolId1;
export const appHost = host;
export const instructions = help;
export const instructionsEs = helpSpanish;

export const AWSConfigExport = {
	region: process.env.REACT_APP_AWSConfigExport_region,
	accessKeyId: process.env.REACT_APP_AWSConfigExport_accessKeyId,
	secretAccessKey: process.env.REACT_APP_AWSConfigExport_secretAccessKey
};

export const AWS_LDV_userPoolId = process.env.REACT_APP_AWS_LDV_userPoolId;