import * as React from 'react';
import '../Login/login.scss';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { AdapterLink } from '../AdapterLink';
import SimpleSnackbar from '../SimpleSnackbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
    img_logo: {
      width: 350,
      marginBottom: 10,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 20,
    },
    menu: {
      width: 200,
    },
    button: {
      margin: theme.spacing(1),
      marginTop: 20,
    },
    input: {
      display: 'none',
    },
  }),
);

interface IValueState {
  username: string;
  code: string;
  password: string;
  errorMessages: string[];
  successMessages: string[];
  usernameHasError: boolean;
}

export const ForgotPassword: React.FC<RouteComponentProps> = props => {
  const auth = React.useContext(AuthContext);
  const classes = useStyles();
const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState<IValueState>({
    username: '',
    code: '',
    password: '',
    usernameHasError: false,
    errorMessages: [],
    successMessages: []
  });

  const { from } = props.location.state || { from: { pathname: '/' } };
 
  if (auth.hasValidSession && !auth.requireVerification) {
    return <Redirect to={from} />;
  }

  if (auth.requireVerification && !auth.hasValidSession) {
    return <Redirect to={'/verify'} />;
  }

  if (auth.resetNewPassword && !auth.hasValidSession) {
    return <Redirect to={'/reset'} />;
  }

  const validateForm = (username: string) => {
    let err: string = '';
    if (!username) {
      err = 'Please enter email.';
    }

    setValues({
      ...values,
      errorMessages: [...values.errorMessages, err],
      usernameHasError: !username
    });

    return !!username;
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.keyCode === 13) {  // if "Enter"
      e.preventDefault();
      handleForgotPwd();
    }
  };

  const handleForgotPwd = async () => {
  setLoading(true)
    try {
      const { username, password, code } = values;
      const inputIsValid = validateForm(username);

      if (inputIsValid) {
        setValues({
          ...values,
          errorMessages: [],
          successMessages: [],
          usernameHasError: false,
        });


        auth.forgotPassword && (await auth.forgotPassword(1, {username, password, code}));
        setValues({
          ...values,
          errorMessages: [],
          successMessages: ['Reset code sent in mail successfully. Please use the confirm code for reset new password.'],
        });
        props.history.push('/reset');
      }
      setLoading(false)
    } catch (error) {
    setLoading(false)
      // console.log(error);
      setValues({
        ...values,
        errorMessages: [error.message],
      });
    }
  };

  return (
    <div className="login-page">
      <div  className="login forget-password">
        {/*<img src={require('../../assets/images/lawdroid-voice-logo.png')} alt="Lawdroid Logo" className={classes.img_logo}/>*/}
        {values.errorMessages &&
          values.errorMessages.map(
            (err, i) => !!err && <div className = "alert alert-danger w-100"> {err} </div>,
          )}
        {values.successMessages &&
        values.successMessages.map(
          (success, i) => !!success && <div className = "alert alert-success w-100"> {success} </div>,
        )}
        <form className={classes.container} noValidate autoComplete="off"  onKeyDown={inputOnKeyDown}>
          <div className="forgetPwd">Forgot Password?</div>
          <label className="emailId">Email</label>
          <TextField
            error={values.usernameHasError}
            id="standard-name"
            placeholder="example@email.xyz"
            className={classes.textField}
            value={values.username}
            onChange={e => {
              setValues({ ...values, username: e.target.value });
            }}
          />
          <div className="signUpBtn mt-30">
            <Button
              onClick={handleForgotPwd}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {loading? 'Loading...' :'Send Code'}
            </Button>
          </div>
          <div className="login-secondaryActions signUp forgotBtn">
            <Button
              className={classes.button}
              component={AdapterLink}
              to="/login"
            >
              <u>GO BACK TO LOGIN</u>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
