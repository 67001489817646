import * as React from 'react';
import '../Chat/app.scss';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import { MessageContainer } from '../../Containers/MessageContainer';
import { AuthContext } from '../../contexts/AuthContext';
import Axios from 'axios';
import SimpleSnackbar from '../SimpleSnackbar';
import { DAClient } from '../../lib/DAClient';
import * as Config from '../../config';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DatePicker from "react-datepicker";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const da = new DAClient();
export const AddedInterview: React.FC = (props: any) => {
    const auth = React.useContext(AuthContext);
    const [loading, setLoading] = React.useState(false);
    const [show, setShow] = React.useState(null);
    const [startDate, setStartDate] = React.useState(new Date());
    const [newPopup, setNewPopup] = React.useState(false);
    const [newQuestion, setNewQuestion] = React.useState({
        'question': '',
        'fields': [
            {
                'variable': ''
            }
        ]
    });
    const [error, setError] = React.useState('');
    const [values, setValues] = React.useState<any>({});
    const [options, setOptions] = React.useState<any>([]);
    const [dateobj, setDateObj] = React.useState<any>({ by: '', date: moment().format('YYYY-MM-DD'), enable: false });
    const [tData, setTData] = React.useState<any>({});
    const [heading, setHeading] = React.useState(false);
    const [template, setTemplate] = React.useState(null);
    const [logic, setLogic] = React.useState(false);
    const [code, setCode] = React.useState([]);
    const [sequence, setSequence] = React.useState([]);
    const [subheading, setSubHeading] = React.useState(false);
    const [message, setMessage] = React.useState<any>([]);
    const [changedseqInterview, setChangesseqInterview] = React.useState([]);
    const listAvailInterviews = async () => {
        if (props.match.params.id) {
            let res = await Axios.get(Config.API_BASE_URL + 'getinterviewbyid/' + props.match.params.id);
            if (res.data.data) {
                let interview = res.data.data;
                setValues(interview);
            }
        } else {
            let res = await Axios.get(Config.API_BASE_URL + 'getcompletedinterview/' + auth['user']['username']);
            if (res.data.data.length > 0) {
                let interview = res.data.data[res.data.data.length - 1];
                setValues(interview);
            }
        }
        setLoading(false);
    }
    const deleteQuestion = async (i) => {
        let result = window.confirm("Do you really want to delete this question?");
        if (result) {
            let v = Object.create(values);
            v.interview[v.interview.length - 1].attachment = v.interview[v.interview.length - 1].attachment.map(att => {
                if (att.fields) {
                    att.fields = att.fields.map(at => {
                        let keyname = Object.keys(at)[0];
                        if (at[keyname].includes(v.interview[i]['fields'][0]['variable'])) {
                            at[keyname] = '""\n';
                        }
                        return at;
                    })
                }
                return att;
            });
            v.interview.splice(i, 1);
            v.interview[1].code.splice(i - 2, 1);
            console.log(v.interview[v.interview.length - 1].attachment);
            await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: v._id, data: { interview: v.interview } });
            setValues(v);
            setSubHeading(false);
            uploadInterview();
        }
    }
    const openQuestionPopup = () => {
        setNewQuestion({
            'question': '',
            'fields': [
                {
                    'variable': ''
                }
            ]
        });
        setOptions([]);
        setDateObj({ by: '', date: moment().format('YYYY-MM-DD'), enable: false });
        setNewPopup(true);
    }
    const editQuestionText = (value) => {
        let obj = newQuestion;
        obj['question'] = value;
        setNewQuestion(obj);
    }
    const editVariableText = (value) => {
        let obj = newQuestion;
        obj['fields'][0]['variable'] = value;
        setNewQuestion(obj);
    }
    const submit = async () => {
        await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: values._id, data: { interview: values.interview } });
        props.history.push(`/upload-template/${values._id}`, { success: true });
    }
    const editHeading = async () => {
        let value: any = document.getElementById('headingtext');
        value = value.value;
        if (!value) {
            return setError('Heading can not be empty.');
        }
        let v = Object.create(values);
        v.interview[0].metadata['title'] = value;
        await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: v._id, data: { interview: v.interview } });
        setValues(v);
        setHeading(false);
        uploadInterview();
    }
    const editSubHeading = async () => {
        let value: any = document.getElementById('subheadingtext');
        value = value.value;
        if (!value) {
            return setError('Heading can not be empty.');
        }
        let v = Object.create(values);
        v.interview[0].metadata['comment'] = value;
        await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: v._id, data: { interview: v.interview } });
        setValues(v);
        setSubHeading(false);
        uploadInterview();
    }
    const addQuestion = async () => {
        let v = Object.create(values);
        if (newQuestion.question && newQuestion.fields[0].variable) {
            let already = v.interview.filter((el, index) => {
                if (index > 1 && index !== (v.interview.length - 1) && newQuestion.fields[0].variable == el['fields'][0]['variable']) {
                    return true;
                }
                return false;
            });
            if (already.length > 0) {
                setError('Variable name is already used.');
                return false;
            } else {
                let code = [];
                v.interview[1].code.forEach((el, ind) => {
                    code.push(el);
                    if (ind == v.interview[1].code.length - 2) {
                        code.push(newQuestion.fields[0].variable);
                    }
                });
                v.interview[1].code = code;


                let dvalue: any = document.getElementById('questiontype');
                dvalue = dvalue.value;
                if (dvalue == 'radio') {
                    let te = options.filter(op => op == '');
                    if (te.length > 0) {
                        return setError('Choices can not be empty.');
                    }
                }
                let newques = JSON.parse(JSON.stringify(newQuestion));
                newques.question = newques.question + "\n";
                if (dvalue == 'text') {
                    delete newques['fields'][0]['input type'];
                    delete newques['fields'][0]['datatype'];
                    delete newques['fields'][0]['choices'];
                    delete newques['fields'][0]['help'];
                    delete newques['fields'][0]['default'];
                    delete newques['fields'][0]['hint'];
                } else if (dvalue == 'yesno') {
                    delete newques['fields'][0]['input type'];
                    delete newques['fields'][0]['choices'];
                    delete newques['fields'][0]['help'];
                    delete newques['fields'][0]['default'];
                    delete newques['fields'][0]['hint'];
                    newques['fields'][0]['datatype'] = 'yesno';
                } else if (dvalue == 'date') {
                    delete newques['fields'][0]['input type'];
                    delete newques['fields'][0]['choices'];
                    if (dateobj.by !== '') {
                        newques['fields'][0]['hint'] = dateobj.by;
                        newques['fields'][0]['default'] = dateobj.date;
                    } else {
                        delete newques['fields'][0]['hint'];
                        delete newques['fields'][0]['default'];
                    }
                    newques['fields'][0]['datatype'] = 'date';
                    newques['fields'][0]['help'] = "speechengine";
                } else if (dvalue == 'email') {
                    delete newques['fields'][0]['input type'];
                    delete newques['fields'][0]['choices'];
                    delete newques['fields'][0]['default'];
                    delete newques['fields'][0]['hint'];
                    newques['fields'][0]['datatype'] = 'email';
                    newques['fields'][0]['help'] = "speechengine";
                } else if (dvalue == 'phone') {
                    delete newques['fields'][0]['input type'];
                    delete newques['fields'][0]['choices'];
                    delete newques['fields'][0]['help'];
                    newques['fields'][0]['datatype'] = 'integer';
                    newques['fields'][0]['hint'] = 'bothlength';
                    newques['fields'][0]['default'] = 10;
                } else if (dvalue == 'integer') {
                    delete newques['fields'][0]['input type'];
                    delete newques['fields'][0]['choices'];
                    delete newques['fields'][0]['help'];
                    delete newques['fields'][0]['default'];
                    delete newques['fields'][0]['hint'];
                    newques['fields'][0]['datatype'] = 'integer';
                } else if (dvalue == 'radio') {
                    delete newques['fields'][0]['input type'];
                    delete newques['fields'][0]['help'];
                    delete newques['fields'][0]['default'];
                    delete newques['fields'][0]['hint'];
                    newques['fields'][0]['datatype'] = 'radio';
                    newques['fields'][0]['choices'] = options;
                }

                let val = [];
                v.interview.forEach((el, ind) => {
                    val.push(el);
                    if (ind == v.interview.length - 2) {
                        val.push(newques);
                    }
                });
                v.interview = val;
                await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: v._id, data: { interview: v.interview } });
                setValues(v);
                setHeading(false);
                setNewPopup(false);
                uploadInterview();
            }
        } else {
            setError('Fields can not be empty.');
        }
    }
    const editQuestion = async () => {
        let value: any = document.getElementById('changedquestion');
        value = value.value;
        let dvalue: any = document.getElementById('questiontype');
        dvalue = dvalue.value;
        if (!value) {
            return setError('Question text can not be empty.');
        }
        if (dvalue == 'radio') {
            let te = options.filter(op => op == '');
            if (te.length > 0) {
                return setError('Choices can not be empty.');
            }
        }
        let active = show;
        let v = Object.create(values);
        v.interview[show].question = value + "\n";
        let obj = v.interview;
        if (dvalue == 'text') {
            delete obj[active]['fields'][0]['input type'];
            delete obj[active]['fields'][0]['datatype'];
            delete obj[active]['fields'][0]['choices'];
            delete obj[active]['fields'][0]['help'];
            delete obj[active]['fields'][0]['default'];
            delete obj[active]['fields'][0]['hint'];
        } else if (dvalue == 'yesno') {
            delete obj[active]['fields'][0]['input type'];
            delete obj[active]['fields'][0]['choices'];
            delete obj[active]['fields'][0]['help'];
            delete obj[active]['fields'][0]['default'];
            delete obj[active]['fields'][0]['hint'];
            obj[active + 2]['fields'][0]['datatype'] = 'yesno';
        } else if (dvalue == 'date') {
            delete obj[active]['fields'][0]['input type'];
            delete obj[active]['fields'][0]['choices'];
            if (dateobj.by !== '') {
                obj[active]['fields'][0]['hint'] = dateobj.by;
                obj[active]['fields'][0]['default'] = dateobj.date;
            } else {
                delete obj[active]['fields'][0]['hint'];
                delete obj[active]['fields'][0]['default'];
            }
            obj[active]['fields'][0]['datatype'] = 'date';
            obj[active]['fields'][0]['help'] = "speechengine";
        } else if (dvalue == 'email') {
            delete obj[active]['fields'][0]['input type'];
            delete obj[active]['fields'][0]['choices'];
            delete obj[active]['fields'][0]['default'];
            delete obj[active]['fields'][0]['hint'];
            obj[active]['fields'][0]['datatype'] = 'email';
            obj[active]['fields'][0]['help'] = "speechengine";
        } else if (dvalue == 'phone') {
            delete obj[active]['fields'][0]['input type'];
            delete obj[active]['fields'][0]['choices'];
            delete obj[active]['fields'][0]['help'];
            obj[active]['fields'][0]['datatype'] = 'integer';
            obj[active]['fields'][0]['hint'] = 'bothlength';
            obj[active]['fields'][0]['default'] = 10;
        } else if (dvalue == 'integer') {
            delete obj[active]['fields'][0]['input type'];
            delete obj[active]['fields'][0]['choices'];
            delete obj[active]['fields'][0]['help'];
            delete obj[active]['fields'][0]['default'];
            delete obj[active]['fields'][0]['hint'];
            obj[active + 2]['fields'][0]['datatype'] = 'integer';
        } else if (dvalue == 'radio') {
            delete obj[active]['fields'][0]['input type'];
            delete obj[active]['fields'][0]['help'];
            delete obj[active]['fields'][0]['default'];
            delete obj[active]['fields'][0]['hint'];
            obj[active]['fields'][0]['datatype'] = 'radio';
            obj[active]['fields'][0]['choices'] = options;
        }
        v.interview = obj;
        await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: v._id, data: { interview: v.interview } });
        setValues(v);
        setShow(null);
        uploadInterview();
    }
    const openModal = (index) => {
        if (values.interview[index].fields[0].datatype == 'radio') {
            setOptions(values.interview[index].fields[0].choices);
        } else {
            setOptions([]);
        }
        if (values.interview[index].fields[0].datatype == 'date') {
            if (values.interview[index].fields[0].hint) {
                setStartDate(moment(values.interview[index].fields[0].default, 'YYYY-MM-DD').toDate());
                setDateObj({ by: values.interview[index].fields[0].hint, date: values.interview[index].fields[0].default, enable: true });
            } else {
                setDateObj({ by: '', date: moment().format('YYYY-MM-DD'), enable: true });
            }
        } else {
            setDateObj({ by: '', date: moment().format('YYYY-MM-DD'), enable: false });
        }
        setShow(index);
        setError(null);
    }
    const onChoiceChange = (e, i) => {
        setError('');
        let opt = options;
        opt[i] = e.target.value;
        setOptions(opt);
    }
    const onTypeChange = (e) => {
        if (e.target.value == 'radio') {
            setOptions(['']);
        } else {
            setOptions([]);
        }
        if (e.target.value == 'date') {
            setDateObj({ by: '', date: moment().format('YYYY-MM-DD'), enable: true });
        } else {
            setDateObj({ by: '', date: moment().format('YYYY-MM-DD'), enable: false });
        }
    }
    const newChoice = () => {
        let opt = JSON.parse(JSON.stringify(options));
        opt.push('');
        setOptions(opt);
    }
    const removeChoice = (i) => {
        let opt = JSON.parse(JSON.stringify(options));
        opt.splice(i, 1);
        setOptions(opt);
    }
    const uploadInterview = async () => {
        if (props.match.params.id) {
            setLoading(true);
            try {
                await Axios.post(Config.API_BASE_URL + 'yaml/' + values._id, { apiKey: Config.API_KEY, baseUrl: Config.DA_API_BASE_URL });
                setLoading(false);
                toast.success('Interview Updated Successfully.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            } catch (er) {
                setLoading(false);
                toast.error('Something went wrong, please try again later.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }
        }
    }
    const changeFieldValue = (index, key, value) => {
        let data = tData;
        data['fields'][index][key] = '${' + value + '}\n';
        setTData(data);
    }
    const editTemplate = async () => {
        let v = JSON.parse(JSON.stringify(values));
        v.interview[v.interview.length - 1].attachment[template] = tData;
        await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: v._id, data: { interview: v.interview } });
        setValues(v);
        setTemplate(null);
        uploadInterview();
    }

    const deleteTemplate = async (index) => {
        if (window.confirm('Are you sure you want to remove this template ?')) {
            let v = JSON.parse(JSON.stringify(values));
            v.interview[v.interview.length - 1].attachment.splice(index, 1);
            await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: v._id, data: { interview: v.interview } });
            setValues(v);
            uploadInterview();
        }
    }

    const setSequenceArray = () => {
        let logic = values.interview[1].code;
        let sequence = logic.map(el => {
            let filt = values.interview.filter((e, ind) => {
                if (ind > 1 && !e.sets) {
                    if (el.includes(`${e.fields[0].variable}=''`) || el == e.fields[0].variable) {
                        return true;
                    }
                }
                return false;
            });
            return filt;
        }).filter(e => e.length > 0).map((e, i) => {
            return { field: e[0].fields[0].variable, showif: checkShowIf(logic, e[0].fields[0].variable, i), other: checkOther(logic, e[0].fields[0].variable, i) }
        });
        setSequence(sequence);
        setCode(logic);
        setChangesseqInterview(values.interview);
        setLogic(true);
    }
    const checkOther = (array, variable, i) => {
        if (array[i] == variable) {
            return false;
        }
        let str = array[i].split(' ');
        if (str[3].includes('True') || str[3].includes('False')) {
            return false
        }
        return true;
    }
    const checkShowIf = (array, variable, i) => {
        if (array[i] == variable) {
            return false;
        }
        return true;
    }
    const getOtherValue = (i) => {
        let array = code;
        let str = array[i].split(' ');
        str = str[3].split(':')[0].replace("'", '');
        return str.replace("'", '');
    }
    const showIfChange = (i) => {
        let seq = JSON.parse(JSON.stringify(sequence));
        let co = JSON.parse(JSON.stringify(code));
        if (seq[i].showif) {
            seq[i].showif = false;
            co[i] = sequence[i]['field'];
        } else {
            seq[i].showif = true;
            co[i] = `if ${'name'} == ${'True'}:\n ${sequence[i]['field']}\nelse:\n ${sequence[i]['field']}=''`;
        }
        setSequence(seq);
        setCode(co);
    }
    const logicChange = (i) => {
        let co = JSON.parse(JSON.stringify(code));
        let seq = JSON.parse(JSON.stringify(sequence));
        let variable: any = document.getElementById('vname' + i);
        variable = variable.selectedOptions[0].value;
        let toValue: any = document.getElementById('vvalue' + i);
        toValue = toValue.selectedOptions[0].value;
        toValue = toValue == 'false' ? 'False' : toValue == 'true' ? 'True' : 'other';
        co[i] = `if ${variable} == ${toValue}:\n ${sequence[i]['field']}\nelse:\n ${sequence[i]['field']}=''`;
        if (toValue == 'other') {
            co[i] = `if ${variable} == '${toValue}':\n ${sequence[i]['field']}\nelse:\n ${sequence[i]['field']}=''`;
            seq[i]['other'] = true;
        } else {
            seq[i]['other'] = false;
        }
        setCode(co);
        setSequence(seq);
    }
    const otherTextChange = (value, i) => {
        let co = JSON.parse(JSON.stringify(code));
        let variable: any = document.getElementById('vname' + i);
        variable = variable.selectedOptions[0].value;
        co[i] = `if ${variable} == '${value}':\n ${sequence[i]['field']}\nelse:\n ${sequence[i]['field']}=''`;
        setCode(co);
    }
    const submitLogic = async () => {
        let v = JSON.parse(JSON.stringify(values));
        v.interview = changedseqInterview;
        v.interview[1].code = code;
        await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: v._id, data: { interview: v.interview } });
        setValues(v);
        setLogic(false);
        uploadInterview();
    }
    const up = (i) => {
        let seq = JSON.parse(JSON.stringify(sequence));
        let co = JSON.parse(JSON.stringify(code));
        let fullArray = JSON.parse(JSON.stringify(changedseqInterview))
        let current = seq[i];
        let neigh = seq[i - 1];
        let currentc = co[i];
        let neighc = co[i - 1];
        let currentf = fullArray[i + 2];
        let neighf = fullArray[i + 1];
        seq[i] = neigh;
        seq[i - 1] = current;
        co[i] = neighc;
        co[i - 1] = currentc;
        fullArray[i + 2] = neighf;
        fullArray[i + 1] = currentf;
        setSequence(seq);
        setCode(co);
        setChangesseqInterview(fullArray);
    }
    const down = (i) => {
        let seq = JSON.parse(JSON.stringify(sequence));
        let co = JSON.parse(JSON.stringify(code));
        let fullArray = JSON.parse(JSON.stringify(changedseqInterview))
        let current = seq[i];
        let neigh = seq[i + 1];
        let currentc = co[i];
        let neighc = co[i + 1];
        let currentf = fullArray[i + 2];
        let neighf = fullArray[i + 3];
        seq[i] = neigh;
        seq[i + 1] = current;
        co[i] = neighc;
        co[i + 1] = currentc;
        fullArray[i + 2] = neighf;
        fullArray[i + 3] = currentf;
        setSequence(seq);
        setCode(co);
        setChangesseqInterview(fullArray);
    }
    const getVariable = (i) => {
        let co = JSON.parse(JSON.stringify(code));
        let str = co[i].split(' ');
        return str[1];
    }
    const getLogicValue = (i) => {
        let co = JSON.parse(JSON.stringify(code));
        let str = co[i].split(' ');
        if (str[3].includes('True')) {
            return 'true'
        } else if (str[3].includes('False')) {
            return 'false'
        } else {
            return 'other'
        }
    }
    React.useEffect(() => {
        setLoading(true);
        listAvailInterviews();
    }, [auth]);
    return (
        <MessageContainer render={({ messages, setMessages, resetSession, useOldSession }) => (
            <>
                <div>
                    {loading ? <div className="loader-cont">
                        <div className="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div> : null}
                    <Header resetSession={resetSession} />
                    <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                    <ToastContainer />
                    {message.map(
                        (err, i) =>
                            <SimpleSnackbar key={i} message={err} />,
                    )}
                    <Modal show={logic} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Interview Logic</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ background: 'white' }}>
                            <div className="row">
                                <div className="col-3" style={{ borderRight: '1px solid black' }}>
                                    {code.map(e => {
                                        return (
                                            <li style={{ listStyleType: 'none', fontWeight: 'bold', fontSize: 14 }} dangerouslySetInnerHTML={{ __html: e.replace(/:/g, ':<br />&nbsp;&nbsp;&nbsp;&nbsp;').replace(/else/g, '<br />else') }}></li>
                                        )
                                    })}
                                </div>
                                <div className="col-9">
                                    {sequence.map((e, i) => {
                                        if (i > 1) {
                                            return (
                                                <div className="row" style={{ padding: '20px 0', borderBottom: '1px solid black' }}>
                                                    <div className="col-2">
                                                        {e.field}
                                                    </div>
                                                    <div className="col-2">
                                                        Show If <input type="checkbox" checked={e.showif} onChange={(e) => showIfChange(i)} />
                                                    </div>
                                                    {e.showif ? <div className="col-3 mb-3">
                                                        <select onChange={(e) => logicChange(i)} id={'vname' + i} value={getVariable(i)} className="form-control">
                                                            {sequence.map((se, ind) => {
                                                                if (ind !== i) {
                                                                    return (
                                                                        <option value={se.field}>{se.field}</option>
                                                                    )
                                                                } else {
                                                                    return null
                                                                }
                                                            })}
                                                        </select>
                                                    </div> : null}
                                                    {e.showif ? <div className="col-3 mb-3">
                                                        <select value={getLogicValue(i)} onChange={(e) => logicChange(i)} id={'vvalue' + i} className="form-control">
                                                            <option selected value="true">Yes</option>
                                                            <option value="false">No</option>
                                                            <option value="other">Other</option>
                                                        </select>
                                                    </div> : null}
                                                    {e.other ? <div className="col-3 mb-3">
                                                        <input onChange={(e) => otherTextChange(e.target.value, i)} type="text" className="form-control" />
                                                    </div> : null}
                                                    <div className="col-2">
                                                        {i < sequence.length - 1 ? <a onClick={() => down(i)}> <i _ngcontent-jlq-c19="" className="material-icons mr-2" style={{ borderColor: 'transparent' }}>arrow_circle_down</i></a> : null}
                                                        {i > 2 ? <a onClick={() => up(i)}><i _ngcontent-jlq-c19="" className="material-icons" style={{ borderColor: 'transparent' }}>arrow_circle_up</i></a> : null}
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <a href="javascript:void(0)" onClick={() => submitLogic()} className="btn btn-primary text-white">
                                Done
                                        </a>
                            <a href="javascript:void(0)" onClick={() => setLogic(false)} className="btn btn-primary text-white">
                                Close
                                        </a>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={heading}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Interview Name</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ background: 'white' }}>
                            <input type="text" onChange={(e) => setError('')} id="headingtext" className="form-control" defaultValue={values.interview && values.interview[0] ? values.interview[0].metadata['title'] : null} /><br />
                            {error ? <span style={{ color: 'red', fontSize: 13 }}>{error}</span> : null}
                        </Modal.Body>
                        <Modal.Footer>
                            <a href="javascript:void(0)" onClick={() => editHeading()} className="btn btn-primary text-white">
                                Done
                                        </a>
                            <a href="javascript:void(0)" onClick={() => setHeading(false)} className="btn btn-primary text-white">
                                Close
                                        </a>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={template !== null}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Template Fields</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ background: 'white' }}>
                            {tData.fields && tData.fields.map((e, i) => {
                                let keyNames = Object.keys(e);
                                return (
                                    <div className="row">
                                        <div className="col-6">
                                            {keyNames[0]}
                                        </div>
                                        <div className="col-6">
                                            <select onChange={(e) => changeFieldValue(i, keyNames[0], e.target.value)} id="questiontype" defaultValue={e[keyNames[0]]} className="form-control w-100 mb-3">
                                                {values.interview.map((el: any, ind: any) => {
                                                    if (ind > 1 && !el.sets) {
                                                        return (
                                                            <option value={el.fields[0].variable}>{el.fields[0].variable}</option>
                                                        )
                                                    }
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                )
                            })}
                        </Modal.Body>
                        <Modal.Footer>
                            <a href="javascript:void(0)" onClick={() => editTemplate()} className="btn btn-primary text-white">
                                Done
                                        </a>
                            <a href="javascript:void(0)" onClick={() => { setTemplate(null) }} className="btn btn-primary text-white">
                                Close
                                        </a>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={subheading}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Description</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ background: 'white' }}>
                            <textarea onChange={(e) => setError('')} id="subheadingtext" className="form-control" defaultValue={values.interview && values.interview[0] ? values.interview[0].metadata['comment'] : null} />
                            <br />
                            {error ? <span style={{ color: 'red', fontSize: 13 }}>{error}</span> : null}
                        </Modal.Body>
                        <Modal.Footer>
                            <a href="javascript:void(0)" onClick={() => editSubHeading()} className="btn btn-primary text-white">
                                Done
                                        </a>
                            <a href="javascript:void(0)" onClick={() => setSubHeading(false)} className="btn btn-primary text-white">
                                Close
                                        </a>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={newPopup}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add New Question</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={options.length > 0 ? { background: 'white' } : { background: 'white', overflow: 'visible' }}>
                            <input type="text" placeholder="Question" onChange={(e) => { setError(''); editQuestionText(e.target.value) }} className="form-control" /><br />
                            <input type="text" placeholder="Variable Name" onChange={(e) => { setError(''); editVariableText(e.target.value) }} className="form-control" /><br />


                            <div className="row">
                                <div className="w-100">
                                    <div className="form-group">
                                        <label>Datatype</label>
                                        <select id="questiontype" defaultValue={'text'} onChange={(e) => onTypeChange(e)} className="form-control w-100 mb-3">
                                            <option value='text'>Text</option>
                                            <option value='date'>Date</option>
                                            <option value='integer'>Integer</option>
                                            <option value='radio'>Radio</option>
                                            <option value='yesno'>Boolean</option>
                                            <option value='email'>Email</option>
                                            <option value='phone'>Phone</option>
                                        </select>
                                    </div>
                                </div>
                                {options.length > 0 ?
                                    <div className="w-100">
                                        <div className="form-group">
                                            <label>Choices</label>

                                            {options.map((e, ci) => {
                                                return (
                                                    <div className="w-100 d-flex align-items-start">
                                                        <input onChange={(ev) => onChoiceChange(ev, ci)} type="text" className="form-control mb-2" defaultValue={e} />
                                                        {options.length > 1 ? <div className="d-flex align-items-start mt-2 pb-3">
                                                            <a onClick={() => removeChoice(ci)}><i className="material-icons">delete</i> </a>
                                                        </div> : null}
                                                    </div>
                                                )
                                            })}

                                            <input type="button" className="btn btn-primary text-white" value="Add" onClick={() => newChoice()} />
                                        </div> </div> : null}
                                {dateobj.enable ? <div className="col-md-12 ">
                                    <div className="form-group">
                                        <label>Add Hint:</label>
                                        <div className="row">
                                            <div>
                                                <RadioGroup onChange={(ev) => {
                                                    let d = JSON.parse(JSON.stringify(dateobj));
                                                    d['by'] = ev.target.value;
                                                    setDateObj(d);
                                                }} value={dateobj.by ? dateobj.by : false} aria-label="gender" name="gender1" row>
                                                    <FormControlLabel
                                                        value="before"
                                                        control={<Radio color="primary"
                                                        />}
                                                        label="before" />
                                                    <FormControlLabel value="after" control={<Radio
                                                        color="primary" />} label="after" />
                                                </RadioGroup>
                                            </div>

                                            <div className="datepicui" style={{ position: 'relative' }}>

                                                <DatePicker className="form-control" selected={startDate} onChange={date => {
                                                    setStartDate(date);
                                                    let d = JSON.parse(JSON.stringify(dateobj));
                                                    d['date'] = moment(date).format('YYYY-MM-DD');
                                                    setDateObj(d);
                                                }} />
                                                {dateobj.by ? <a className="del-icon" onClick={() => setDateObj({ by: '', date: moment().format('YYYY-MM-DD'), enable: true })} > <i className="material-icons">delete</i> </a> : null}
                                            </div> </div></div> </div> : null}
                            </div>


                            {error ? <span style={{ color: 'red', fontSize: 13 }}>{error}</span> : null}
                        </Modal.Body>
                        <Modal.Footer>
                            <a href="javascript:void(0)" onClick={() => addQuestion()} className="btn btn-primary text-white">
                                Done
                                        </a>
                            <a href="javascript:void(0)" onClick={() => setNewPopup(false)} className="btn btn-primary text-white">
                                Close
                                        </a>
                        </Modal.Footer>
                    </Modal>
                    <div className="app-main">
                        <div className="app-main-inner">
                            <Container>
                                <div className="table-responsive addinterview_ addnewtempinterview_">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="interviewHeading">
                                                    <div className="row d-flex flex-wrap justify-content-center align-items-stretch py-2">
                                                        <div className="col-md-12 col-xs-12">
                                                            {values.interview ? <div>
                                                                <h2>{values.interview[0].metadata.title}</h2>{!props.match.params.id ? <a href="#" onClick={() => setHeading(true)} > Edit </a> : null}
                                                            </div> : null}
                                                            {values.interview ? <p>{values.interview[0].metadata['comment']}<a href="#" onClick={() => setSubHeading(true)} > Edit </a></p> : null}
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="interviewHeading tbldata">
                                                    <div className="row mt-3">
                                                        <div className="row">
                                                            <div className={values.interview && (!props.match.params.id || !values.interview[values.interview.length - 1].sets) ? "col-md-0 col-sm-0" : "col-md-8 col-sm-12"}>
                                                                <div className="row">
                                                                    {values.interview && values.interview[values.interview.length - 1].sets ? values.interview[values.interview.length - 1].attachment.map(function (result, index) {
                                                                        return (
                                                                            <div className="col-md-4 col-sm-12">
                                                                                <div>
                                                                                    <h2>Template {index + 1} {result['pdf template file'] ? <a href="#" title="Edit Template" onClick={() => { setTemplate(index); setTData(result) }}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7399 6.33966C20.0865 6.68628 20.0865 7.2462 19.7399 7.59281L18.1135 9.21925L14.7806 5.88639L16.407 4.25995C16.5731 4.09353 16.7985 4 17.0336 4C17.2687 4 17.4941 4.09353 17.6602 4.25995L19.7399 6.33966ZM4 19.5556V16.8538C4 16.7293 4.04444 16.6227 4.13331 16.5338L13.8297 6.83738L17.1626 10.1702L7.45729 19.8667C7.3773 19.9555 7.26176 20 7.14622 20H4.44438C4.19553 20 4 19.8044 4 19.5556Z" fill="#848584" />
                                                                                    </svg>
                                                                                    </a> : null}
                                                                                        {values.interview[values.interview.length - 1].attachment.length > 1 ? <a onClick={() => { deleteTemplate(index) }} title="Delete Template" className="mr-2 text-white"><i _ngcontent-jlq-c19=""><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.79 3.29L15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9.17 11.17C9.56 10.78 10.19 10.78 10.58 11.17L12 12.59L13.42 11.17C13.81 10.78 14.44 10.78 14.83 11.17C15.22 11.56 15.22 12.19 14.83 12.58L13.41 14L14.83 15.42C15.22 15.81 15.22 16.44 14.83 16.83C14.44 17.22 13.81 17.22 13.42 16.83L12 15.41L10.58 16.83C10.19 17.22 9.56 17.22 9.17 16.83C8.98275 16.6432 8.87751 16.3895 8.87751 16.125C8.87751 15.8605 8.98275 15.6068 9.17 15.42L10.59 14L9.17 12.58C8.78 12.2 8.78 11.56 9.17 11.17Z" fill="#848584" />
                                                                                        </svg>
                                                                                        </i></a> : null}
                                                                                    </h2>
                                                                                </div>
                                                                                <p className=" ">{result['pdf template file'] ? result['pdf template file'] : result['docx template file']} </p>
                                                                            </div>
                                                                        )
                                                                    }) : null}
                                                                </div>
                                                            </div>
                                                            <div className={values.interview && (!props.match.params.id || !values.interview[values.interview.length - 1].sets) ? "btn_spc col-md-12 col-sm-12" : "btn_spc col-md-4 col-sm-12 text-right"}>
                                                                {values.interview && (!props.match.params.id || !values.interview[values.interview.length - 1].sets) ? <a title="Continue" onClick={() => submit()} href='javascript:void(0)' className="btn btn-outline mt-2">Continue (Add template)</a> : <a title="Continue" onClick={() => {
                                                                    props.history.push(`/add-new/${values._id}`, { success: true })
                                                                }} href='javascript:void(0)' className="btn btn-outline mt-2">Add template</a>}
                                                                {values.interview ? <a onClick={() => setSequenceArray()} href='javascript:void(0)' className="btn btn-outline mt-2">Edit Interview Logic</a> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row mt-5 ">
                                                        <div className="col-md-12 col-xs-12 d-flex justify-content-between int_que_title">
                                                            <h4>Questions</h4>
                                                            <a href='javascript:void(0)' onClick={() => openQuestionPopup()} className="btn btn-outline">+ Add Question</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row mt-3">
                                                        <div className="col-12">
                                                            {values.interview ? values.interview.map(function (result, index) {
                                                                if (index > 3 && !result.sets) {
                                                                    if (show == index) {
                                                                        return (
                                                                            <div >
                                                                                <div className="d-flex align-items-end justify-content-end mt-3 pt-3 ">
                                                                                    <a title="Done" onClick={() => editQuestion()} href='javascript:void(0)' className="btn btn-outline">Done</a>&nbsp; &nbsp;
                                                                                    <a title="Cancel" onClick={() => setShow(null)} href='javascript:void(0)' className="btn btn-outline" style={{ borderColor: '#333', color: '#333' }}>Cancel</a>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="sequence text-center h-100">
                                                                                        <div>{index - 3}.</div>
                                                                                        <div className="deletenewint">
                                                                                            {values.interview.length - index - 4 > 1 ? <a href="javascript:void(0)"><svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.78902 0.290092L10.499 1.00009H12.999C13.549 1.00009 13.999 1.45009 13.999 2.00009C13.999 2.55009 13.549 3.00009 12.999 3.00009H0.999023C0.449023 3.00009 -0.000976562 2.55009 -0.000976562 2.00009C-0.000976562 1.45009 0.449023 1.00009 0.999023 1.00009H3.49902L4.20902 0.290092C4.38902 0.110092 4.64902 9.15527e-05 4.90902 9.15527e-05H9.08902C9.34902 9.15527e-05 9.60902 0.110092 9.78902 0.290092ZM2.99902 18.0001C1.89902 18.0001 0.999023 17.1001 0.999023 16.0001V6.00009C0.999023 4.90009 1.89902 4.00009 2.99902 4.00009H10.999C12.099 4.00009 12.999 4.90009 12.999 6.00009V16.0001C12.999 17.1001 12.099 18.0001 10.999 18.0001H2.99902Z" fill="black" fill-opacity="0.54" />
                                                                                            </svg></a> : null}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="addInterview">
                                                                                        <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <form action="" className="addnewint">
                                                                                                    <div className="form-group">
                                                                                                        <label>Title</label>
                                                                                                        <input type="text" onChange={(e) => setError('')} id="changedquestion" required className="form-control" defaultValue={values.interview && values.interview[show] ? values.interview[show].question : null} />
                                                                                                    </div>
                                                                                                </form>
                                                                                            </div>
                                                                                            <div className="col-md-12 addnewint">
                                                                                                <div className="form-group">
                                                                                                    <label>Datatype</label>
                                                                                                    <select id="questiontype" defaultValue={values.interview && values.interview[show] ? values.interview[show].fields[0].datatype : null} onChange={(e) => onTypeChange(e)} className="form-control w-100 mb-3">
                                                                                                        <option value='text'>Text</option>
                                                                                                        <option value='date'>Date</option>
                                                                                                        <option value='integer'>Integer</option>
                                                                                                        <option value='radio'>Radio</option>
                                                                                                        <option value='yesno'>Boolean</option>
                                                                                                        <option value='email'>Email</option>
                                                                                                        <option value='phone'>Phone</option>
                                                                                                    </select>
                                                                                                    {/* <a title="" className="btn btn-primary text-white w-100 mb-3">Add Help Text</a> */}
                                                                                                    {/* {active > 2 ? <a onClick={()=> remove()} title="" className="text-danger float-right w-100 text-right">Remove</a> : null} */}
                                                                                                </div>
                                                                                            </div>
                                                                                            {options.length > 0 ?
                                                                                                <div className="col-md-12 addnewint">
                                                                                                    <div className="form-group">
                                                                                                        <label>Choices</label>

                                                                                                        {options.map((e, ci) => {
                                                                                                            return (
                                                                                                                <div className="d-flex align-items-start">
                                                                                                                    <input onChange={(ev) => onChoiceChange(ev, ci)} type="text" className="form-control mb-2" defaultValue={e} />
                                                                                                                    {options.length > 1 ? <div className="d-flex align-items-start mt-2 pb-3">
                                                                                                                        <a onClick={() => removeChoice(ci)}><i className="material-icons">delete</i> </a>
                                                                                                                    </div> : null}
                                                                                                                </div>
                                                                                                            )
                                                                                                        })}

                                                                                                        <input type="button" className="btn btn-primary text-white" value="Add" onClick={() => newChoice()} />
                                                                                                    </div> </div> : null}
                                                                                            {dateobj.enable ? <div className="col-md-12 ">
                                                                                                <div className="form-group">
                                                                                                    <label>Add Hint:</label>
                                                                                                    <div className="row">
                                                                                                        <div>
                                                                                                            <RadioGroup onChange={(ev) => {
                                                                                                                let d = JSON.parse(JSON.stringify(dateobj));
                                                                                                                d['by'] = ev.target.value;
                                                                                                                setDateObj(d);
                                                                                                            }} value={dateobj.by ? dateobj.by : false} aria-label="gender" name="gender1" row>
                                                                                                                <FormControlLabel
                                                                                                                    value="before"
                                                                                                                    control={<Radio color="primary"
                                                                                                                    />}
                                                                                                                    label="before" />
                                                                                                                <FormControlLabel value="after" control={<Radio
                                                                                                                    color="primary" />} label="after" />
                                                                                                            </RadioGroup>
                                                                                                        </div>

                                                                                                        <div className="datepicui">

                                                                                                            <DatePicker className="form-control" selected={startDate} onChange={date => {
                                                                                                                setStartDate(date);
                                                                                                                let d = JSON.parse(JSON.stringify(dateobj));
                                                                                                                d['date'] = moment(date).format('YYYY-MM-DD');
                                                                                                                setDateObj(d);
                                                                                                            }} />


                                                                                                            {dateobj.by ? <a className="del-icon" onClick={() => setDateObj({ by: '', date: moment().format('YYYY-MM-DD'), enable: true })} > <i className="material-icons">delete</i> </a> : null}
                                                                                                        </div> </div></div> </div> : null}
                                                                                        </div>
                                                                                        {error ? <span style={{ color: 'red', fontSize: 13 }}>{error}</span> : null}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    }
                                                                    return (
                                                                        <div className="d-flex align-items-start mb-3 pb-3 border-bottom">
                                                                            <span className="d-flex flex-wrap justify-content-center align-items-center pr-3 srno">{index - 3}.</span>
                                                                            <div className="d-flex flex-column flex-grow-1 mr-2">
                                                                                <span className="question_title"> {result.question}</span>
                                                                                <span className="question_short_title">
                                                                                    <span className="text-light">Variable: </span>
                                                                                    <span className="text-dark mr-5">{result.fields[0].variable} </span>
                                                                                    <span className="text-light">Datatype: </span>
                                                                                    <span className="text-dark">{result.fields[0].datatype ? result.fields[0].datatype : 'text'}</span>
                                                                                </span>
                                                                                {result.fields[0].datatype && result.fields[0].datatype == 'radio' ? <div><span className="question_short_title">
                                                                                    <span className="text-light">Radio Options:</span>
                                                                                    {result.fields[0].choices.map((c, cin) => {
                                                                                        return (
                                                                                            <div className=" ">
                                                                                                {cin + 1}. &nbsp;{c}<br />
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                </span></div> : null}
                                                                            </div>
                                                                            <span className="mt-2">
                                                                                <a title="Edit Question" onClick={() => openModal(index)} className="mr-2 text-white"><i _ngcontent-jlq-c19=""><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7399 6.33966C20.0865 6.68628 20.0865 7.2462 19.7399 7.59281L18.1135 9.21925L14.7806 5.88639L16.407 4.25995C16.5731 4.09353 16.7985 4 17.0336 4C17.2687 4 17.4941 4.09353 17.6602 4.25995L19.7399 6.33966ZM4 19.5556V16.8538C4 16.7293 4.04444 16.6227 4.13331 16.5338L13.8297 6.83738L17.1626 10.1702L7.45729 19.8667C7.3773 19.9555 7.26176 20 7.14622 20H4.44438C4.19553 20 4 19.8044 4 19.5556Z" fill="#848584" />
                                                                                </svg></i></a>
                                                                                <a onClick={() => deleteQuestion(index)} title="Delete Question" className="mr-2 text-white"><i _ngcontent-jlq-c19=""><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.79 3.29L15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9.17 11.17C9.56 10.78 10.19 10.78 10.58 11.17L12 12.59L13.42 11.17C13.81 10.78 14.44 10.78 14.83 11.17C15.22 11.56 15.22 12.19 14.83 12.58L13.41 14L14.83 15.42C15.22 15.81 15.22 16.44 14.83 16.83C14.44 17.22 13.81 17.22 13.42 16.83L12 15.41L10.58 16.83C10.19 17.22 9.56 17.22 9.17 16.83C8.98275 16.6432 8.87751 16.3895 8.87751 16.125C8.87751 15.8605 8.98275 15.6068 9.17 15.42L10.59 14L9.17 12.58C8.78 12.2 8.78 11.56 9.17 11.17Z" fill="#848584" />
                                                                                </svg>
                                                                                </i></a>
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    return null
                                                                }
                                                            }) : null}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="row mt-3">
                                                        <div className="col-12">
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Container>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        )}
        />
    );
};