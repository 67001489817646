import * as React from 'react';
import '../Chat/app.scss';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import { MessageContainer } from '../../Containers/MessageContainer';
import { AuthContext } from '../../contexts/AuthContext';
import Axios from 'axios';
import { DAClient } from '../../lib/DAClient';
import * as Config from '../../config';
import * as LangConfig from '../../languages/languages';
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import Slider from '@material-ui/core/Slider';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Modal from 'react-bootstrap/Modal';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import * as moment from 'moment';

import Spinner from 'react-bootstrap/Spinner'

const da = new DAClient();

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		radio: {
			'&$checked': {
				color: '#4B8DF8'
			}
		},
		checked: {}
	}),
);

const DialogContent = withStyles(theme => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

interface IValueState {
	questions: any[];
	all: any;
	i: string;
	errors: any[];
	errorMessages: any[];
}

export const CreatePdf: React.FC = (props: any) => {
	const auth = React.useContext(AuthContext);
	const [open, setOpen] = React.useState(false);
	const [translations, setTranslations] = React.useState(auth['user']['attributes'].locale ? LangConfig.languages[auth['user']['attributes'].locale]['translations'] : LangConfig.languages['en']['translations']);
	const [langCode, setLangCode] = React.useState(auth['user']['attributes'].locale && auth['user']['attributes'].locale ? auth['user']['attributes'].locale : 'en');
	const [availInterviews, setAvailInterviews] = React.useState([]);
	const [allInterviews, setAllInterviews] = React.useState([]);
	const [nextInterview, setNextInterview] = React.useState([]);
	const [prevInterview, setPrevInterview] = React.useState([]);
	const [popupText, setpopupText] = React.useState('');
	const [title, setTitle] = React.useState('');

	let userDetails = '';
	if(localStorage.getItem("userId")) {
		if(JSON.parse(localStorage.getItem("userDetails")).fileMatter) {
			userDetails += JSON.parse(localStorage.getItem("userDetails")).fileMatter + ' - ';
		}
		userDetails += JSON.parse(localStorage.getItem("userDetails")).FirstName;
	}

	const handleClickOpen = () => {
		if (pageRoute != '/viewinterview') {
			setOpen(true);
		}
	};
	const handleClose = () => {
		setOpen(false);
	};

	const [show, setShow] = React.useState(false);
	const [pdfload, setPdfload] = React.useState(false);
	const [uploadPercent, setUploadPercent] = React.useState(0);
	const modelClose = () => {
		setShow(false)
	};
	const modelShow = () => setShow(true);
	const [submit, setSubmit] = React.useState(false);
	const handleClickSubmit = () => {
		setSubmit(true);
	};
	const handleSubmitClose = () => {
		setSubmit(false);
	};

	const [values, setValues] = React.useState<IValueState>({
		questions: [],
		all: null,
		i: '',
		errors: [],
		errorMessages: []
	});

	let pageRoute = window.location.pathname;
	let query_params = new URLSearchParams(window.location.search);
	let interviewId = query_params.get('v');

	const listAvailInterviews = async () => {
		let AvailableInterviews: any = await da.listInterviews();
		AvailableInterviews = AvailableInterviews.filter((e) => {
			return e.metadata['required privileges'].indexOf(Config.appHost) != -1;
		})

		// sort in descending order by interview title
		AvailableInterviews.sort((a, b) => (a.title > b.title) ? -1 : ((b.title > a.title) ? 1 : 0));
		setAvailInterviews(AvailableInterviews);
	}
	const submittedInterviews = async (userId) => {
		let AvailableInterviews: any = await da.listInterviews();
		AvailableInterviews = AvailableInterviews.filter((e) => {
			return e.metadata['required privileges'].indexOf(Config.appHost) != -1;
		})
		let sess = await Axios.post(Config.API_BASE_URL + 'getDocAssembleUserData/', {
			userId: userId,
			host: Config.appHost
		});
		let alls = sess.data.DocAssembleData.filter(inter => {
			let ind = AvailableInterviews.findIndex(e => e.filename == inter.i);
			if (ind > -1) {
				return true;
			}
			return false;
		});
		alls.sort((a, b) => (a.title > b.title) ? -1 : ((b.title > a.title) ? 1 : 0));
		setAllInterviews(alls);
	}

	const downloadFile = async (fileNumber, title) => {
		try {
			setPdfload(true);
			let sess = await Axios.post(Config.API_BASE_URL + 'getDocAssembleUserData/' + interviewId, { userId: '', host: Config.appHost });
			let file = sess.data.DocAssembleData[0].fileNumber.split("&nb&");
			for (let f of file) {
				await da.retrieveFile(f, sess.data.DocAssembleData[0].title);
				setPdfload(false);
			}
		} catch (err) {
			setPdfload(false);
		}
	}

	const translateToEnglish = (text) => {
		return new Promise(async (resolve, reject) => {
			try {
				let trns = await Axios.post(Config.API_BASE_URL + 'translate/', {
					text: text
				});
				return resolve(trns.data.text);
			} catch (err) {
				return reject(err);
			}
		});
	}

	const getInterviewData = async () => {
		let sess = await Axios.post(Config.API_BASE_URL + 'getDocAssembleUserData/' + 
		interviewId, { userId: '', host: Config.appHost });

		let files = sess.data.DocAssembleData[0].fileNumber.split("&nb&");
		console.log(files);

		if (sess.data.DocAssembleData[0].title == 'Petition for Benefit Determination') {
			setpopupText(`<span><b>You will receive 2 forms.</b></span><br/>
			<span>1) Petition for Benefit Determination Form</span><br/>
			<span>2) Continuation document</span><br/>
			<span>For processing with the BWC, you will need to send both forms to the BWC by email to PBD.Courtcourt@tn.gov.</span>`);
		} else if (sess.data.DocAssembleData[0].title == 'Request for Expedited Hearing' && files.length > 1) {
			setpopupText(`<span><b>You will receive 2 forms.</b></span><br/>
			<span>1) Request for Expedited Hearing form</span><br/>
			<span>2) Rule 72 Statement</span><br/>
			<span>For processing, you will need to send both forms to to the court clerk at:</span><br/><br/>
			<span>1) wc.courtclerk@tn.gov for email</span><br/>
			<span>2) 220 French Landing Drive, 1st Floor, Nashville, TN 37243-1002 for regular mail</span><br/>
			<span>3) 615-253-2480 for fax</span>`);
		} else if (sess.data.DocAssembleData[0].title == 'Request for Expedited Hearing'  && files.length <= 1) {
			setpopupText(`<span><b>You will receive 1 form.</b></span><br/>
			<span>Request for Expedited Hearing form</span><br/>
			<span>For processing, you will need to send the form to the court clerk at:</span><br/><br/>
			<span>1) wc.courtclerk@tn.gov for email</span><br/>
			<span>2) 220 French Landing Drive, 1st Floor, Nashville, TN 37243-1002 for regular mail</span><br/>
			<span>3) 615-253-2480 for fax</span>`);
		}
		setTitle(sess.data.DocAssembleData[0].title);
		setValues({ ...values, questions: sess.data.DocAssembleData[0].questionAns, i: sess.data.DocAssembleData[0].i, all: sess.data.DocAssembleData[0] });

		if (pageRoute == '/viewinterview') {
			submittedInterviews(sess.data.DocAssembleData[0].userId);
		}
	}

	React.useEffect(() => {
		getInterviewData();
		if (pageRoute == '/viewinterview') {
			listAvailInterviews();
		}
	}, [auth]);

	React.useEffect(() => {
		let currentInterviewIndex: any = allInterviews.findIndex(x => x._id === interviewId);

		let next: any = allInterviews.filter((a, index) => {
			return index > currentInterviewIndex;
		});

		let prev: any = allInterviews.filter((a, index) => {
			return index < currentInterviewIndex;
		});

		setNextInterview(next);
		setPrevInterview(prev);
	}, [allInterviews]);


	const viewNextInterview = () => {
		window.location.href = "/viewinterview?v=" + nextInterview[0]._id;
	}

	const viewPrevInterview = () => {
		window.location.href = "/viewinterview?v=" + prevInterview[0]._id;
	}

	const inputOnKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
		if (e.keyCode === 13) {  // if "Enter"
			e.preventDefault();
			// handleSubmit();
		}
	};

	const getAttachments = (attachments) => {
		let code = '';
		attachments.forEach((el) => {
			if (el) {
				code = code + el.number.pdf + '&nb&';
			}
		});
		return code;
	}


	const handleSubmit = async (event) => {
		event.preventDefault();
		da.i = values.i;
		try {
			if (values.errors.length == 0) {
				handleClickSubmit();
				let string = '';
				let sess = await Axios.post(Config.API_BASE_URL + 'getDocAssembleUserData/' + interviewId, { userId: '', host: Config.appHost });
				await da.editInterview(sess.data.DocAssembleData[0]);

				let fileNumber: any = null;
				let total = sess.data.DocAssembleData[0].questionAns.length + values.questions.length, completed = 0, i = 0, index = 0;
				let pdfQues = await da.getQuestion();
				while (fileNumber == null) {
					let obj = {}
					let filterIndex = values.questions.findIndex(el => el['variable'][pdfQues.fields[0]['variable_name']]);
					if (filterIndex > -1) {
						let mom: any = moment;
						if (pdfQues.fields[0]['datatype'] == 'date') {
							obj[pdfQues.fields[0]['variable_name']] = mom(values.questions[filterIndex].variable[pdfQues.fields[0]['variable_name']], 'YYYY-MM-DD').format('MM-DD-YYYY');
						} else {
							obj[pdfQues.fields[0]['variable_name']] = values.questions[filterIndex].variable[pdfQues.fields[0]['variable_name']];
						}
						// if (pdfQues.questionText.includes("&nbsp;")) {
						// 	string = string + '[BR][BR]Question: ' + pdfQues.questionText.replace(/ *\<[^>]*\> */g, "") + '[BR]Answer: ' + values.questions[filterIndex].variable[pdfQues.fields[0]['variable_name']]
						// }

						// if (pdfQues.questionText == "text_long") {
						// 	obj[pdfQues.fields[0]['variable_name']] = string;
						// }
					} else {
						if (pdfQues.questionType == "yesno") {
							obj[pdfQues.fields[0]['variable_name']] = false;
						} else {
							obj[pdfQues.fields[0]['variable_name']] = '';
						}
					}
					obj['questionRange'] = { step: "1" };
					obj['questionText'] = pdfQues.questionText;
					obj['questionType'] = pdfQues.questionType;
					// if (pdfQues.fields[0].datatype == "text" && langCode !== 'en') {
					if (((pdfQues.questionType == "text" && (pdfQues.questionChoices == undefined || pdfQues.questionChoices == '')) || pdfQues.questionType == "area") && langCode !== 'en') {
						try {
							obj[pdfQues.fields[0]['variable_name']] = await translateToEnglish(obj[pdfQues.fields[0]['variable_name']]);
						} catch (er) {
						}
					}
					pdfQues = await da.updateVariable(pdfQues.questionName, obj);
					if (pdfQues.attachments) {
						fileNumber = getAttachments(pdfQues.attachments);
					}

					let per: any = (completed / total) * 100;
					if (per <= 100) {
						completed = i + 1;
						setUploadPercent(parseInt(per));
						i += 1;
						index = index + 1;
					}
				}
				let sess_save = await Axios.post(Config.API_BASE_URL + 'updateDocInterview/' + interviewId, {
					session: da.session,
					secret: da.secret,
					i: da.i,
					fileNumber: fileNumber,
					questionAns: []
				});
				let final = 0;
				i = 0;
				for (let ques of values.questions) {
					if (ques.variable.questionType == "file") {
						let formData = new FormData();
						formData.append('file', ques.variable[Object.keys(ques.variable)[0]]);

						let fileupload = await Axios.post(Config.API_BASE_URL + 'saveDocAssembleUserFile/' + interviewId + '/' + ques.questionName, formData, {
							headers: {
								'content-type': 'multipart/form-data'
							}
						}
						);
					}

					if (ques.variable.questionType == "file") {
						let filename_split = ques.variable[Object.keys(ques.variable)[0]].name.split('.');

						let filename = interviewId + '_' + ques.questionName + '_' + filename_split.slice(0, -1).join('.') + '.' + filename_split[filename_split.length - 1];

						ques.variable[Object.keys(ques.variable)[0]] = filename;
					}
					let sess = await Axios.post(Config.API_BASE_URL + 'updateDocAssembleUserData/' + da.session, {
						questionName: ques.questionName,
						variable: ques.variable,
					});
					final = completed + i + 1;
					let per: any = (final / total) * 100;
					if (per <= 100) {
						setUploadPercent(parseInt(per));
						i += 1;
					}
				}
				handleSubmitClose();
				modelShow();
				// alert("Interview updated successfully.");
				// window.location.href="/interviews";
			} else {
			}
		} catch (error) {

		}
	};

	const handleClick = async (event, fileInputId) => {
		document.getElementById(fileInputId).click();
	}

	const makeTimeValue = async (event) => {
		const { target: { id } } = event;
	}

	const handleChange = async (event) => {
		const { target: { name, value, id } } = event;
		var questionId = id;
		var questionValue = value;
		if (id.indexOf("hours_") == -1 && id.indexOf("minutes_") == -1 && id.indexOf("amorpm_") == -1) {

		} else {
			questionId = questionId.replace('hours_', '').replace('minutes_', '').replace('amorpm_', '');
			if (id.indexOf("hours_") != -1) {
				var minutes = (document.getElementById(id.replace('hours_', 'minutes_')) as any).value;
				questionValue = value < 10 ? '0' + value : value;
				// minutes = minutes < 10 ? '0'+minutes : minutes;
				questionValue = questionValue + ':' + minutes + ' ' + (document.getElementById(id.replace('hours_', 'amorpm_')) as any).value;
			} else if (id.indexOf("minutes_") != -1) {
				var hours = (document.getElementById(id.replace('minutes_', 'hours_')) as any).value;
				questionValue = value < 10 ? '0' + value : value;
				// hours = hours < 10 ? '0'+hours : hours;
				questionValue = hours + ':' + questionValue + ' ' + (document.getElementById(id.replace('minutes_', 'amorpm_')) as any).value;
			} else {
				questionValue = (document.getElementById(id.replace('amorpm_', 'hours_')) as any).value + ':' + (document.getElementById(id.replace('amorpm_', 'minutes_')) as any).value + ' ' + value;
			}
		}


		let changedQuestion = values.questions.find(obj => obj.questionName === questionId);
		if (changedQuestion.variable['questionType'] == 'boolean') {
			changedQuestion.variable[name] = (questionValue == "true" ? true : false);
		} else if (changedQuestion.variable['questionType'] == 'file') {
			let filenameArr = event.target.files[0].name.split('.');
			let filename = filenameArr.slice(0, -1).join('.');
			let fileExt = filenameArr[filenameArr.length - 1];
			let fileAllowedExt = ['pdf', 'doc', 'docx', 'csv', 'xls', 'xlsx', 'jpg', 'jpeg', 'png', 'gif', 'txt', 'jpe'];

			if (fileAllowedExt.indexOf(fileExt) == -1) {
				values.errors = values.errors.filter(err => {
					return (Object.keys(err)[0] != questionId);
				});
				delete values.errorMessages[questionId];

				let questionError: any = {};
				questionError[questionId] = 'Please upload file of extensions ' + fileAllowedExt.join(',');
				values.errors = [...values.errors, questionError];
				values.errorMessages[questionId] = 'Please upload file of extensions ' + fileAllowedExt.join(',');

			} else {
				changedQuestion.variable[name] = event.target.files[0];
			}
		} else {
			changedQuestion.variable[name] = questionValue;
		}

		values.questions = [...values.questions];
		setValues({ ...values, questions: values.questions });
	}

	const [value, setValue] = React.useState(0);

	const handleBlur = (min, max) => {
		if (value < min) {
			setValue(min);
		} else if (value > max) {
			setValue(max);
		}
	};
	const handleValidate = (questionId, value, required, min, max, minlength, maxlength, pattern, masking) => {
		let fieldErrors: any = [];

		if (required && value == '') {
			fieldErrors = [...fieldErrors, translations.mandatory_field];

		} else if (min != '' && min != undefined && Number(value) < Number(min)) {
			fieldErrors = [...fieldErrors, translations.greater_equal_valid + ' ' + min];

		} else if (max != '' && max != undefined && Number(value) > Number(max)) {
			fieldErrors = [...fieldErrors, translations.less_equal_valid + ' ' + max];

		} else if (minlength != '' && minlength != undefined && Number(value.length) < Number(minlength)) {
			fieldErrors = [...fieldErrors, translations.char_g_e + '  ' + minlength + ' ' + translations.chars];

		} else if (maxlength != '' && maxlength != undefined && Number(value.length) > Number(maxlength)) {
			fieldErrors = [...fieldErrors, translations.char_l_e + '  ' + maxlength + ' ' + translations.chars];

		} else if (pattern != '' && pattern != undefined && !value.match(pattern)) {
			fieldErrors = [...fieldErrors, translations.valid_format];

		} else if (masking != '' && masking != undefined && value.indexOf('_') != -1) {
			fieldErrors = [...fieldErrors, translations.digits_0_9];

		}
		// values.errors = values.errors.filter(err => {
		// 	return (Object.keys(err)[0] != questionId);
		// });
		// delete values.errorMessages[questionId];

		// if (fieldErrors.join('') != '') {
		// 	let questionError: any = {};
		// 	questionError[questionId] = fieldErrors.join('');
		// 	values.errors = [...values.errors, questionError];
		// 	values.errorMessages[questionId] = fieldErrors.join('');
		// }
	}

	const toDate = (dateStr) => {
		var parts = dateStr.split("-")
		let date = parts[0] + '-' + (parseInt(parts[1]) < 10 ? parts[1].includes('0') ? parts[1] : '0' + parts[1] : parts[1]) + '-' + (parseInt(parts[2]) < 10 ? parts[2].includes('0') ? parts[2] : '0' + parts[2] : parts[2]);
		return date;
	}


	const maskedInputValue = (string) => {
		return 'XXX-XX-' + string;
	}

	const renderValue = (value) => {
		if (value) {
			return (<span className="text-val">{value}</span>)
		} else {
			return (<span className="text-null">{translations.No_Answer}</span>)
		}

	}


	const renderHTMLInput = (result) => {
		if ((result.variable.questionType == "text" && (result.variable.questionChoices == undefined || result.variable.questionChoices == '') || result.variable.questionType == "currency")) {
			if (pageRoute == '/viewinterview') {
				return renderValue(result.variable[Object.keys(result.variable)[0]])
			} else {
				return (
					<TextField style={{ width: '100%', alignSelf: 'flex-end' }}
						id={result.questionName}
						onChange={(event) => {
							handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', result.variable.questionRange.minlength, result.variable.questionRange.maxlength, '', '');
							handleChange(event);
						}}
						required
						inputProps={{
							'minLength': result.variable.questionRange.minlength,
							'maxLength': result.variable.questionRange.maxlength,
						}}
						name={Object.keys(result.variable)[0]}
						value={result.variable[Object.keys(result.variable)[0]]}
						onKeyDown={() => handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', result.variable.questionRange.minlength, result.variable.questionRange.maxlength, '', '')}
						onBlur={() => handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', result.variable.questionRange.minlength, result.variable.questionRange.maxlength, '', '')}
						disabled={pageRoute == '/viewinterview'}
					/>
				);
			}
		} else if (result.variable.questionType == "boolean") {
			return (
				<div className='boolean_btn' >
					<Button color="primary" variant="outlined" className="disagree" disabled> {translations.big_no} </Button>
					<Button color="primary" variant="contained" className="agree" disabled> {translations.big_yes} </Button>
				</div>
			);
		} else if ((result.variable.questionType == "integer" && ((result.variable.questionRange.default == undefined && result.variable.questionRange.hint == undefined) || (result.variable.questionRange.default !== undefined && result.variable.questionRange.hint.toLowerCase() == 'ssn'))) || result.variable.questionType == "number") {
			if (pageRoute == '/viewinterview') {
				return renderValue(result.variable[Object.keys(result.variable)[0]])
			}
			else {
				return (
					<TextField style={{ width: '100%', alignSelf: 'flex-end' }}
						type="number"
						required
						id={result.questionName}
						onChange={(event) => {
							handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, result.variable.questionRange.min, result.variable.questionRange.max, '', '', '', '');
							handleChange(event);
						}}
						inputProps={{
							'min': result.variable.questionRange.min,
							'max': result.variable.questionRange.max,
						}}
						name={Object.keys(result.variable)[0]}
						value={result.variable[Object.keys(result.variable)[0]]}
						onBlur={() => handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, result.variable.questionRange.min, result.variable.questionRange.max, '', '', '', '')}
						onKeyDown={() => handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, result.variable.questionRange.min, result.variable.questionRange.max, '', '', '', '')}
						disabled={pageRoute == '/viewinterview'}
					/>
				);
			}
		} else if (result.variable.questionType == "integer" && result.variable.questionRange.default != undefined && result.variable.questionRange.hint != undefined) {
			if (result.variable[Object.keys(result.variable)[0]].length == 4) {
				if (pageRoute == '/viewinterview') {
					return renderValue(result.variable[Object.keys(result.variable)[0]])
				}
				else {
					return (
						<TextField style={{ width: '100%', alignSelf: 'flex-end' }}
							type='text'
							required
							name={Object.keys(result.variable)[0]}
							value={maskedInputValue(result.variable[Object.keys(result.variable)[0]])}
							id={result.questionName}
							onChange={(event) => {
								handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', '', '', '', true);
								handleChange(event);
							}}
							onBlur={() => handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', '', '', '', true)}
							onKeyDown={() => handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', '', '', '', true)}
							disabled={pageRoute == '/viewinterview'}
						/>
					)
				}
			} else {
				if (pageRoute == '/viewinterview') {
					return renderValue(result.variable[Object.keys(result.variable)[0]])
				}
				else {
					return (
						<MaskedFormControl style={{ width: '100%', alignSelf: 'flex-end' }}
							type='text'
							required
							name={Object.keys(result.variable)[0]}
							mask='111-111-1111'
							value={typeof result.variable[Object.keys(result.variable)[0]] !== 'string'? JSON.stringify(result.variable[Object.keys(result.variable)[0]]): result.variable[Object.keys(result.variable)[0]]}
							id={result.questionName}
							onChange={(event) => {
								handleValidate(result.questionName, typeof result.variable[Object.keys(result.variable)[0]] !== 'string'? JSON.stringify(result.variable[Object.keys(result.variable)[0]]): result.variable[Object.keys(result.variable)[0]], true, '', '', '', '', '', true);
								handleChange(event);
							}}
							// onBlur={() => handleValidate(result.questionName, JSON.stringify(result.variable[Object.keys(result.variable)[0]]), true, '', '', '', '', '', true)}
							// onKeyDown={() => handleValidate(result.questionName, JSON.stringify(result.variable[Object.keys(result.variable)[0]]), true, '', '', '', '', '', true)}
							disabled={pageRoute == '/viewinterview'}
						/>
					)
				}
			}
		} else if (result.variable.questionType == "date") {
			if (pageRoute == '/viewinterview') {
				return renderValue(result.variable[Object.keys(result.variable)[0]])
			}
			else {
				return (
					<TextField style={{ width: '100%', alignSelf: 'flex-end' }}
						id={result.questionName}
						type="date"
						required
						onChange={(event) => {
							handleChange(event);
						}}
						onKeyDown={(e) => e.preventDefault()}
						name={Object.keys(result.variable)[0]}
						defaultValue={result.variable[Object.keys(result.variable)[0]] ? toDate(result.variable[Object.keys(result.variable)[0]]) : null}
						inputProps={{
							'max': (result.variable.questionRange.default != undefined && result.variable.questionRange.hint != undefined && result.variable.questionRange.hint.toLowerCase() == 'before') ? new Date(result.variable.questionRange.default).toISOString().split('T')[0] : undefined,
							'min': (result.variable.questionRange.default != undefined && result.variable.questionRange.hint != undefined && result.variable.questionRange.hint.toLowerCase() == 'after') ? new Date(result.variable.questionRange.default).toISOString().split('T')[0] : undefined
						}}
						disabled={pageRoute == '/viewinterview'}
					/>
				);
			}
		} else if (result.variable.questionType == "area") {
			if (pageRoute == '/viewinterview') {
				return renderValue(result.variable[Object.keys(result.variable)[0]])
			}
			else {
				return (
					<TextField style={{ width: '100%', alignSelf: 'flex-end' }}
						label=""
						multiline
						required
						rows="4"
						inputProps={{
							'minLength': result.variable.questionRange.minlength,
							'maxLength': result.variable.questionRange.maxlength,
						}}
						margin="normal"
						variant="outlined"
						onChange={(event) => {
							handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', result.variable.questionRange.minlength, result.variable.questionRange.maxlength, '', '');
							handleChange(event);
						}}
						name={Object.keys(result.variable)[0]}
						id={result.questionName}
						defaultValue={result.variable[Object.keys(result.variable)[0]]}
						onBlur={() => handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', result.variable.questionRange.minlength, result.variable.questionRange.maxlength, '', '')}
						onKeyDown={() => handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', result.variable.questionRange.minlength, result.variable.questionRange.maxlength, '', '')}
						disabled={pageRoute == '/viewinterview'}
					/>
				);
			}
		} else if (result.variable.questionType == "email") {
			if (pageRoute == '/viewinterview') {
				return renderValue(result.variable[Object.keys(result.variable)[0]])
			}
			else {
				return (
					<TextField style={{ width: '100%', alignSelf: 'flex-end' }}
						id={result.questionName}
						type="email"
						required
						inputProps={{
							// 'pattern' : /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
						}}
						onChange={(event) => {
							handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', '', '', '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$', '');
							handleChange(event);
						}}
						name={Object.keys(result.variable)[0]}
						defaultValue={result.variable[Object.keys(result.variable)[0]]}
						onBlur={() => handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', '', '', '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$', '')}
						onKeyDown={() => handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', '', '', '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$', '')}
						disabled={pageRoute == '/viewinterview'}
					/>
				);
			}
		} else if (result.variable.questionType == "time") {
			if (pageRoute == '/viewinterview') {
				return renderValue(result.variable[Object.keys(result.variable)[0]])
			}
			else {
				console.log(result.variable[Object.keys(result.variable)[0]],'fdfsdf')
				return (
					<div id={result.questionName} onClick={(event) => makeTimeValue(event)}>
						<TextField style={{ marginRight: 10, alignSelf: 'flex-end' }}
							type="number"
							required
							id={"hours_" + result.questionName}
							onChange={(event) => {
								handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]].split(':')[0], true, 0, 11, '', '', '', '');
								handleChange(event);

							}}
							onKeyDown={(e) => e.preventDefault()}
							inputProps={{
								'min': 0,
								'max': 11,
							}}
							name={Object.keys(result.variable)[0]}
							value={result.variable[Object.keys(result.variable)[0]].replace('am', '').replace('pm', '').replace(/ /g, '').split(':')[0].length == 1 ? '0' + result.variable[Object.keys(result.variable)[0]].replace('am', '').replace('pm', '').replace(/ /g, '').split(':')[0] : result.variable[Object.keys(result.variable)[0]].replace('am', '').replace('pm', '').replace(/ /g, '').split(':')[0]}
							disabled={pageRoute == '/viewinterview'}
						/>
					            :
						<TextField style={{ marginLeft: 10, alignSelf: 'flex-end' }}
							type="number"
							required
							id={"minutes_" + result.questionName}
							onChange={(event) => {
								handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]].replace('am', '').replace('pm', '').split(':')[1], true, 0, 59, '', '', '', '');
								handleChange(event);
							}}
							onKeyDown={(e) => e.preventDefault()}
							inputProps={{
								'min': 0,
								'max': 59,
							}}
							name={Object.keys(result.variable)[0]}
							value={result.variable[Object.keys(result.variable)[0]] && result.variable[Object.keys(result.variable)[0]].replace('am', '').replace('pm', '').replace(/ /g, '').split(':')[1].length == 1 ? '0' + result.variable[Object.keys(result.variable)[0]].replace('am', '').replace('pm', '').replace(/ /g, '').split(':')[1] : result.variable[Object.keys(result.variable)[0]].replace('am', '').replace('pm', '').replace(/ /g, '').split(':')[1]}
							disabled={pageRoute == '/viewinterview'}
						/>&nbsp;
						<select
							style={{ border: 0, padding: 5 }}
							required
							disabled={pageRoute == '/viewinterview'}
							name={Object.keys(result.variable)[0]}
							id={"amorpm_" + result.questionName}
							defaultValue={result.variable[Object.keys(result.variable)[0]] && result.variable[Object.keys(result.variable)[0]].includes('pm') ? 'pm' : 'am'}
							onChange={(event) => {
								event.target['id'] = "amorpm_" + result.questionName
								event.target['name'] = Object.keys(result.variable)[0]
								handleChange(event);
							}}
						>
							<option value="am">am</option>
							<option value="pm">pm</option>
						</select>
					</div>
				);
			}
		} else if (result.variable.questionType == "password") {
			if (pageRoute == '/viewinterview') {
				return renderValue(result.variable[Object.keys(result.variable)[0]])
			}
			else {
				return (
					<Input style={{ width: '100%', alignSelf: 'flex-end' }}
						id={result.questionName}
						type='password'
						required
						inputProps={{
							'minLength': result.variable.questionRange.minlength,
							'maxLength': result.variable.questionRange.maxlength,
						}}
						name={Object.keys(result.variable)[0]}
						value={result.variable[Object.keys(result.variable)[0]]}
						onChange={(event) => {
							handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', result.variable.questionRange.minlength, result.variable.questionRange.maxlength, '', '');
							handleChange(event);
						}}
						onBlur={() => handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', result.variable.questionRange.minlength, result.variable.questionRange.maxlength, '', '')}
						onKeyDown={() => handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', result.variable.questionRange.minlength, result.variable.questionRange.maxlength, '', '')}
						disabled={pageRoute == '/viewinterview'}
					/>
				);
			}
		} else if (result.variable.questionType == "range") {
			if (pageRoute == '/viewinterview') {
				return renderValue(result.variable[Object.keys(result.variable)[0]])
			}
			else {
				return (
					<span style={{ alignSelf: 'flex-end' }}>
						<Slider
							value={typeof value === 'number' ? value : Number(result.variable[Object.keys(result.variable)[0]])}
							// onChange={handleSliderChange}
							aria-labelledby={result.questionName}
							aria-valuetext={Object.keys(result.variable)[0]}
							min={result.variable.questionRange ? Number(result.variable.questionRange.min) : 0}
							max={result.variable.questionRange ? Number(result.variable.questionRange.max) : 10}
							step={result.variable.questionRange ? Number(result.variable.questionRange.step) : 1}
						/>
						<Input
							value={value}
							required
							defaultValue={result.variable[Object.keys(result.variable)[0]]}
							margin="dense"
							onKeyDown={(e) => e.preventDefault()}
							onChange={(event) => {
								handleChange(event);
							}}
							onBlur={() => handleBlur(Number(result.variable.questionRange.min), Number(result.variable.questionRange.max))}
							inputProps={{
								step: (result.variable.questionRange ? Number(result.variable.questionRange.step) : 1),
								min: (result.variable.questionRange ? Number(result.variable.questionRange.min) : 0),
								max: (result.variable.questionRange ? Number(result.variable.questionRange.max) : 10),
								type: 'number',
								id: result.questionName,
								name: Object.keys(result.variable)[0],
								'aria-labelledby': result.questionName,
								'aria-valuetext': Object.keys(result.variable)[0]
							}}
							disabled={pageRoute == '/viewinterview'}
						/>

					</span>
				);
			}
		} else if (result.variable.questionType == "text" && result.variable.questionChoices != undefined && result.variable.questionChoices != '') {  //radio, selectbox
			if (pageRoute == '/viewinterview') {
				return renderValue(result.variable[Object.keys(result.variable)[0]])
			}
			else {
				return (
					<span style={{ width: '100%' }}>
						<Select style={{ width: '100%', alignSelf: 'flex-end' }} native
							required
							value={result.variable[Object.keys(result.variable)[0]]}
							onChange={(event) => {
								handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', '', '', '', '');
								handleChange(event);
							}}
							inputProps={{
								name: Object.keys(result.variable)[0],
								id: result.questionName,
							}}

							onBlur={() => handleValidate(result.questionName, result.variable[Object.keys(result.variable)[0]], true, '', '', '', '', '', '')}
							disabled={true}
						>
							<option value='' key=''></option>
							{
								result.variable.questionChoices.map(function (result, index) {
									return (<option value={result.value} key={index}>{result.label}</option>);
								})
							}
						</Select >
					</span>
				)
			}
		} else if (result.variable.questionType == "file") {
			return (
				<div>
					<input type="file"
						style={{ 'display': 'none', 'width': '100%', alignSelf: 'flex-end' }}
						id={result.questionName}
						name={Object.keys(result.variable)[0]}
						onChange={e => {
							handleChange(e);
						}}
						disabled={pageRoute == '/viewinterview'}
					/>
					{
						values.questions.map(function (ques) {
							if (ques.questionName == result.questionName && ques.variable[Object.keys(result.variable)[0]] != "") {
								return (
									<div>{typeof ques.variable[Object.keys(result.variable)[0]] == "object" ? ques.variable[Object.keys(result.variable)[0]].name : (ques.variable[Object.keys(result.variable)[0]] != "Yes" ? ques.variable[Object.keys(result.variable)[0]] : '')}
									</div>
								)
							}
						})
					}

					<Button disabled={pageRoute == '/viewinterview'}
						variant="contained"
						className="btn btn-primary text-white"
						onClick={(event) => handleClick(event, result.questionName)}>
						{translations.Upload}
					</Button>
				</div>
			);
		}
	}

	return (
		<MessageContainer
			render={({ messages, setMessages, resetSession, useOldSession }) => (
				<>
					<div>
						<Header resetSession={resetSession} />
						<div className="app-main">
							<div className="app-main-inner">
								<Container
									style={{
										paddingTop: 30,
										paddingBottom: 30,
									}}
								>
									<div className="table-responsive reviewEditInterview position-r">
										<table className="table table-bordered">
											<thead>
												<tr>
													<th className="border-right-0 reviewHeading py-4 px-0">
														<div className="row d-flex flex-wrap justify-content-center align-items-stretch">
															<div className="col-12 col-sm-8 border-right-0 d-flex align-items-center">
																<h1 className="h2 mb-0"><span>
																	{pageRoute != '/viewinterview' ? <span>{translations.Review_and_Edit_Answers}</span> : <span>{translations.Review_Answers} </span>}
																</span><span className="text-light"> • </span>
																	<span className="text-primary border-b-blue">{(values.all != null ? (localStorage.getItem("userId") == values.all.userId ? userDetails : values.all.userId) : '')}
																	</span>
																</h1>
															</div>
															<div className="col-12 col-sm-4 px-3 d-flex justify-content-start justify-content-sm-end align-items-center">
																{
																	prevInterview.length != 0 ?
																		<a title={translations.View_Previous_Interview} onClick={() => viewPrevInterview()} className="lineHeight">
																			<span className="material-icons rounded mr-1" aria-describedby="verified-tooltip">
																				keyboard_arrow_left
					   		</span>
																		</a>
																		: ''
																}
																<span className="int_title">{title}</span>
																{
																	nextInterview.length != 0 ?
																		<a title={translations.View_Next_Interview} onClick={() => viewNextInterview()} className="lineHeight">
																			<span className="material-icons rounded" aria-describedby="verified-tooltip">
																				keyboard_arrow_right
					   		</span>
																		</a>
																		: ''
																}
															</div>
														</div>
													</th>
												</tr>
												<tr>
													<td className="border-right-0 quesCompleted p-0 d-none d-sm-block">
														<div className="row d-flex flex-wrap justify-content-center align-items-stretch py-3 border-b">
															<div className="col-9 border-right-0 d-flex align-items-center pl-60">
																<div className="quesCompleted">
																	<strong>{translations.Questions}</strong>
																	{/* <span className="text-light">&nbsp;&nbsp;•&nbsp;&nbsp;</span>100% completed<span className="text-light">&nbsp;&nbsp;•&nbsp;&nbsp;</span>56 attempted */}
																</div>
															</div>
															<div className="col-3 border-right-0 d-flex flex-wrap justify-content-center align-items-center p-0">
																<div className="quesCompleted"><strong>{translations.Answers}</strong></div>
															</div>
														</div>
													</td>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className="border-top-0">
														{pageRoute != '/viewinterview' ? <span className="note_error pl-50">{translations.redo_note}</span> : null}
														<form onSubmit={(event) => handleSubmit(event)} noValidate autoComplete="off" onKeyDown={inputOnKeyDown} encType="multipart/form-data" id="view_edit_form">
															{
																values.questions != undefined ?
																	values.questions.map(function (result, index) {
																		if (index > 0 && result.variable.questionText !== 'text_long') {
																			return (
																				<div className="row d-flex flex-wrap justify-content-center align-items-stretch border-b">
																					<div className="col-1 py-4 pl-0 d-flex justify-content-end"><span className="serialNo">{index}.</span></div>
																					<div className="col-11 col-sm-8 border-right py-4 ques_text Gilroy-Medium">
																						<div className="row" key={result.questionName}>
																							<div className="col-12 p-0">
																								<span dangerouslySetInnerHTML={{ __html: result.variable.questionText }}></span>
																							</div>
																						</div>
																					</div>
																					<div className="col-12 col-sm-3 py-3 d-flex flex-wrap justify-content-center align-items-center">
																						<div className="row " key={result.questionName}>
																							<div className="col-12 p-sm-0 pl-3">
																								<div style={{ color: 'red' }}>
																									{values.errorMessages[result.questionName]}
																								</div>
																							</div>
																							<div className="col-12 answerbox_" style={{ display: 'flex' }}>
																								<p className="d-block d-sm-none">{translations.Answers}</p>
																								{result.variable.questionText !== 'text_long' && index > 0 ? renderHTMLInput(result) : null}
																							</div>
																						</div>
																					</div>
																				</div>
																			)
																		} else {
																			return null
																		}

																	})
																	:
																	'No Questions Attempted.'

															}
															{
																pageRoute != '/viewinterview' ?
																	<div className="row d-flex flex-wrap justify-content-center align-items-stretch border-b pl-50">
																		<div className="col-12 py-5 pl-0">
																			<button type="submit" className="btn btn-primary text-white text-uppercase"
																				disabled={values.errors.length != 0 || submit ? true : false}>
																				{submit ? <Spinner animation="border" style={{ width: '1rem', height: '1rem', marginRight: '1em' }} /> : ''}
																				{translations.Get_Your_Forms}</button>
																			{uploadPercent > 0 ? <span style={{ marginLeft: '15px', fontWeight: 'bold', fontSize: '16px', color: '#555555' }}>{uploadPercent} <span style={{ fontWeight: 'bold', fontSize: '10px', color: 'black' }}>%</span></span> : null}
																		</div>
																	</div>
																	: ''
															}



															{/* <div className="gradient-white position-a"></div> */}
														</form>


													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<Modal show={show}>
										<Modal.Header >
											<Modal.Title>{translations.Data_updated}</Modal.Title>
											<a type="button" onClick={() => props.history.push('/availableInterviews', { fromEdit: true })} >
												<i className="material-icons">close</i>
											</a>
										</Modal.Header>
										<Modal.Body style={{ background: 'white' }}>
											{values.all !== null && values.all.fileNumber !== '-1' ? <div className="modal-text"><div className="text-center"><a className="btn btn-primary text-white" onClick={() => downloadFile(values.all.fileNumber, values.all.title)} href={`javascript:void(0)`}>{translations.d_pdf_message}</a></div>
												<br />
												<p style={{ marginTop : '10px'}} dangerouslySetInnerHTML={{ __html: popupText }}></p>
											</div> : <p>{translations.Data_updated}</p>}
										</Modal.Body>
									</Modal>

									<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
										<DialogContent>
											{open ? (
												<IconButton autoFocus aria-label="close" onClick={handleClose} style={{ position: 'absolute', top: '-5px', right: 0, color: '#305cc5' }}>
													<CloseIcon />
												</IconButton>
											) : null}
											<Typography gutterBottom>
												{translations.delete_if_not_needed}
											</Typography>
										</DialogContent>
										<DialogActions>
											<Button onClick={() => window.location.href = "/availableInterviews"} className="btn btn-primary text-white" >
												{translations.Start_New_Interview}
											</Button>
										</DialogActions>
									</Dialog>
								</Container>
							</div>
						</div>
						<Footer />
					</div>
				</>
			)}
		/>
	);
};
