import * as React from 'react';
import './container.scss';

interface IContainer {
  fullWidth?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const Container: React.FC<IContainer> = ({
  fullWidth,
  style,
  children,
}) => {
  const classes = {
    fullWidth: fullWidth ? 'container--fullWidth' : '',
  };
  return (
    <div className={`container ${classes.fullWidth}`} style={style}>
      {children}
    </div>
  );
};
