import * as React from 'react';
import './login.scss';
import Image from 'react-bootstrap/Image';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { RouteComponentProps, Redirect } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';
import { AdapterLink } from '../AdapterLink';

import SimpleSnackbar from '../SimpleSnackbar';
import { DAClient } from '../../lib/DAClient';
import * as Config from '../../config';
import AWS from 'aws-sdk';
import axios from 'axios';

const da = new DAClient(); 
var CryptoJS = require("crypto-js");


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
    img_logo: {
      width: 350,
      marginBottom: 10,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 20,
    },
    textFieldSignup: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 20,
    },
    menu: {
      width: 200,
    },
    button: {
      margin: theme.spacing(1),
      marginTop: 20,
    },
    input: {
      display: 'none',
    },
  }),
);

interface IValueState {
  username: string;
  password: string;
  errorMessages: string[];
  successMessages: string[];
  usernameHasError: boolean;
  passwordHasError: boolean;
}


interface IAvailState {
  availInterviews: any[];
}

export const Login: React.FC<RouteComponentProps> = props => {
  const auth = React.useContext(AuthContext);
  const classes = useStyles();
  const [pass, setPass] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pass1, setPass1] = React.useState(false);
  const [values, setValues] = React.useState<IValueState>({
    username: '',
    password: '',
    usernameHasError: false,
    passwordHasError: false,
    errorMessages: [],
    successMessages: []
  });
  const hostname = window && window.location && window.location.hostname;
  const [valuesSignup, setValuesSignup] = React.useState({
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    errorMessages: [],
    successMessages: [],
    usernameHasError: false,
    passwordHasError: false,
  });

  let query_params = new URLSearchParams(window.location.search);
  
  let success_param = query_params.get('success');
  let error_param = query_params.get('error');
  if(error_param) {
    if(error_param == "UserNotFoundException") {
      error_param = "Username/client id combination not found.";
    } else if(error_param == "NotAuthorizedException") {
      error_param = "User cannot be confirmed. Current status is CONFIRMED.";
    } else {
      error_param = "Account Verification Failed!";
    }
  }
  let interviewType = query_params.get('interview');
  let view = query_params.get('view');
  if (interviewType) {
    localStorage.setItem('inetrviewtype', interviewType);
  }
  if (view) {
    if (view == 'dv' || view == 'wc') {
      if (localStorage.getItem('iview') == 'false') {
        auth.logout();
        window.location.reload();
      }
      localStorage.setItem("iview", view);
    }
  }

  const directInterviewOpen = async (inter) => {
    localStorage.setItem('inetrviewtype', 'false');
    if (inter == "divorce") {
      inter = 'docassemble.playground1:Divorce-form-final.yml';
    } else if (inter == "feewaiver") {
      inter = 'docassemble.playground1:Fee-Waiver.yml';
    } else if (inter == "workers") {
      inter = 'docassemble.playground1:TN-workers-progress.yml';
    } else if (inter == "parenting") {
      inter = 'docassemble.playground1:Permanent-Parenting.yml';
    }

    let AvailableInterviews: any = await da.listInterviews();
    let result: any = [];
    if (hostname == 'help4tn.atjbot.org') {
      result = AvailableInterviews.filter((e) => {
        return e.filename == inter;
      })
    } else {
      result = AvailableInterviews.filter((e) => {
        return e.metadata['required privileges'].indexOf(Config.appHost) != -1 && e.filename == inter;
      })
    }

    if (inter && AvailableInterviews.length != 0 && result.length > 0) {
      window.location.href = "/?i=" + result[0].filename + "&title=" + result[0].metadata.title + "&total_steps=" + result[0].metadata.total_steps;
    } else {
      window.location.href = '/availableInterviews';
    }
  }

  let routePath: any = '/';

  if (auth.hasValidSession && !auth.requireVerification) {
    if (interviewType == "divorce" || interviewType == "feewaiver" || interviewType == "workers" || interviewType == "parenting") {
      // directInterviewOpen(interviewType);
    } else {
      const { from } = props.location.state || { from: { pathname: routePath } };
      return <Redirect to={from} />;
    }
  }

  const handleResendConfirmationCode = () => {
    const { username } = values;
    // setValues({
    //   ...values,
    //   errorMessages: [],
    //   usernameHasError: false
    // });

    auth.resendSignUp && auth.resendSignUp({ username });
    /*setValues({
      ...values,
      errorMessages: ['Email resent with confirmation code. Login with your account.'],
    });*/
  }

  if (auth.requireVerification && !auth.hasValidSession) {
    handleResendConfirmationCode();
    var ciphertext = CryptoJS.AES.encrypt(values['password'], 'my-secret-key@123').toString();
    localStorage.setItem('authKey', ciphertext);
    return <Redirect to={'/verify'} />;
  }

  const validateForm = (username: string, password: string) => {
    let err: string = '';
    if (!username && !password) {
      err = 'Please enter email and password.';
    }
    if (!username && password) {
      err = 'Please enter email.';
    }
    if (username && !password) {
      err = 'Please enter password.';
    }

    setValues({
      ...values,
      errorMessages: [...values.errorMessages, err],
      usernameHasError: !username,
      passwordHasError: !password,
    });

    return !!username && !!password;
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.keyCode === 13) {  // if "Enter"
      e.preventDefault();
      handleLogin();
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      let username = values['username'].toLowerCase();
      let password = values['password'];
      const inputIsValid = validateForm(username, password);

      if (inputIsValid) {
        setValues({
          ...values,
          errorMessages: [],
          successMessages: [],
          usernameHasError: false,
          passwordHasError: false,
        });

    var ciphertext = CryptoJS.AES.encrypt(values['password'], 'my-secret-key@123').toString();
    localStorage.setItem('authKey', ciphertext);
        auth.login && (await auth.login({ username, password }));
        localStorage.setItem('instpopup', 'false');
        // window.location.href = '/';

        const res = await axios.get('https://geolocation-db.com/json/')
        let IPV4address = res.data.IPv4;

        let userAgent = navigator.userAgent;
        let browserName;
       
        if(userAgent.match(/chrome|chromium|crios/i)) {
           browserName = "chrome";
        } else if(userAgent.match(/firefox|fxios/i)) {
           browserName = "firefox";
        } else if(userAgent.match(/safari/i)) {
           browserName = "safari";
        } else if(userAgent.match(/opr\//i)) {
           browserName = "opera";
        } else if(userAgent.match(/edg/i)) {
           browserName = "edge";
        } else {
           browserName = "No browser detection";
        }

        let date = new Date();
        let pst = date.toLocaleString('en-US', {
          timeZone: 'America/Los_Angeles',
        });

        let params = {
          "UserAttributes": [
            {
              "Name": "custom:lastLogin",
              "Value": pst
            },
            {
              "Name": "custom:IP",
              "Value": IPV4address
            },
            {
              "Name": "custom:Browser",
              "Value": browserName
            }
          ],
          "Username": username,
          "UserPoolId": Config.AWS_userPoolId
        }
        try {
          AWS.config.update(Config.AWSConfigExport);
          const cognito = new AWS.CognitoIdentityServiceProvider();
          await cognito.adminUpdateUserAttributes(params).promise();
        } catch (err) {
          console.log(err);
        }

        if (interviewType || (localStorage.getItem('inetrviewtype') && localStorage.getItem('inetrviewtype') !== 'false')) {
          let inter = localStorage.getItem('inetrviewtype');
          directInterviewOpen(inter);
        } else {
          window.location.href = '/';
          // props.history.push('/availableInterviews');          
        }
      }
      setLoading(false);
    } catch (error) {
    setLoading(false);
      setValues({
        ...values,
        errorMessages: [error.message],
      });
    }
  };

  const validateFormSignup = (username: string, password: string) => {
    let err: string = '';
    if (!username && !password) {
      err = 'Please enter email and password.';
    }
    if (!username && password) {
      err = 'Please enter email.';
    }
    if (username && !password) {
      err = 'Please enter password.';
    }

    setValuesSignup({
      ...valuesSignup,
      errorMessages: [...valuesSignup.errorMessages, err],
      usernameHasError: !username,
      passwordHasError: !password,
    });

    return !!username && !!password;
  };

  // const handleSignUp = async () => {
  //   try {
  //     let username = valuesSignup['username'].toLowerCase();
  //     let password = valuesSignup['password'];
  //     let firstName = valuesSignup['firstName'];
  //     let lastName = valuesSignup['lastName'];
  //     const inputIsValid = validateFormSignup(username, password);

  //     if (inputIsValid) {
  //       setValuesSignup({
  //         ...valuesSignup,
  //         errorMessages: [],
  //         usernameHasError: false,
  //         passwordHasError: false,
  //       });

  //       auth.signup && (await auth.signup({ username, password, firstName, lastName }));
  //       setValuesSignup({
  //         ...valuesSignup,
  //         errorMessages: ['User registered successfully. Please confirm email.'],
  //       });
  //     }
  //   } catch (error) {
  //     // console.log(error);
  //     setValuesSignup({
  //       ...valuesSignup,
  //       errorMessages: [error.message],
  //     });
  //   }
  // };



  const handleClickShowPassword = () => {
    setPass(!pass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="login-page">
      <div className="login">
        {props.location.state && props.location.state.verfiedCode ? <div className = "alert alert-success w-100">Your account is verified now, please login with your username and password! </div> : null}

        {success_param ? <div className = "alert alert-success w-100">Your account has been verified, Please login to continue.</div> : null}

        {error_param ? <div className = "alert alert-danger w-100"> {error_param} </div> : null}

        {values.errorMessages &&
          values.errorMessages.map(
            (err, i) => !!err && <div className = "alert alert-danger w-100"> {err} </div>,
        )}

        {/* <img src={require('../../assets/images/lawdroid-voice-logo.png')} alt="Lawdroid Logo" className={classes.img_logo}/> */}
        <form className={classes.container} noValidate autoComplete="off"
          onKeyDown={inputOnKeyDown}>
          <label>Email</label>
          <TextField
            error={values.usernameHasError}
            id="standard-name"
            placeholder="example@email.xyz"
            className={classes.textField}
            value={values.username}
            onChange={e => {
              setValues({ ...values, username: e.target.value });
            }}
          />
          <label className="mt-30">Password</label>
          <Input
            id="standard-adornment-password"
            style={{ marginRight: '0', marginLeft: '0' }}
            placeholder="pass••••"
            type={!pass ? 'password' : 'text'}
            error={values.passwordHasError}
            className={'myInput'}
            value={values.password}
            onChange={e => {
              setValues({ ...values, password: e.target.value });
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  style={{ background: 'transparent', color: '#333' }}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  className="passcodeIcon"
                >
                  {!pass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <div className="login-secondaryActions">
            <Button
              className={classes.button}
              component={AdapterLink}
              to="/forgot"
            >
              Forgot Password?
            </Button>
          </div>
          
          <div className="loginBtn">
            <Button
            onClick={handleLogin}
            variant="contained"
            color="primary"
            className={'mybutton mt-30'}
            >
              {loading? 'Loading...' :'Login'}
            </Button>
          </div>
          <div className="login-secondaryActions">
            { <a href="/signup" className="signUp">Don't have an account? <u className="secondaryColor">SIGN UP!</u></a> }
          </div> 
        </form>    
      </div>
    </div>
  );
};
