import * as React from 'react';

import { AuthContext } from '../../contexts/AuthContext';
import { Route, Redirect } from 'react-router-dom';

// const ProtectedRoute: React.FC<RouteComponentProps> = ({
export const ProtectedRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  const auth = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  // const [isLoggedIn, setIsLoggedIn] = React.useState<any>(false);

  React.useEffect(() => {
    const verifySession = async () => {
      const isValidSession =
        !!auth.isAuthenticated && (await auth.isAuthenticated());
      
      // setIsLoggedIn(isValidSession);
      setIsLoading(false);
    };

    if (isLoading) {
      verifySession();
    }
  }, []);

  // console.log('isLoading', isLoading);
  // console.log('isLoggedIn', isLoggedIn);

  if (isLoading) {
    return (
      <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <div className="loader-cont"><div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div> 
      </div>
    );
  }
  return (
    <Route
      {...rest}
      render={props => {
        if ((!localStorage.getItem('iview') || localStorage.getItem('iview') == 'false') && (window.location.hostname == 'help4tn.atjbot.org' || window.location.hostname == 'devfront.atjbot.org') && props.location.pathname == '/' && props.location.search && props.location.search.includes("view=")) {
          let query_params = new URLSearchParams(props.location.search);
          let interviewType = query_params.get('view');
          if (interviewType == 'dv' || interviewType == 'wc') {
            localStorage.setItem("iview", interviewType);
            window.location.href = '/';
          }
        }
        if (auth.hasValidSession) {
          return (
            <Component {...props} />
          )
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }
      }
      }
    />
  );
};
