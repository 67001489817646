import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import * as Config from '../config';

interface ApiBaseContructor {
  baseUrl: string;
}

export class ApiBase {
  private baseUrl: string;
  private axios: AxiosInstance = Axios.create();

  constructor({ baseUrl }: ApiBaseContructor) {
    this.baseUrl = Config.DA_API_BASE_URL;

    /**
     * Catch expired accessTokens, fetch new ones, retry
     */
    // this.axios.interceptors.response.use(
    //   ({ data }) => data,
    //   async error => {
    //     if (error.response.data.error) {
    //       throw Error(error.response.data.error.message);
    //     }
    //   },
    // );
  }

  /**
   * Error Handling
   */
  private handleErrors(error: Error) {
    // console.log('ApiBase.handleError', error.message);
    // throw Error(error.message);
  }

  /**
   *
   * @param url URI of the resource off the BaseURL
   * @param options AxiosRequestConfig
   */
  public async get(
    url: string,
    options: AxiosRequestConfig = {},
  ): Promise<any> {
    try {
      // console.log('GET '+this.baseUrl+'/'+url);
      return await this.axios.get(`${this.baseUrl}/${url}`, options);
    } catch (error) {
      return this.handleErrors(error);
    }
  }

  /**
   *
   * @param url URI of the resource off the BaseURL
   * @param data Object of data that gets passed to the server.
   * @param options AxiosRequestConfig
   */
  public async post(
    url: string,
    data: any,
    options: AxiosRequestConfig = {},
  ): Promise<any> {
    try {
      // console.log('POST '+this.baseUrl+'/'+url);
      return await this.axios.post(`${this.baseUrl}/${url}`, data, options);
    } catch (error) {
      return this.handleErrors(error);
    }
  }

  /**
   *
   * @param url URI of the resource off the BaseURL
   * @param data Object of data that gets passed to the server.
   * @param options AxiosRequestConfig
   */
  public async patch(
    url: string,
    data: any,
    options: AxiosRequestConfig = {},
  ): Promise<any> {
    try {
      // console.log('PATCH '+this.baseUrl+'/'+url);
      return await this.axios.patch(`${this.baseUrl}/${url}`, data, options);
    } catch (error) {
      return this.handleErrors(error);
    }
  }

  /**
   *
   * @param url URI of the resource off the BaseURL
   * @param options AxiosRequestConfig
   */
  public async delete(
    url: string,
    options: AxiosRequestConfig = {},
  ): Promise<any> {
    try {
      return await this.axios.delete(`${this.baseUrl}/${url}`, options);
    } catch (error) {
      return this.handleErrors(error);
    }
  }
}
