import * as React from 'react';
import '../Login/login.scss';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { RouteComponentProps, Redirect } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';
import { AdapterLink } from '../AdapterLink';
import Input from '@material-ui/core/Input';

import SimpleSnackbar from '../SimpleSnackbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
    img_logo: {
      width: 350,
      marginBottom: 10,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 20,
    },
    menu: {
      width: 200,
    },
    button: {
      margin: theme.spacing(1),
      marginTop: 20,
    },
    input: {
      display: 'none',
    },
  }),
);

interface IValueState {
  username: string;
  password: string;
  // email: string;
  // phone: string;
  errorMessages: string[];
  successMessages: string[];
  usernameHasError: boolean;
  passwordHasError: boolean;
  // phoneHasError: boolean;
  // emailHasError: boolean;
}

export const SignUp: React.FC<RouteComponentProps> = props => {
  const auth = React.useContext(AuthContext);
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [pass, setPass] = React.useState(false);
  const [language, setLanguage] = React.useState('en');
  const [values, setValues] = React.useState({
    username: '',
    password: '',
    lastName: '',
    firstName: '',
    errorMessages: [],
    successMessages: [],
    usernameHasError: false,
    passwordHasError: false,
    // phoneHasError: false,
    // emailHasError: false,
    registerSuccess: false,
  });

  const { from } = props.location.state || { from: { pathname: '/' } };

  if (auth.hasValidSession && !auth.requireVerification) {
    return <Redirect to={from} />;
  }

  if (auth.requireVerification && !auth.hasValidSession) {

  }

  const validateForm = (username: string, password: string) => {
    let err: string = '';
    if (!username && !password) {
      err = 'Please enter all fields.';
    }
    if (!username && password) {
      err = 'Please enter email.';
    }
    if (username && !password) {
      err = 'Please enter password.';
    }
    /*if (username && password  &&  phone) {
      err = 'Please enter an email.';
    }*/
    /*if (username && password  &&  !phone) {
      err = 'Please enter phone number.';
    }*/

    setValues({
      ...values,
      errorMessages: [...values.errorMessages, err],
      usernameHasError: !username,
      passwordHasError: !password,
      // phoneHasError: !phone,
      // emailHasError: !email
    });

    return !!username && !!password;
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.keyCode === 13) {  // if "Enter"
      e.preventDefault();
      handleSignUp();
    }
  };

  const handleSignUp = async () => {
    setLoading(true)
    try {
      let username = values['username'].toLowerCase();
      let password = values['password'];
      let firstName = values['firstName'];
      let lastName = '';
      const inputIsValid = validateForm(username, password);

      if (inputIsValid) {
        setValues({
          ...values,
          errorMessages: [],
          successMessages: [],
          usernameHasError: false,
          passwordHasError: false,
          // phoneHasError: false,
          // emailHasError: false
        });

        auth.signup && (await auth.signup({ username, password, firstName, lastName, language }));
        setValues({
          ...values,
          username: '',
          password: '',
          lastName: '',
          firstName: '',
          errorMessages: [],
          successMessages: [],
          registerSuccess: true
        });
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      // console.log(error);
      setValues({
        ...values,
        errorMessages: [error.message],
      });
    }
  };

  const handleClickShowPassword = () => {
    setPass(!pass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="login-page signupPage">
      <div className="login" style={{"padding": values.registerSuccess ? '5px' : '0px'}}>
        {/* <img src={require('../../assets/images/lawdroid-voice-logo.png')} alt="Lawdroid Logo" className={classes.img_logo}/> */}
        
        <div className="" style={{"display": values.registerSuccess ? 'block' : 'none'}}>
           <div className="">
              <div className="modal-body text-center text-white rounded shadow">
                 <div className="icon-box mx-auto mb-3" style={{"color": "#ffffff","width": "50px", "height" : "50px", "display": "flex", "border-radius": "50%", "z-index": "9", "border": "3px solid #ffffff", "textAlign": "center", "flex-wrap": "wrap", "alignItems": "center", "justify-content" : "center"}}>
                    <i className="material-icons" style={{"fontSize": "30px"}}></i>
                 </div>
                 <h4>Great!</h4>
                 <p className="text-white">Account registered successfully. Look for an email from ATJBot in your inbox or spam folder to verify your email address!</p>
                 <a style={{"width":"50%"}} className="btn btn-default bg-white py-2 d-flex justify-content-center flex-wrap align-items-center mx-auto" href="/login"><span>Login</span> <i className="material-icons ml-1 text-primary"></i></a>
              </div>
           </div>
        </div>
        {values.errorMessages &&
          values.errorMessages.map(
          (err, i) => !!err && <div className = "alert alert-danger w-100"> {err} </div>,
        )}

        <form className={classes.container} noValidate autoComplete="off" onKeyDown={inputOnKeyDown} style={{"display": values.registerSuccess ? 'none' : 'flex'}}>          
          <label>Full name</label>
          <TextField
            id="standard-name"
            placeholder="eg: Tony Stark"
            className={classes.textField}
            value={values.firstName}
            onChange={e => {
              setValues({ ...values, firstName: e.target.value });
            }}
          />
          {/* <label>Last name</label>
          <TextField
            id="standard-name"
            placeholder="eg: Tony Stark"
            className={classes.textField}
            value={values.lastName}
            onChange={e => {
              setValues({ ...values, lastName: e.target.value });
            }}
          /> */}
          <label className="mt-30">Email</label>
          <TextField
            error={values.usernameHasError}
            id="standard-name"
            placeholder="example@email.xyz"
            className={classes.textField}
            value={values.username}
            onChange={e => {
              setValues({ ...values, username: e.target.value });
            }}
          />
          <label className="mt-30">Password</label>
          <Input
            error={values.passwordHasError}
            id="standard-password"
            placeholder="pass••••"
            type={!pass ? 'password' : 'text'}
            className={'myInput'}
            value={values.password}
            onChange={e => {
              setValues({ ...values, password: e.target.value });
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  style={{ background: 'transparent', color: '#333' }}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  className="passcodeIcon"
                >
                  {!pass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <label className="mt-30">Lagnuage</label>
          <select className="myInput" style={{ padding: '10px' }} defaultValue={language} onChange={(e) => setLanguage(e.target.value)}>
            <option value="en">English</option>
            <option value="es">Spanish</option>
          </select>
          <div className="signUpBtn mt-30">
            <Button
              onClick={handleSignUp}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {loading ? 'Loading...' : 'Sign up'}
            </Button>
          </div>
          <div className="login-secondaryActions" style={{"display": values.registerSuccess ? 'none' : 'flex'}}>
            {<a href="/login" className="logIn">Already have an account? <u className="secondaryColor">LOGIN!</u></a>}
          </div>
        </form>        
      </div>
      {/* <div className="copyright">
        By signing up you accept our,<br />
        <a onClick={() => props.history.push('/terms')} href='javascript:void(0)'><u>Terms of Service </u></a> &nbsp; & &nbsp; <a onClick={() => props.history.push('/privacy')} href='javascript:void(0)'><u>Privacy Policy</u></a>
      </div> */}
    </div>
  );
};
