import * as React from 'react';
import './atj-bot.scss';
import { ThemeProvider } from '@material-ui/styles';
import {
  createMuiTheme,
  SimplePaletteColorOptions,
  darken,
  lighten,
} from '@material-ui/core/styles';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { AuthProvider } from '../../contexts/AuthContext';
import { ProtectedRoute } from '../ProtectedRoute';
import { Chat } from '../Chat';
import { Login } from '../Login';
import { SignUp } from '../SignUp';
import { ForgotPassword } from '../ForgotPassword';
import { ResetNewPassword } from '../ResetNewPassword';
import { VerifyAccount } from '../VerifyAccount';
import { TermsConditions } from '../TermsConditions';
import { PrivacyPolicy } from '../PrivacyPolicy';
import { Profile } from '../Profile';
import { Interviews } from '../Interviews';
import { AvailableInterviews } from '../AvailableInterviews';
import { AddedInterview } from '../AddedInterview';
import { AddedInterviews } from '../AddedInterviews';
import { UploadTemplate } from '../uploadDocument';
import { UploadInterview } from '../uploadInterview';
import { AddNewInterview } from '../AddNewInterview';
import { EditInterview } from '../EditInterview';
import { CreatePdf } from '../createPdf';
import { ExportAWSUsers } from '../ExportAWSUsers';
import { ListAWSUsers } from '../ListAWSUsers';
import { Analytics } from '../Analytics';
import { UserInterviews } from '../UserInterviews';
import { AdminDashboard } from '../AdminDashboard';
import DocumentMeta from 'react-document-meta';
import Favicon from 'react-favicon';

const primary: SimplePaletteColorOptions = {
  light: lighten('#4fc3f5', 0.1),
  main: '#4fc3f5',
  dark: darken('#4fc3f5', 0.1),
  contrastText: '#fff',
};

const theme = createMuiTheme({
  palette: {
    primary,
  },
});

export const ATJBot: React.FC = () => {
  let meta = {
    title: 'WC Bot'
  };
  if(window.location.hostname == 'help4tn.atjbot.org' || window.location.hostname == 'localhost'){
    meta.title = 'Help4tn';
  }
  
  return (
    <DocumentMeta {...meta}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          {window.location.hostname == 'help4tn.atjbot.org' ? <Favicon url={'https://www.help4tn.org/sites/all/themes/custom/dlaw4_bootswatch/favicon.ico'} /> :<Favicon url={require('../../assets/images/faviconIcon.jpg')} />}
          <Router>
            <ProtectedRoute exact path="/" component={Chat} />
            <ProtectedRoute exact path="/listusers" component={ListAWSUsers} />
            <ProtectedRoute exact path="/analytics" component={Analytics} />
            <ProtectedRoute exact path="/export/RUwuB57_CMUyj84_eJNB6y56" component={ExportAWSUsers} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/forgot" component={ForgotPassword} />
            <Route path="/reset" component={ResetNewPassword} />
            <Route path="/verify" component={VerifyAccount} />
            <ProtectedRoute exact path="/interviews" component={Interviews} />
            <ProtectedRoute exact path="/userinterviews" component={UserInterviews} />
            <ProtectedRoute exact path="/editinterview" component={CreatePdf} />
            <ProtectedRoute exact path="/create-pdf" component={CreatePdf} />
            <ProtectedRoute exact path="/availableInterviews" component={AvailableInterviews} />
            <ProtectedRoute exact path="/interview" component={AddedInterview} />
            <ProtectedRoute exact path="/interview/:id" component={AddedInterview} />
            <ProtectedRoute exact path="/added-interviews" component={AddedInterviews} />
            <ProtectedRoute exact path="/upload-template/:id" component={UploadTemplate} />
            <ProtectedRoute exact path="/add-new/:id" component={UploadTemplate} />
            <ProtectedRoute exact path="/configurations" component={UploadInterview} />
            <ProtectedRoute exact path="/addnewquestions" component={AddNewInterview} />
            <ProtectedRoute exact path="/profile" component={Profile} />
            <ProtectedRoute exact path="/privacy" component={PrivacyPolicy} />
            <ProtectedRoute exact path="/terms" component={TermsConditions} />
            <ProtectedRoute exact path="/viewinterview" component={EditInterview} />
            <ProtectedRoute exact path="/admindashboard" component={AdminDashboard} />
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </DocumentMeta>
  );
};

