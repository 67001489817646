import * as React from 'react';
import '../Chat/app.scss';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import { MessageContainer } from '../../Containers/MessageContainer';
import { AuthContext } from '../../contexts/AuthContext';
import Axios from 'axios';
import { DAClient } from '../../lib/DAClient';
import Spinner from 'react-bootstrap/Spinner';
import * as Config from '../../config';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const da = new DAClient();

export const UploadTemplate: React.FC = (props: any) => {
    const [value, setValue] = React.useState('female');
    const auth = React.useContext(AuthContext);
    const [loading, setLoading] = React.useState(false);
    const [tloading, setTLoading] = React.useState(false);
    const [fileN, setFileN] = React.useState('Click to choose file');
    const [ttype, setTtype] = React.useState('pdf');
    const [files, setFiles] = React.useState<any>([]);
    const [dfiles, setDFiles] = React.useState<any>([]);
    const [active, setActive] = React.useState<any>(0);
    const [values, setValues] = React.useState<any>({});
    const getInterview = async () => {
        console.log(props);
        let res = await Axios.get(Config.API_BASE_URL + 'getinterviewbyid/' + props.match.params.id);
        let files = await da.listFiles('templates');
        if (res.data.data) {
            let data = res.data.data;
            if (props.match.path == '/add-new/:id') {
                data.interview[data.interview.length - 1].attachment.push(null);
                setValues(data);
                setTtype('pdf');
                setActive(data.interview[data.interview.length - 1].attachment.length - 1);
            } else {
                if (data.interview[data.interview.length - 1].sets) {
                    data.interview.splice(data.interview.length - 1, 1);
                }
            }
            setValues(data);
        }
        let file = files.filter(f => f.includes('.pdf'));
        let dfile = files.filter(f => f.includes('.docx'));
        setFiles(file);
        setDFiles(dfile);
        setLoading(false);
    }
    const getFields = (data) => {
        return data.fields.map(e => {
            let obj = {};
            obj[e] = '""\n';
            return obj;
        });
    }

    const getFileName = (e) => {
        if (e.target.files) {
            let file = e.target.files[0];
            if (!file) {
                setFileN('Click to choose file');
                return
            } else if (file.name) {
                setFileN(file.name);
                return
            }
        }
        setFileN('Click to choose file');
        return
    }

    const UploadTemplate = async () => {
        setTLoading(true);
        try {
            let file: any = document.getElementById('templatefile');
            file = file.files[0];
            if (!file) {
                setTLoading(false);
                return
            }
            let formData = new FormData();
            formData.append('file', file);
            formData.append('folder', 'templates');
            await da.UploadFile(formData);
            let formData1 = new FormData();
            formData1.append('template', file);
            let data = await da.ExtractFields(formData1);
            let value = Object.create(values);
            let fields = getFields(data);
            if (!value.interview[value.interview.length - 1].sets) {
                if (ttype == 'pdf') {
                    value.interview.push({
                        "sets": "print_pdf",
                        "question": "Thank you for using Bot! You can download your completed forms here.\n",
                        "attachment": [
                            {
                                "name": value.interview[0].metadata.title,
                                "filename": value.interview[0].metadata.title,
                                "pdf template file": file.name,
                                "fields": fields
                            }
                        ]
                    });
                } else {
                    value.interview.push({
                        "sets": "print_pdf",
                        "question": "Thank you for using Bot! You can download your completed forms here.\n",
                        "attachment": [
                            {
                                "name": value.interview[0].metadata.title,
                                "filename": value.interview[0].metadata.title,
                                "docx template file": file.name
                            }
                        ]
                    });
                }
            } else {
                if (ttype == 'pdf') {
                    value.interview[value.interview.length - 1].attachment[active] = {
                        "name": value.interview[0].metadata.title + active,
                        "filename": value.interview[0].metadata.title + active,
                        "pdf template file": file.name,
                        "fields": fields
                    }
                } else {
                    value.interview[value.interview.length - 1].attachment[active] = {
                        "name": value.interview[0].metadata.title + active,
                        "filename": value.interview[0].metadata.title + active,
                        "docx template file": file.name
                    }
                }
            }
            if (value.interview[1].code[value.interview[1].code.length - 1] !== 'print_pdf') {
                value.interview[1].code.push('print_pdf');
            }
            setValues(value);
            await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: value._id, data: { interview: value.interview } });
            setTLoading(false);
        } catch (err) {
            setTLoading(false);
        }
    }
    const selectedFromList = async () => {
        setTLoading(true);
        let va: any = document.getElementById('document-list');
        va = va.value;
        let res = await Axios.post(Config.API_BASE_URL + 'getFields', { apiKey: Config.API_KEY, baseUrl: Config.DA_API_BASE_URL, fileName: va });
        let value = Object.create(values);
        let fields = getFields(res.data.data);
        if (!value.interview[value.interview.length - 1].sets) {
            if (ttype == 'pdf') {
                value.interview.push({
                    "sets": "print_pdf",
                    "question": "Thank you for using Bot! You can download your completed forms here.\n",
                    "attachment": [
                        {
                            "name": value.interview[0].metadata.title,
                            "filename": value.interview[0].metadata.title,
                            "pdf template file": va,
                            "fields": fields
                        }
                    ]
                });
            } else {
                value.interview.push({
                    "sets": "print_pdf",
                    "question": "Thank you for using Bot! You can download your completed forms here.\n",
                    "attachment": [
                        {
                            "name": value.interview[0].metadata.title,
                            "filename": value.interview[0].metadata.title,
                            "docx template file": va
                        }
                    ]
                });
            }
        } else {
            if (ttype == 'pdf') {
                value.interview[value.interview.length - 1].attachment[active] = {
                    "name": value.interview[0].metadata.title + active,
                    "filename": value.interview[0].metadata.title + active,
                    "pdf template file": va,
                    "fields": fields
                }
            } else {
                value.interview[value.interview.length - 1].attachment[active] = {
                    "name": value.interview[0].metadata.title + active,
                    "filename": value.interview[0].metadata.title + active,
                    "docx template file": va
                }
            }
        }
        if (value.interview[1].code[value.interview[1].code.length - 1] !== 'print_pdf') {
            value.interview[1].code.push('print_pdf');
        }
        setValues(value);
        await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: value._id, data: { interview: value.interview } });
        setTLoading(false);
    }
    const addMoreTemplate = () => {
        let value = Object.create(values);
        value.interview[value.interview.length - 1].attachment.push(null);
        setValues(value);
        setTtype('pdf');
        setActive(active + 1);
    }
    const mapField = (e, index, variable) => {
        let v = Object.create(values);
        let key = Object.keys(e).map(ky => {
            return ky
        })
        v.interview[v.interview.length - 1]['attachment'][active].fields[index][key[0]] = '${' + variable + '}\n';
        setValues(v);
    }
    const submit = async () => {
        setTLoading(true);
        try {
            let obj = JSON.parse(JSON.stringify(values.interview));
            await Axios.post(Config.API_BASE_URL + 'saveinterview', { id: values._id, data: { interview: obj } });
            await Axios.post(Config.API_BASE_URL + 'yaml/' + values._id, { apiKey: Config.API_KEY, baseUrl: Config.DA_API_BASE_URL });
            if (props.match.path == '/add-new/:id') {
                props.history.push('/interview/' + props.match.params.id);
            } else {
                window.location.href = '/configurations';
            }
            setTLoading(false);
        } catch (er) {
            setTLoading(false);
        }
    }
    React.useEffect(() => {
        setLoading(true);
        getInterview();
    }, [auth]);
    return (
        <MessageContainer render={({ messages, setMessages, resetSession, useOldSession }) => (
            <>
                <div>{loading || tloading ? <div className="loader-cont">
                    <div className="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div> : null}
                    <Header resetSession={resetSession} />
                    <div className="table-responsive addinterview_ uploadinterview_">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="interviewHeading">
                                        <div className="row d-flex align-items-center justify-content-between pr-2 pl-2">
                                            <div>
                                                {values.interview && (values.interview[values.interview.length - 1].attachment && values.interview[values.interview.length - 1].attachment[active] && values.interview[values.interview.length - 1].attachment[active] !== null) ? <div>
                                                    {ttype == 'pdf' ? <p>Fill values for <strong>{values.interview[values.interview.length - 1].attachment[active]['pdf template file']}</strong></p> : <p>{values.interview[values.interview.length - 1].attachment[active]['docx template file']} selected.</p>}
                                                </div>
                                                    : null}
                                                <h2>{values.interview ? values.interview[0]['metadata']['title'] : null} -
                                                        {values.interview ? ` Template ${active + 1}` : null}</h2>
                                            </div>
                                            <div>
                                                {values.interview && (values.interview[values.interview.length - 1].attachment && values.interview[values.interview.length - 1].attachment[active] && values.interview[values.interview.length - 1].attachment[active] !== null) ? props.match.path == '/add-new/:id' ? <button title="Submit" disabled={tloading} onClick={() => submit()} className="btn btn-primary text-white">Submit</button> : <div><button title="Submit" disabled={tloading} onClick={() => addMoreTemplate()} className="btn btn-primary text-white">Add More Template</button>&nbsp;&nbsp;&nbsp;OR&nbsp;&nbsp;<button title="Submit" disabled={tloading} onClick={() => submit()} className="btn btn-primary text-white">Submit</button>
                                                </div> : <a href="#" className="btn">&nbsp;</a>}
                                                {props.match.path == '/add-new/:id' ? <button title="Back" disabled={tloading} onClick={() => { props.history.push('/interview/' + props.match.params.id) }} className="btn btn-primary text-white">Back</button> : null}
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="row">
                                            {values.interview && (!values.interview[values.interview.length - 1].sets || (values.interview[values.interview.length - 1].attachment && values.interview[values.interview.length - 1].attachment[active] == null)) ? <div className="col-12 mt-3">
                                                <FormControl component="fieldset">
                                                    <RadioGroup row aria-label="position" name="position" defaultValue={ttype}>
                                                        <FormControlLabel value={'pdf'} onChange={() => setTtype('pdf')} control={
                                                            <Radio color="primary" />} label="Pdf" />
                                                        <FormControlLabel value={'docx'} onChange={() => setTtype('docx')} control={
                                                            <Radio color="primary" />} label="Docx" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div> : null}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="row upload_">
                                            {values.interview && (!values.interview[values.interview.length - 1].sets || (values.interview[values.interview.length - 1].attachment && values.interview[values.interview.length - 1].attachment[active] == null)) ?
                                                <div className="col-12 mt-3">
                                                    <label className="light-gray">Upload Template</label>
                                                    <span className="uploadui">{fileN}</span>
                                                    {ttype == 'pdf' ? <input className="hove" type="file" id='templatefile' accept="application/pdf" onChange={(event) => {
                                                        getFileName(event)
                                                    }} /> : <input type="file" className="hove" onChange={(event) => {
                                                        getFileName(event)
                                                    }} id='templatefile' accept=".docx" />}
                                                    <button title="Submit" disabled={tloading} onClick={() => UploadTemplate()} className="btn btn-primary text-white">Upload</button>
                                                </div> : null}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="row mt-3 separater">
                                            <div className="col-12">
                                                {values.interview && (!values.interview[values.interview.length - 1].sets || (values.interview[values.interview.length - 1].attachment && values.interview[values.interview.length - 1].attachment[active] == null)) ?
                                                    <label>OR</label>
                                                    : null}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="row">
                                            {values.interview && (!values.interview[values.interview.length - 1].sets || (values.interview[values.interview.length - 1].attachment && values.interview[values.interview.length - 1].attachment[active] == null)) ? <div className="col-12 mt-3">
                                                <label className="light-gray">Select From List</label><br />
                                                {ttype == 'pdf' ? <select id='document-list' defaultValue={''} className="form-control w-100 mb-3">
                                                    <option disabled value=''>Select Template</option>
                                                    {
                                                        files.map(f => {
                                                            return (
                                                                <option value={f}>{f}</option>
                                                            )
                                                        })
                                                    }
                                                </select> : <select id='document-list' defaultValue={''} className="form-control w-100 mb-3">
                                                        <option disabled value=''>Select Template</option>
                                                        {
                                                            dfiles.map(f => {
                                                                return (
                                                                    <option value={f}>{f}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>}
                                                <button title="Select" disabled={tloading} onClick={() => selectedFromList()} className="btn btn-primary text-white">Select</button>
                                            </div> : null}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {values.interview && (values.interview[values.interview.length - 1].attachment && values.interview[values.interview.length - 1].attachment[active] && values.interview[values.interview.length - 1].attachment[active] !== null) ? <div className="col-12">
                                            {ttype == 'pdf' && values.interview[values.interview.length - 1].attachment[active].fields && values.interview[values.interview.length - 1].attachment[active].fields.map((e, i) => {
                                                return (
                                                    <div className="row d-flex align-items-center justify-content-center uploadpdf_">
                                                        <div className="col-8"><span className="pdf_obj">{
                                                            Object.keys(e).map(key => {
                                                                return key
                                                            })
                                                        }</span></div>
                                                        <div className="col-4 text-right">
                                                            <select onChange={(ev) => mapField(e, i, ev.target.value)} defaultValue={''} className="form-control w-100">
                                                                <option disabled value=''>Select Variable</option>
                                                                {
                                                                    values.interview.map((el: any, ind: any) => {
                                                                        if (ind > 1 && !el.sets) {
                                                                            return (
                                                                                <option value={el.fields[0].variable}>{el.fields[0].variable}</option>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div> : null}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Footer />
                </div>
            </>
        )}
        />
    );
};